.accountActivation{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1140px !important;
    height: 86vh;
    margin: 0 auto !important;
    padding: 0 250px;
    @media (max-width: 768px) {
        padding: 0px;

    }

    .accountActivationContainer{
        position: absolute;
        top: 195px;

        width: 100%;
    }
    .row{
        height: 65vh;
    }

    @include desktop{
        padding: 0;
    }
    .payPanelLogoContainer {
        display: flex;
        justify-content: center;

        .payPanelLogo {
            width: 200px;

            text-align: center;

            svg {
                width: 40%;
                height: 80px;
            }
        }
    }

    label.mobileNumber{
        width: 100%;
        margin-bottom: 30px;

        color: $grayColor;
        font-size: 15px;

        text-align: center;

    }
    /* stylelint-disable-next-line */
    .accountActivationContainer{
        max-width: 369px;
    }
    .dropdown-menu{
        min-width: 21rem !important;
    }
    .verificationSection{
        height: 65vh;
        @media (max-width: 365px) {
            max-width: 333px;
            margin: 0 auto;
        }

    }
    .codeInputMargin{
        margin: 0px !important;
    }
}

.white-link {
    color: white !important;
}

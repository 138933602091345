.commissionPrctgSection {
    .percentageContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 16px; /* Use a gap for consistent spacing between columns */
        justify-content: flex-start;  /* Align items to the start */
    }

    .col {
        position: relative;

        max-width: 150px;
        height: auto;

        text-align: center;

        .inputWrapper {
            position: relative;

            display: inline-block;

            .inputField {
                width: 130px;
                height: 65px;
                padding-right: 25px;

                color: $primaryColor;
                text-align: center;

                border: 1px solid $primaryColor;
                border-radius: 7px;
            }

            .percentageSymbol {
                position: absolute;
                top: 32px;
                right: 45px;

                color: $primaryColor;
                font-size: 12px;

                transform: translateY(-50%);
            }
        }
    }
}


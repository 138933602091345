.nav-tabs {
    grid-gap: 25px;
    padding: 40px 80px;

    background: #f6f6f6;
    li {
        display: inline-block;
        a {
            position: relative;

            display: flex;
            grid-gap: 10px;
            align-items: center;
            padding: 0;
            padding-right: 80px;

            color: $primaryColor;
            font-size: 16px;

            border: 0;
            .num-sec {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                width: 20px;
                height: 20px;

                color: #fff;
                font-size: 10px;
                text-align: center;

                background: #dadada;
                border-radius: 50%;
            }
            .tab-title {
                color: #dadada;

                cursor: pointer;
            }
            .line-div {
                position: absolute;
                top: 10px;
                right: 0;

                display: block;
                width: 25%;
                height: 2px;

                background: #262d3a;
            }
            &:hover {
                background: transparent;
                border: 0;
            }
        }
        &:last-child {
            a {
                .line-div {
                    display: none !important;
                }
            }
        }
        a.active {
            .num-sec {
                background: #262d3a;
            }
            .tab-title {
                color: #262d3a;

                cursor: default;
            }
        }
        a.nav-link.active {
            background: transparent;
            border: 0;
        }
    }
}
div#tabs-1 {
    .nav-tabs {
        display: flex;
        grid-gap: 100px;
        align-items: center;
        padding: 16px 0;

        background: #fff;
        @media (max-width: 1500px) {
            grid-gap: 60px;
        }
        @media (max-width: 991px) {
            grid-gap: 40px;
        }
        .finance-term {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin: 0 9px 0 0;
            // padding: 12px 40px 0px 20px;
            font-size: $regularFontSize;

            // border: 1px solid #dfdfdf;

            @media (max-width: 1600px) {
                width: 100%;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
            label {
                margin-right: 10px;

                font-size: 13px;
            }
            .ng-select {
                position: relative;

                margin-bottom:0;
                overflow: visible;
                .ng-select-container {
                    overflow: visible;
                    span[title="Clear all"] {
                        position: absolute;
                        top: 7px;
                        right: -30px;
                    }
                }
            }
            select {
                width: 100px;

                padding: 0 18px;

                border: 1px solid #dfdfdf;
            }
        }
        li {
            a {
                position: relative;
                top: 11px;

                display: inline-block;
                padding-right: 0;
                padding-bottom: 14px;
                padding-left: 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: 14px;
                line-height: 26.3px;
            }
            a.active {
                border-bottom: 2px solid $primaryColor;
            }
        }
    }
    padding: 0;
}

div#tabs-4 {
    .extra-padding {
        padding: 40px 50px 50px 60px;
        @media (max-width: 480px) {
            padding: 0 20px 50px 20px;
        }
    }
    .nav-tabs {
        padding: 35px 80px 0 25px;

        background: #fff;
        .finance-term {
            width: 20%;
            margin: -10px 5vw 9px;
            padding: 5px 20px;

            font-size: 14px;

            border: 1px solid #dfdfdf;

            @media (max-width: 1600px) {
                width: 29%;
            }
            @media (max-width: 500px) {
                width: 100%;
            }
            label {
                margin-right: 10px;
            }
            select {
                width: 100px;

                border: 1px solid #dfdfdf;
            }
        }
        li {
            a {
                display: inline-block;
                padding-right: 0;
                padding-bottom: 20px;
                padding-left: 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: 14px;
                line-height: 26.3px;
            }
            a.active {
                border-bottom: 2px solid $primaryColor;
            }
        }
    }
    padding: 0;
}
div#tabs-6 {
    /* stylelint-disable-next-line */
    .main-group {
        /* stylelint-disable-next-line */
        label {
            &:after {
                top: 4px;
                left: -34px !important;

                display:none;
            }
        }
    }
}

.bs-datepicker-body{
    table{
        thead{
            tr{
                // background-color: $whiteColor;
                th{
                    color: $primaryColor;
                }
            }
        }
    }
}

.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
    color: rgba(0, 0, 0, 0.25)
}

/* hide 7 or more items met conditions, in the last row only */
.bs-datepicker-body table tr:last-child td:has(.is-other-month):first-of-type:nth-last-of-type(n + 7) span,
.bs-datepicker-body table tr:last-child td:has(.is-other-month):first-of-type:nth-last-of-type(n + 7) ~ td:has(.is-other-month) span
{
    visibility: hidden
    // color: red !important
}

.calender-main{
    position: relative;

    img{
        position: absolute;
        top: 5px;
        right: 7px;
        
        width: 20px;
    }
}
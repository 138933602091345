$blackColor: #000000;
$primaryColor: #307AFF;
$whiteColor: #ffffff;
$grayColor: #ccc;
$errorColor: #ff0000;
$borderColor:#dfdfdf;
$grayColor: #4c4c4c;
$colorGray: $grayColor;
$headerTextColor: #4c4c4c;
$charcoalColor: #7c7c7c;
$Grey-clr: #DFDFDF;
$colorBlue:#0d6efd;
$lightBlue: #86b7fe;
$darkBlue: #0d6efd40;
$amberColor: #F68A4A;
// Alert
$dangerColor: #c9252c;
$infoColor: #0f66d0;
$warningColor: #ec8600;
$successColor: #127f5f;
$alertBoxSize: 500;
$invalidFeedback: #dc3545;

// Table
$tableBgColor: #ffffff;
$tableHoverColor: #fdfdfd;

// Font size
$bigFontSize: 18px;
$titleFontSize: 14px;
$tableTitleFontSize: 12px;
$tableBodyFontSize: 11px;
$regularFontSize: 12px;
$placeHolderFontSize: 11px;

$titleText1: 16px;
$titleText2: 18px;
$titleText3: 20px;

$textSizeSmallest: 7px;
$textSizeExtraSmall: 8px;
$textSizeVerySmall: 10px;
$textSizeSmall: 11px;
$textSizeNormal: 12px;
$textSizeMedium: 14px;
$textSizeLarge: 16px;
$textSizeExtraLarge: 18px;

$textLineHeightNormal: 16px;

// Customer register page
$CRSectionSpacing: 16px;

// Spacing
$headerSpacing: 32px;
$mainContentSpacing: 32px;
$standardSpacing: 16px;
$standardBorderWidth: 1px;
$layoutBreakPointSpacing: 16px;


// Media query variable
$smallDevice: 576px;
$tabletDevice: 768px;
$largeDevice: 992px;
$xLargeDevice: 1200px;
$xxLargeDevice: 1400px;

/**
Spacing between table
*/
$tableSpacing: 32px;
$tabPanelSpacing: 32px;
$tabPanelMinHeight: 530px;

/**
Form element
*/
$formLabelSize: 14px;
$disableBgColor: #e9ecef;

$defaultCheckboxWidth: 14px;
$defaultCheckboxHeight: 14px;

/**
Button
*/
$buttonSpacing: 10px;

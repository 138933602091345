@import "tableDefault";

.tableContainer{
    &.tableHover{
        .table{
            tbody{
                tr{
                    cursor:pointer;

                    transition:all ease-in-out .2s;
                    &:hover{
                        --bs-table-striped-bg: $tableHoverColor !important;
                        td{
                            background-color:$tableHoverColor !important;

                            transition:all ease-in-out .2s;
                        }
                    }
                }
            }
        }
    }
    &.loginHistoryMinHeight{
        min-height: 300px;
    }
}

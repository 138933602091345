.card_rate {

    .pblAuth{
        .input-div{
            width: 11.8% !important;
        }
    }
    /* stylelint-disable-next-line */
    .row {
        justify-content: space-between;
    }
    /* stylelint-disable-next-line */
    .left_section {
        flex: 0 0 54.333333%;
        max-width: 54.333333%;
        @media (max-width: 991px) {
            flex: 0 0 58.333333%;
            max-width: 100%;
        }

        .fee-switch {
            color: #575756;

            background-color: #ffffff;
            border-width: 1px;
        }
        .fee-switch.active {
            color: $primaryColor;

            background-color: #ededed;
            border-color: $primaryColor;
        }
    }
    /* stylelint-disable-next-line */
    .right-selection-calculator{
        margin-top: 48px !important;
    }

    .right_section {
        flex: 0 0 42.333333%;
        max-width: 42.333333%;
        margin-top: 65px;

        padding: 0;
        @media (max-width: 991px) {
            max-width: 50% !important;
        }
        @media (max-width: 480px) {
            max-width: 90% !important;
            margin: 0 10px;
        }
        .calculator-border {
            // padding-bottom: 20px !important;
            padding: 25px 15px !important;

            border: 2px solid #307aff !important;
        }
        .calculator {
            width: 93%;
            padding: 10px;

            color: #fff;
            text-align: center;

            background-color: #307aff;
        }
        .calculator-head {
            display: flex;
            grid-gap: 30px;
            justify-content: center;
            margin: 15px;

            text-align: center;

            background: #307aff;
            border-radius: 3px;
            .inner {
                margin: 16px 0;
                padding: 0 10px;
                &.section-a {
                    position: relative;
                    &:after {
                        position: absolute;
                        top: 0;
                        right: -20px;

                        display: block;

                        width: 1px;
                        height: 100%;

                        background: #fff;

                        content: "";
                    }
                }
                /* stylelint-disable-next-line */
                p {
                    color: #fff;
                    font-size: 10px;
                }
                span {
                    display: inline-block;
                    padding-top: 8px;

                    color: #fff;
                    font-size: 28px;
                }
            }
        }

        .input-area {
            padding: 0 15px;

            text-align: center;
            .turnover-div-main {
                display: flex;
                grid-gap: 20px;
                margin-bottom: 15px;
                .left,
                .right {
                    width: 50%;

                    text-align: left;
                }
                /* stylelint-disable-next-line */
                label {
                    font-size: 12px;
                    text-align: left;
                }
                input {
                    width: 100%;
                    height: 42px;
                    padding: 0 15px;

                    color: #7c7c7c;
                    font-size: 12px;

                    border: 1px solid #dfdfdf;

                    border-radius: 0;
                }
            }
            /* stylelint-disable-next-line */
            label {
                width: 100%;
                padding-bottom: 10px;

                font-size: 12px;

                text-align: left;
            }
            .label {
                margin-bottom: 5px;
            }
            .estimate {
                position: relative;

                height: 48px;

                margin-bottom: 20px;

                input {
                    width: 100%;
                    height: 36px;
                    padding: 15px;

                    border: none;
                    &[type="text"],
                    &.currency-mask {
                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6 !important;
                    }
                }
                /* stylelint-disable-next-line */
                .select-div {
                    position: absolute;
                    top: 5px;
                    right: 10px;

                    height: 26px;
                    padding: 0 10px;

                    background: #ededed;
                    /* stylelint-disable-next-line */
                    select {
                        height: 25px;
                        padding: 0 20px;

                        font-size: 12px;
                        text-align: center;

                        background: #ededed;

                        border: 0;

                        /* stylelint-disable-next-line */
                        .ng-select-container {
                            position: relative;
                            top: 5px;

                            width: 100px;

                            height: 26px;

                            min-height: 26px;
                            overflow: visible;

                            background: #ededed;
                            border: 0;
                            border-radius: 0;
                        }
                        /* stylelint-disable-next-line */
                        span[title="Clear all"] {
                            position: absolute;
                            top: 4px;
                            right: -28px;
                        }
                    }
                }
            }
        }
        // .ng-star-inserted {
        //     padding: 25px 15px;
        //     border: 2px solid #307aff;
        // }
        .collapse {
            border: 2px solid #307aff;
            .calculator-head {
                display: flex;
                justify-content: space-evenly;
                margin: 30px 20px;
                @media (max-width: 1600px) {
                    margin: 20px 10px;
                }

                padding: 12px;

                color: #fff;

                text-align: center;

                background: #307aff;

                .inner {
                    margin: 10px;
                }
                .section-a {
                    padding-right: 35px;

                    border-right: 1px solid #fff;
                    @media (max-width: 1600px) {
                        padding-right: 22px;
                    }
                    /* stylelint-disable-next-line */
                    p {
                        font-size: 10px;
                    }
                    span {
                        font-size: 28px;
                        @media (max-width: 1600px) {
                            font-size: 20px;
                        }
                    }
                }
                .section-b {
                    /* stylelint-disable-next-line */
                    p {
                        font-size: 10px;
                    }
                    span {
                        font-size: 28px;
                        @media (max-width: 1600px) {
                            font-size: 20px;
                        }
                    }
                }
            }
            .input-area {
                padding: 15px;
                /* stylelint-disable-next-line */
                label {
                    margin: 12px 0;
                }
                button {
                    width: 250px;
                    margin: 25px 100px;
                    @media (max-width: 1600px) {
                        margin: 25px 15px;
                    }
                    @media (max-width: 480px) {
                        margin: 25px 12px;
                    }

                    border-radius: 2px;
                }
                .turnover-div-main {
                    display: flex;
                    grid-gap: 25px;
                }
                .estimate {
                    display: flex;
                    justify-content: space-between;
                    height: 32px;
                    margin-bottom: 20px;

                    border: 1px solid #d7d7d6;

                    input {
                        padding: 15px;

                        border: none;
                    }
                    /* stylelint-disable-next-line */
                    select {
                        width: 30%;
                        height: 25px;
                        margin: 2px;
                        padding: 0 30px;
                        @media (max-width: 1500px) {
                            padding: 0;
                        }

                        background: #ededed;

                        border: none;
                    }
                }
                /* stylelint-disable-next-line */
                .left {
                    width: 50%;
                    /* stylelint-disable-next-line */
                    label {
                        display: block;
                        width: 100%;
                        margin: 12px 0;

                        color: #333333;
                        font-size: 12px;
                    }
                    input {
                        display: block;
                        width: 100%;
                        padding: 8px 15px;

                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }
                }
                /* stylelint-disable-next-line */
                .right {
                    width: 48%;
                    /* stylelint-disable-next-line */
                    label {
                        display: block;
                        width: 100%;
                        margin: 12px 0;

                        font-size: 12px;
                    }
                    input {
                        display: block;
                        width: 100%;
                        padding: 8px 15px;

                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }
                }
            }
        }
    }
    #accordion {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;
        .left_section {
            width: 48%;
        }
    }
    /* stylelint-disable-next-line */
    .left_section {
        table {
            width: 100%;
            /* stylelint-disable-next-line */
            input {
                width: 80px;

                font-size: 11px;
            }
            /* stylelint-disable-next-line */
            thead {
                padding: 10px;

                color: #0491df;
                font-weight: 400;

                background: #3c3c3b;
                background: linear-gradient(180deg, #fff, #dbdbdb);
                /* stylelint-disable-next-line */
                th {
                    padding: 10px;

                    border-top: 1px solid #dee2e6;
                    border-bottom: 2px solid #dee2e6;
                }
                /* stylelint-disable-next-line */
                tr {
                    th.text_centr {
                        text-align: center;
                    }
                }
            }
            /* stylelint-disable-next-line */
            tbody {
                /* stylelint-disable-next-line */
                tr {
                    /* stylelint-disable-next-line */
                    td {
                        padding: 10px;

                        vertical-align: top;

                        border-top: 1px solid #dee2e6;
                    }
                    td.text_centr {
                        text-align: center;
                    }
                }
            }
        }
    }
    /* stylelint-disable-next-line */
    .right_section {
        table {
            width: 100%;
            /* stylelint-disable-next-line */
            input {
                width: 80px;

                font-size: 11px;
            }
            /* stylelint-disable-next-line */
            thead {
                padding: 10px;

                color: #0491df;
                font-weight: 400;

                background: #3c3c3b;
                background: linear-gradient(180deg, #fff, #dbdbdb);
                /* stylelint-disable-next-line */
                th {
                    padding: 10px;

                    border-top: 1px solid #dee2e6;
                    border-bottom: 2px solid #dee2e6;
                }
                /* stylelint-disable-next-line */
                tr {
                    th.text_centr {
                        text-align: center;
                    }
                }
            }
            /* stylelint-disable-next-line */
            tbody {
                /* stylelint-disable-next-line */
                tr {
                    /* stylelint-disable-next-line */
                    td {
                        padding: 10px;

                        vertical-align: top;

                        border-top: 1px solid #dee2e6;
                    }
                    td.text_centr {
                        text-align: center;
                    }
                }
            }
        }
    }
    /* stylelint-disable-next-line */
    button {
        /* stylelint-disable-next-line */
        &:focus {
            outline: transparent;
            box-shadow: none;
        }
    }
    button.btn.btn-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 12px;
        height: 12px;
        padding: 0;

        font-size: 15px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    button.btn.btn-link.collapsed {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 12px;
        height: 12px;
        padding: 0;

        font-size: 15px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .card-body {
        display: flex;

        padding: 8px 15px;

        font-size: 11px;
        line-height: 15px;

        background: #f6f6f6;
        /* stylelint-disable-next-line */
        .left-content {
            width: 56px;
        }
        /* stylelint-disable-next-line */
        .right-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding-left: 35px;

            @media (max-width: 991px) {
                padding-left: 4px;
            }
            @media (max-width: 1600px) {
                padding-left: 8px;
            }
            /* stylelint-disable-next-line */
            input {
                width: 50px;
                margin: 0 35px;
                @media (max-width: 1600px) {
                    margin: 0 38px;
                }
                @media (max-width: 991px) {
                    margin: 0 37px;
                }
                @media (max-width: 480px) {
                    margin: 0 4px;
                }

                padding: 4px 5px;

                text-align: center;

                border: 1px solid #dfdfdf;
            }
        }
    }
    /* stylelint-disable-next-line */
    .card-header {
        width: 100%;
        padding: 0;

        font-size: 11px;
        line-height: 15px;

        background: transparent;
        border-bottom: none;

        .tool {
            position: relative;

            display: inline-block;
        }

        .tool .tooltiptext {
            position: absolute;
            top: 18px;
            right: -55px;
            z-index: 1;

            width: 120px;

            padding: 5px 0;

            color: #999999;

            text-align: center;

            background-color: #ededed;

            border-radius: 3px;
            visibility: hidden;
        }

        .tool:hover .tooltiptext {
            visibility: visible;
        }

        /* stylelint-disable-next-line */
        .cardPadding{
            padding-left: 0 !important;
        }
        .right-content {
            display: flex;
            grid-gap: 30px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 13px;


            .naSection{
                display:flex;
                margin-left:44px;
                input[type=checkbox]{
                    margin-right:5px !important;
                    padding:0 !important;

                    &:before{
                        margin:0;
                    }
                }
            }
            /* stylelint-disable-next-line */
            button {
                width: 33px;

                color: #307aff;

                background: transparent;
                border: transparent;
            }
            /* stylelint-disable-next-line */
            .div {
                display: flex;
                grid-gap: 0;
                align-items: center;
                // justify-content: center;

                /* stylelint-disable-next-line */
                h2 {
                    min-width: 205px;
                    max-width: 205px;

                    font-weight: 500;
                }
            }
            .cardInput{
                width: 36%;

                column-gap: 56px !important;
            }
            .input-div {
                display: flex;
                grid-gap: 17px;
                min-width: 20px;

                /* stylelint-disable-next-line */
                input {
                    margin: 0 !important;
                }
            }
            /* stylelint-disable-next-line */
            input {
                /* stylelint-disable-next-line */
                &[formcontrolname="transactionFee"] {
                    margin: 0 25px;
                }
                @media (max-width: 1600px) {
                    margin: 0 38px;
                }
                @media (max-width: 991px) {
                    margin: 0 36px;
                }
                @media (max-width: 480px) {
                    margin: 0 3px;
                }
                width: 50px;
                margin: 0 8px;

                padding: 4px 0 4px 12px;

                text-align: center;

                border: 1px solid #dfdfdf;
                &.num-input {
                    padding-left: 0;
                }
            }
            /* stylelint-disable-next-line */
        }
        .collapse {
            .right-content {
                display: flex;
                grid-gap: 90px !important;
                align-items: center;
                justify-content: revert !important;

                width: 100%;
                padding-left: 25px;
                @media (max-width: 1600px) {
                    grid-gap: 80px !important;
                    padding-left: 38px;
                }

                button {
                    color: #307aff;

                    background: transparent;
                    border: transparent;
                }
                /* stylelint-disable-next-line */
                .div {
                    display: flex;
                    grid-gap: 10px;
                    align-items: center;
                    justify-content: space-between;

                    width: 110px;
                    /* stylelint-disable-next-line */
                    h2 {
                        min-width: 80px;
                        max-width: 80px;
                    }
                    span{
                        font-size: $textSizeSmall;
                    }
                }

                /* stylelint-disable-next-line */
                input {
                    &[formcontrolname="transactionFee"] {
                        margin: 0 25px;
                    }
                    @media (max-width: 1600px) {
                        margin: 0 38px;
                    }
                    @media (max-width: 991px) {
                        margin: 0 36px;
                    }
                    @media (max-width: 480px) {
                        margin: 0 3px;
                    }
                    width: 50px;
                    margin: 0 35px;

                    padding: 4px 0 4px 12px;

                    text-align: center;

                    border: 1px solid #dfdfdf;

                    &.num-input {
                        padding-left: 0;
                    }
                }
                /* stylelint-disable-next-line */
            }
        }
    }
    .transactionFeeSection{
        display: flex;
        align-items: center;
        margin-left: -17px;
    }
    .freeCheckbox{
        height: 13px;
        margin-left: 45px;
    }
    .freeText{
        font-size: $textSizeSmall;
    }
    .card {
        border: 0;
        @media (min-width: 350px) and (max-width: 770px) {
            width: 150%;
        }
    }
    .vt-pay {
        /* stylelint-disable-next-line */
        .card-header {
            .right-content {
                grid-gap: 87px !important;
                @media (min-width: 1200px) and (max-width: 1600px) {
                    grid-gap: 77px !important;
                }
                @media (max-width: 1199px) {
                    grid-gap: 60px !important;
                }
                .input-div {
                    grid-gap: 80px !important;
                }
            }
            .collapse {
                .right-content {
                    grid-gap: 48px !important;
                    @media (min-width: 1200px) and (max-width: 1600px) {
                        grid-gap: 36px !important;
                    }
                }
            }
        }
    }
    .top-title-section {
        margin-top: 16px;
        margin-bottom: 16px;

        font-weight: 400;
        font-size: $titleFontSize;
    }
}

.top-header-custom {
    padding: 8px 15px 8px 15px;

    color: #fff;
}

.top-header-custom .ooveride {
    margin-left: 50px;
}

.hide{
    display: none;
}

/* my css */
.fullWidth{
    width:100%;
}
.paddingRemove{
    justify-content: space-around;
    padding: 10px 10px;
}

.one{
    flex-grow: 4;
    justify-content: start;
}
.two{
    flex-grow: 1;
    justify-content: center;
}
.three{
    position: relative;
    left: 52px;
}
.gridRemove{
    grid-gap: 0 !important;
}




//TO DO need to remove the IMP once all the other places refactor
input[type=radio] {
    width: 8px;
    height:8px;

    border: 0 !important;
    border-radius: 50% !important;
    outline: 0.1em solid $charcoalColor;
    outline-offset: 2px;

    -webkit-appearance: none;

    &:checked{
        display: inline-block;

        background-color: $colorBlue !important;
        outline: 0.1em solid $colorBlue;
    }
}
input[type="text"] {
    width: 100%;
    height:38px;

    padding: 9px 15px;
}

input[type=checkbox] {
    width: $defaultCheckboxWidth;
    height: $defaultCheckboxHeight;

    vertical-align: top;


    background-color: $whiteColor;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid $blackColor;
    border-radius: .25em  !important;

    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;

    &:checked{
        background-color: $colorBlue !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        border-color: $colorBlue;
    }
    &:disabled{
        appearance: revert;
    }
    &.checkboxDefault{
        width: $defaultCheckboxWidth;
        height: $defaultCheckboxHeight;
    }
    &.checkboxSecondaryClr{
        &:checked {
            color: $colorBlue;

            background-color: $whiteColor !important;
        }
    }

}

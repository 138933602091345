.alertContainer{
    position:fixed;
    top: 20px;
    right:20px;
    z-index: 1000000;

    width:$alertBoxSize+px;

    @media (max-width: 768px) {
        left: 23px;

        width: fit-content;
    }

    .alert{
        position:relative;

        margin:0;
        padding:0;

        background: transparent;
        border: none;

        transform: translateX(($alertBoxSize+40)+px);

        &.show{
            animation: animSlideInRight ease-in-out .5s forwards 0.2s;
        }

        .container{
            margin: 10px 0;

            &.hide{
                animation: animSlideOutRight ease-in-out .5s forwards 0.2s;
            }

            .alertContent{
                display:flex;
                align-items: center;

                color: $whiteColor;

                border-radius: 5px;
                box-shadow: 0 0 5px rgba(0,0,0,.5);

                &.dangerAlert{
                    background-color: $dangerColor;
                }

                &.warningAlert{
                    background-color: $warningColor;
                }

                &.infoAlert{
                    background-color: $infoColor;
                }

                &.successAlert{
                    background-color: $successColor;
                }

                .iconPlaceholder{
                    margin-right: 10px;

                    svg{
                        color: $whiteColor;
                    }
                }

                .alertClose{
                    margin-left: 10px;
                    button{
                        color: $whiteColor;

                        background: transparent;
                        border: none;
                    }
                }

                .alertBody{
                    flex: 1;

                    h2{
                        margin-bottom: 5px;

                        color: $whiteColor;

                        font-size: $titleText1;

                    }
                    .text{
                        font-size:$textSizeMedium;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.invalidFormSection{
    background-color: #ffd3d3;
}
.message-content {
    margin-top: 10px!important;

    color:#4c4c4c !important;

    font-size: 12px !important;

    white-space: pre-line;
    text-align: center;
}

.custom-button {
    padding: 4.2px 20px !important;

    color: #fff !important;

    font-size: 11px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;


    background: #307AFF !important;
    border-top: 1px solid #307AFF !important;
    border-radius: 5px !important;


}
.bgColor{
    color: white!important;

    background-color: #307AFF!important;
}
.dialogTitle{
    font-size: 14px !important;
    text-align: center!important;
}
.close-icon {
    float: right !important;
    margin-top: 6px!important;

    cursor: pointer!important;
}

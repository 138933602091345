.bottom-list-section {
    .list {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 25vw;
    }
    /* stylelint-disable-next-line */
    ul {
        display: flex;
        align-items: flex-end;

        padding-left: 75px;
        /* stylelint-disable-next-line */
        li {
            width: 18%;
            padding: 0 22px;

            border-right: 2px solid #e4e4e4;
            /* stylelint-disable-next-line */
            a {
                padding-left: 8px;

                color: $primaryColor;
                font-size: 12px;
                line-height: 21.12px;
                text-decoration: none;
            }
            /* stylelint-disable-next-line */
            &:last-child {
                border-right: 0;
            }
            /* stylelint-disable-next-line */
            &:first-child {
                .price-sec {
                    padding-left: 0;
                }
            }
            /* stylelint-disable-next-line */
            .price-sec {
                padding: 0 10px;

                white-space: nowrap;
                text-align: center;

                .price {
                    display: inline-block;
                    margin-bottom: 5px;

                    color: #4c4c4c;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                }
                .price-label {
                    color: #4c4c4c;
                    font-size: 12.5px;
                    line-height: 21.12px;
                }
            }

            /* stylelint-disable-next-line */
            &:nth-child(1) {
                padding-left: 0;
            }
        }
    }
    margin-bottom: 50px;
    padding-top: 20px;
}

/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/
.account-summary-section {
    .bottom-list-section {
        .text-right {
            .green-clr {
                padding: 8px 30px;

                font-weight: 400;
                font-size: 12px;
                line-height: 20.12px;

                border-radius: 5px;
                box-shadow: none;
            }

            .dropdown-item {
                font-size: 12px;
            }
        }
    }

    .top-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 10px;
        padding-left: 14px;

        border-bottom: 1px solid #dfdfdf;

        .right-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h2 {
                color: $Grey2-clr;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }

            /* stylelint-disable-next-line */
            span {
                display: inline-block;
                padding-top: 10px;
                padding-left: 22px;

                color: #706f6f;
                font-size: 11px;
                line-height: 18.64px;
            }
        }

        .left-export {
            position: relative;

            /* stylelint-disable-next-line */
            button {
                padding: 2px 18px;

                color: $Grey2-clr;
                font-weight: 400;
                font-size: 11px;
                line-height: 20.12px;

                background: linear-gradient(to bottom, $White-clr 0%, #dbdbdb 100%);
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                box-shadow: 1px 1px 5px -2px #888888;

                &:hover {
                    color: #0491df;
                }
            }

            /* stylelint-disable-next-line */
            span {
                display: inline-flex;
                margin-right: 20px;

                color: #8f8f8f;
                font-size: 14px;
            }
        }

        .toggle-btn-option {
            position: absolute;
            top: 50px;
            right: 0;

            width: 150px;

            background: $White-clr;
            box-shadow: 1px 1px 5px -2px #888888;

            ul {

                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px;

                    font-size: 13px;

                    border-bottom: 2px solid #e4e4e4;

                    /* stylelint-disable-next-line */
                    a {
                        color: $primaryColor;
                        font-size: 15.4px;
                        line-height: 18.48px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .msg-detail {
        width: 84%;
        margin-bottom: 10px;

        border: none !important;
    }
}

.navbars.navbar-light {
    padding: 1.4rem 1rem;

    background: #262626;
}

.cls-inline {
    display: inline-block;

    /* stylelint-disable-next-line */
    button {
        margin-left: 10px;
        padding: 5px 10px;
    }
}

.msg-title-alert {
    position: absolute;
    top: 0;
    left: 100px;

    color: #000;

    background-color: #fff;
    border-color: #dfdfdf;
}

.msg-form-alert {
    position: absolute;
    top: 0;

    margin-top: 100px;

    color: $invalidFeedback;
    font-size: $regularFontSize;

    svg {
        margin-right: 10px;
    }
}

.transaction_section_table {
    .inner-tabel-tab {
        .table {
            th {
                &:nth-child(1) {
                    width: 12%;
                }

                &:nth-child(2) {
                    width: 15%;
                }

                &:nth-child(3) {
                    width: 7%;
                }

                &:nth-child(4) {
                    width: 9%;
                }

                &:nth-child(5) {
                    width: 9%;
                }

                &:nth-child(6) {
                    width: 9%;
                }

                &:nth-child(7) {
                    width: 9%;
                }

                &:nth-child(8) {
                    width: 11%;
                }
            }
        }
    }
}

.btn-primary {
    color: $White-clr;

    background: $blue-clr;
    border: 1px solid $blue-clr;
    box-shadow: none;

    &:hover {
        background: #2a6cdd;
    }
}

.new-design-table-sec.customer-deatil-tabs {
    .tablist-section {
        .nav-tabs {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 11.2%;
            }

            .nav-link {
                padding: 7px 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                font-family: "Roboto", sans-serif;
                line-height: 26.4px;

                background: #f6f6f6;
                border: 1px solid #e2e2e2;
                border-bottom: 0;
                box-shadow: none;
            }

            .nav-link.active {
                position: relative;

                background: $White-clr;

                &:before {
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    left: 0;

                    display: block;
                    width: 100%;
                    height: 4px;

                    background: $White-clr;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            li {
                &:first-child {
                    margin-left: 14px;
                }
            }
        }

        .tab-content {
            background: transparent;
            border: 1px solid #e2e2e2;
            box-shadow: none;
        }
    }

    .tab-table-section {
        .inner-tabel-tab {
            width: 100%;
            padding-top: 0;
        }
    }
}

code-input#password_confirm {
    margin: 20px 0 0;
}

code-input#verificationCode {
    margin: 20px 0 0;
}

.bottom-sec-btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 20px;
}

.login-input {
    .iti {
        display: block;
        width: 100%;
    }
}

.login-container {
    .is-invalid {
        .iti {
            .form-control {
                border: 1px solid #dc3545;
                border-radius: 0.25rem;
            }
        }
    }
}

input[type="password"] {
    width: 45px;
    height: 45px;
    margin: 0 20px;

    font-size: 25px;
    text-align: center;

    border: 1px solid #d7d7d6;

    &::-webkit-inner-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }

    &::-webkit-outer-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }
}

.password-change input[type="password"] {
    margin: 0 !important;
}

.row.radio-btn {
    padding: 10px 95px;
}

.square-radio {
    position: relative;

    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 2px;

    background-color: #e9e8e6;
    border: 1px solid black;

    /* stylelint-disable-next-line */
    span {
        display: inline-block;
        padding: 0 20px;
    }
}

.square-radio--clicked {
    .square-radio--content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 8px;
        height: 8px;
        margin: auto;

        background-color: black;
        border-radius: 20px;
    }
}

/* ================ Transaction page css start ====================== */
/* stylelint-disable-next-line */
.primaryTooltip {

    .tool {
        @include tool;
        /* stylelint-disable-next-line */
        svg{
            @include svgOverflow;
        }

        .tooltipText {
            @include tooltip;
            &.productMonthlyPriceText{
                top: -5px;
                left: 20px;

                width: 216px;
                padding: 6px 10px;
            }

            &.monthlyPriceTextService{
                // top: -5px;
                // left: 20px;

                top: 21px;
                left: -114px;

                width: 216px;
                padding: 6px 10px;
            }

            &.mccIcon {
                top: -46px;
            }

            &.dotbIcon {
                top: -95px;
                left: -154px;
            }
            &.poundText {
                top: 23px;
                left: -114px;

                width: 216px;
                padding: 6px 10px;
            }
        }

        .loginIcon {
            top: 32px;
            left: -141px;
        }

        .pinResetIcon {
            top: 18px;
            left: -242px;
        }
        .accountActivationIcon{
            top: -13px;
            left: 144px;
            @media (max-width: 420px) {
                left: 16px;
            }
        }

        &:hover {
            .tooltipText {
                @include tooltipHover;
            }
        }

        .questionMark {
            width: 14px;
            margin-top: 3px;
        }

        .mcc{
            margin-top: 2px;
        }

        .pound {
            margin-left: -20px;
        }
        .poundSelectProduct {
            margin-top: 4px;
            margin-left: -12px;
        }

        .login {
            width: 18px;
            margin-top: 7px;
            margin-left: -9px;
        }

        .pinReset {
            width: 18px;
            margin-top: -6px;
            margin-left: -106px;
        }

        .userAccount{
            width: 18px;
            margin-top: -36px !important;
            margin-left: 237px !important;

            @media (max-width: 365px) {
                left: -21px;
            }
        }

    }
}


.arrow-main-div {
    position: relative;

    .toggle-arrow-sec {
        position: relative;
        top: -1px;
        left: 5px;

        display: inline-flex;
        flex-direction: column;
        grid-gap: 2px;
        align-content: center;
        justify-content: center;


        svg {
            width: 10px;
            height: 7px;

            polygon {
                fill: white;
            }
        }

        /* stylelint-disable-next-line */
        a {
            line-height: 6px;

            /* stylelint-disable-next-line */
            &:hover {
                svg {
                    polygon {
                        fill: $Black-clr;
                    }
                }
            }
        }
    }

    .date-arrow {
        right: 162px !important;
    }

    .settle-date-arrow {
        right: 125px !important;
    }

    .settle-trans-arrow {
        right: 90px !important;
    }

    .settle-amount-arrow {
        right: 110px !important;
    }

    .msg-date-arrow {
        right: 85px !important;
    }

    .msg-arrow {
        right: 78px !important;
    }
}

.ng-select {
    .ng-select-container {
        min-height: 29px;
        max-height: 39px;

        border-radius: 0;

        .ng-value-container {
            padding: 0px;
            padding-left: 10px;
            overflow: visible !important;

            .ng-placeholder {
                // padding-left: 10px;

                color: #7c7c7c;
                font-weight: normal;
                font-size: 11px;
                line-height: 21.12px;
            }
        }
    }
}

.custom-control.custom-checkbox {
    display: flex;
    padding: 0;
}

.first-tabing-sec {
    border: 1px solid #dfdfdf;
}

.mdc-list-item {
    min-height: 25px !important;

    font-size: 12px !important;
}

/* ================ Transaction page css end ====================== */


.topUserHeaderTab {
    display: flex;
    justify-content: space-between;

    margin: 0 auto $standardSpacing;

    @media (max-width: 500px) {
        display: block;
    }

    .rightSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /* stylelint-disable-next-line */
        // button {
        //     padding: 2px 18px;

        //     color: $White-clr;
        //     font-size: 11px;
        //     line-height: 20.12px;

        //     background: $blue-clr;
        //     border: 0;
        //     border: 1px solid $blue-clr;
        //     border-radius: 5px;
        //     box-shadow: none;
        //     /* stylelint-disable-next-line */
        //     &:hover {
        //         background: #2a6cdd;
        //     }
        // }
        .btn-user-all {
            display: flex;
            grid-gap: 15px;

            @media (max-width: 500px) {
                justify-content: center;
            }
        }
    }
}

.relativePosition {
    position: relative;
}

.modal-header {
    color: $whiteColor;

    background-color: $primaryColor;
}

.declinedText {
    color: $errorColor
}

.aprrovedText {
    color: $successColor
}

.loyalCustomer{
    color: $grayColor;
    .loyalCustHeader{
        height: 34px;
        padding: 5px 10px;

        color: $whiteColor;
        font-weight: normal;
        line-height: 19.2px;

        background: $primaryColor;
    }
    .bonusInfo {
        padding: 5px;

        .bonusItem {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .loyaltyMonthlyInput{
                width: 90%;
                height: 50px;

                color: $whiteColor;
                font-weight: 600;
                font-size: $titleText1;

                background: $primaryColor;
                border: none;
                border-radius: 6px;
                outline: none;
                box-shadow: none;
            }

            .topUpMonthLabel{
                position: absolute;
                left: 45px;

                display: flex;

                color: $whiteColor;
                font-weight: 600;
                font-size: $titleText1;
            }
            .bonusInput {
                width: 90%;
                height: 50px;

                color: $whiteColor;
                font-weight: 600;
                font-size: $titleText1;
                text-align: center;

                background: $primaryColor;
                border: none;
                border-radius: 6px;
                outline: none;
                box-shadow: none;

                &.payTapBonus {
                    color: $primaryColor;

                    background: $whiteColor;
                    border: 1px solid $primaryColor;

                    &.ng-select .ng-select-container {
                        border: none;
                        &.ng-select-container .ng-value-container {
                            align-items: center;
                            justify-content: center;
                        }
                        &.ng-select-container .ng-value-container .ng-value{
                            color: $primaryColor;
                            font-size: $titleText1;
                        }
                    }

                }

            }
            .loyalCustBonusText{
                bottom: -33px;

                width: 78%;

                text-align: center;
            }
            .loyalCustBonusTextField{
                bottom: -20px;

                width: 78%;

                text-align: center;
            }

            .poundSign{
                top: 33%;
                left: 54px;
                z-index: 1;

                color: #307AFF;
            }
        }
    }


    .partnerColor{
        margin-bottom: 20px !important;

        color: #307AFF;
        font-weight:600;
        font-size: 14px;

    }
}






/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/
@mixin tool {
    position: relative;
    top: -14px;

    display: inline-block;
    margin: 0 5px;
}

@mixin tooltip {
    position: absolute;
    top: -8px;
    left: 22px;
    z-index: 1;

    width: 325px;
    padding: 20px 19px;

    color: $White-clr;
    font-size: 11px;
    line-height: 17px;
    text-align: center;

    background-color: $blue-clr;
    border: 1px solid #0d6efd;
    border-radius: 8px;
    transform: translateY(-20px);

    visibility: hidden;
    opacity: 0;

    transition: visibility 0s, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    animation: animSlideIn 0.1s ease-in-out;
}

@mixin tooltipHover {
    transform: translateY(0);

    visibility: visible;
    opacity: 1;
}

@mixin svgOverflow {
    overflow: visible;
}
@mixin buttonStyle {
    margin: 7.5px;
    padding: 2px 18px;

    color: #4c4c4c;
    font-weight: 400;
    font-size: 11px;
    line-height: 20.12px;

    border-radius: 5px;
}

@mixin buttonGradientLinerGray{
    background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
}

@mixin buttonBorderGray {
    border: 1px solid #d8d8d8;
    border-radius: 1px;
    box-shadow: 1px 1px 5px -2px #888888;
}

.widget-chart {
    width: 100%;
    height: 100%;
}
.tabel-section {
    .container {
        .row-gridster {
            min-width: 500px;
            height: 500vh;

            background: white;
            .gridster-column {
                border: none !important;
            }
            .gridster-row {
                border-top: none !important;
                border-bottom: none !important;
            }
            .gridster-item-custom {
                padding-top: 13px;
                padding-bottom: 13px;

                border: 1px solid #dfdfdf;
                border-radius: 5px;
                box-shadow: 3px 2px 7px -4px #888888;
                .widget-chart {
                    width: 100%;
                    height: 100%;
                }
            }
            /* stylelint-disable-next-line */
            .gridster-row {
                display: none;

                background-color: #e9ecef;
            }
        }
    }
}
.dashboardPage{
    .scrollVertical{
        overflow: hidden;
    }

    .editDashboard {
        // display: none;
        margin-top: 30px;
        button{
            color: $whiteColor;
            font-weight: normal;
            font-size: 12px;

            background-color: $primaryColor;


            &:hover{
                color: $primaryColor;

                background: #f6f6f6;
                border: 1px solid $primaryColor;
            }

            &:focus{
                box-shadow: none;
            }
        }
    }
    .dashboardWidget{
        .gridsterItem{
            padding: 20px;
            overflow: visible;

            border: 1px solid #DFDFDF;
            border-radius: 5px;
            .hideShowItem{
                position: absolute;
                top: 25px;
                right: 20px;
                button{
                    color: $primaryColor;
                    font-size: 12px;

                    background-color: transparent;
                    border:none;
                }
            }
        }
        .widgetItem{
            .widgetHeader{
                display:flex;
                align-items: center;
                justify-content: space-between;
                h3{
                    color: #307aff;
                    font-weight: normal;
                    font-size: 16px;
                }
            }
            .widgetChart{
                width:100%;
                height:230px;
            }
        }
        &.dragEnable{
            .gridsterItem{
                background-color: rgba(246,246,246,1);
                &:hover{
                    cursor: move;
                }
                .widgetItem{
                    .widgetHeader{
                        h3 {
                            color: rgba(87, 87, 86, .5);
                        }
                    }
                    .widgetChart{
                        opacity: .4;
                        &:hover{
                            cursor: move;
                        }
                    }
                }
            }
        }
        .scrollVertical{
            background:#f6f6f6;
        }
    }
    .dashboardEditing{
        position:fixed;
        bottom: 0;
        left:0;
        z-index: 10000;

        width: 100%;
        padding: 20px 0;

        background:#fff;
        box-shadow: 5px 0 5px rgba(0,0,0,.5);
        button.vBtn{
            padding: 10px 30px;

            font-weight: bolder;
            font-size: 12px;

            border-radius: 5px;

            transition: opacity ease-in-out 0.3s;
            &:hover{
                opacity: .7;
            }
            &.cancel{
                margin-right: 20px;

                color: $primaryColor;

                background-color: #fff;
                border: 2px solid #DFDFDF;
            }
            &.saveChanges{
                color: #fff;

                background: $primaryColor;
                border: none;
            }
        }
    }
}
.DashboardWidgetTitle{
    color: #307aff;
    font-weight: normal;
    font-size: 16px;
}

.editButton{
    column-gap: 10px;
}


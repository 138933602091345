.table-left-align>td {
    text-align: left !important;
}

.tabel-section.tab-table-section {
    .grid-tab-sec {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    a {
        color: #212529;
        text-decoration: none;
    }

    position: relative;
}

.tabel-section {

    .table-border {
        border: 1px solid #e2e2e2;
    }

    .inner-tabel-tab {
        .table-space {
            min-height: $tabPanelMinHeight - 59px;
            padding: 0 30px;
        }

        .bottom-table-foot {
            ul {
                display: grid;
                grid-template-columns: 0.8fr 2fr 1fr;
                align-items: center;

                li {
                    padding: 8px 0;

                    color: #7e796f;
                    font-size: 11px;
                    line-height: 14px;

                    border-right: 1px solid #dadada;

                    &:last-child {
                        height: 100%;

                        border-right: 0;
                    }

                    &:nth-child(2) {
                        padding-left: 20px;

                        border-right: 0;

                        select {
                            width: 50px;
                            margin: 0 5px;
                            padding: 0 5px;

                            background: #f6f6f6;
                            border: 1px solid #dadada;
                        }
                    }

                    &:nth-child(1) {
                        width: 225px;
                        padding-left: 10px;

                        select {
                            width: 48px;
                            margin: 0 5px;
                            padding: 0 5px;

                            background: #f6f6f6;
                            border: 1px solid #dadada;
                        }
                    }
                }

                .next-prev-btn {
                    position: relative;

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;

                    padding: 0;

                    .number {
                        position: relative;

                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 15px;

                        color: #0078c8;

                        &:before {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;

                            width: 1px;
                            height: 100%;

                            background: #dadada;

                            content: "";
                        }
                    }

                    .next-btn {
                        position: relative;

                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 15px;

                        color: #0078c8;

                        a {
                            color: #7e796f;
                            text-decoration: none;
                        }

                        &:after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;

                            width: 1px;
                            height: 100%;

                            background: #dadada;

                            content: "";
                        }
                    }

                    .end-btn {
                        a {
                            color: #7e796f;
                            text-decoration: none;
                        }

                        padding: 0 15px;
                    }
                }
            }

            margin-top: 10px;

            background: #fff;
            border-top: 1px solid #dadada;
        }

        background: #fff;

        .table {

            .lastBtnSection {
                .button-group {
                    display: flex;
                    flex-wrap: wrap;
                    grid-gap: 20px;
                    justify-content: flex-end;
                }
            }

            td.lastBtnSection {
                .view-group-sec {
                    display: flex;
                    flex-wrap: wrap;
                    grid-gap: 30px;
                    align-items: center;
                    justify-content: flex-end;

                    padding-right: 40px;

                    text-align: right;
                }
            }

            .user-svg {
                svg {
                    width: 16px;
                    height: 16px;
                }

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

.table-bordered {
    /* stylelint-disable-next-line */
    td {
        padding: 5px;
    }
}

.form-field.is-invalid {
    ~ {
        .invalid-feedback {
            display: block;
        }
    }
}

.tabPanel{
    &.tabPanelDefault{
        position:relative;

        padding:$tabPanelSpacing $tabPanelSpacing 0;

        background-color:$whiteColor;

        &.footerSpacing{
            padding-bottom:$tabPanelSpacing;
        }
    }

    &.defaultMinHeight{
        min-height:$tabPanelMinHeight;
    }

    &.bordered{
        border: $standardBorderWidth solid $borderColor;
    }

    &.borderedI{
        border: $standardBorderWidth solid $borderColor !important;
    }

    &.footerBottomSpacing{
        padding-bottom: $tabPanelSpacing;
    }

    &.footerBottomSpacingStandard{
        padding-bottom: $standardSpacing;
    }
}

@media (max-width: 767px) {
    .customer-deatil-tabs.new-design-table-sec {
        .tablist-section {
            .nav-tabs {
                li {
                    width: 100%;
                }
            }
        }
    }

    .breadcrumb-section {
        .container {
            .left-section {
                width: 100%;
            }

            .rightSection {
                width: 100%;
                margin-top: 20px;
            }
        }
    }

    .top-nav-section-div {
        p {
            font-size: 14px;
        }

        ul {

            /* stylelint-disable-next-line */
            li {
                a {
                    font-size: 14px;
                }

                padding: 25px 10px;
            }

            padding: 0 20px;
        }
    }
}

@media (max-width: 480px) {
    .product-main-div {
        ul {
            display: block;
            width: 100%;
        }
    }

    .card-section {
        .row {
            .col-lg-2 {
                flex: initial;
                max-width: 100%;
                margin-bottom: 40px;
            }
        }
    }

    .breadcrumb-section {
        .container {
            .rightSection {
                .version-dash {
                    p {
                        text-align: right;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .product-main-div {
        ul {
            flex-wrap: wrap;

            li {
                width: 45%;
            }
        }
    }

    .card-section {
        .row {
            .col-lg-2 {
                flex: initial;
                max-width: 50%;
            }
        }
    }

    .breadcrumb-section {
        .container {
            .rightSection {
                .version-dash {
                    p {
                        text-align: right;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .card-section {
        .row {
            .col-lg-2 {
                flex: initial;
                max-width: 33.33%;
            }
        }
    }
}

@media (min-width: 768px) {
    .customer-deatil-tabs {
        .tablist-section {
            .nav-tabs {
                .nav-link.active {
                    &:before {
                        position: absolute;
                        right: 0;
                        bottom: -2px;
                        left: 0;

                        display: block;
                        width: 100%;
                        height: 2px;

                        background: #fff;

                        content: "";
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .navbar.navbar-light {
        a.navbar-brand {
            position: relative;
            z-index: 999;
        }

        .navbar-nav {
            li {
                .dropdown-menu {
                    .dropdown-item {
                        &:nth-child(1) {
                            &:before {
                                top: 18px;

                                display: inline-block;
                                width: 20px;
                                height: 20px;

                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        &:nth-child(2) {
                            &:before {
                                top: 20px;

                                display: inline-block;
                                width: 20px;
                                height: 20px;

                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }

            li.reporting {
                >a {
                    &:before {
                        top: 15px;
                    }
                }
            }

            li.messages {
                >a {
                    &:before {
                        top: 15px;
                    }
                }
            }

            li.general {
                >a {
                    &:before {
                        top: 15px;
                    }
                }
            }

            li.logout {
                >a {
                    &:before {
                        top: 15px;
                    }
                }
            }
        }
    }

    .navbar-toggler {
        position: relative;
        z-index: 999;
    }

    .login-section {
        .middle-section {
            .header {
                border-radius: 20px 20px 0 0;
            }

            border-radius: 0;
        }
    }

    #tabs-3 {
        .third-tab-section {
            .third-inner-section {
                flex-wrap: wrap;
                margin-top: 50px;
                margin-bottom: 20px;

                .language-select {
                    width: 100%;
                }

                .currency-select {
                    width: 100%;
                }
            }
        }
    }

    #tabs-1 {
        padding: 0 20px 50px 20px;
    }

    #tabs-2 {
        padding: 0 20px 50px 20px;
    }

    .first-tab-section {
        .form-group {
            width: 100%;
        }
    }

    /* stylelint-disable-next-line */
    #tabs-1 {
        padding: 40px 20px 50px 20px;
    }

    .turnover-main-container {
        .turnover-div-main {
            .left_section {
                width: 100% !important;
            }

            .right_section {
                width: 100% !important;
            }

            grid-gap: 30px;
        }
    }

    .bottom-card-section {
        ul {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .breadcrumb-item {
        font-size: 13px;
        line-height: 20px;
    }

    .person-detail {
        .inner-person-detail {
            ul {
                margin-right: 20px;
            }

            padding: 20px;
        }
    }

    /* stylelint-disable-next-line */
    .product-main-div {

        /* stylelint-disable-next-line */
        ul {
            justify-content: space-between;
            width: 100%;

            /* stylelint-disable-next-line */
            li {
                margin-bottom: 30px;
            }
        }
    }

    .big-deatil-tabs {
        .nav-tabs {
            grid-template-columns: repeat(3, 1fr);
            padding: 20px;
        }
    }

    .turnover-form {
        .turnover-inner-section {
            .common-div-sec {
                width: 100%;
            }
        }

        margin-top: 10px;
        padding: 20px;
    }

    .first-tab-form {
        padding: 20px;

        .form-group {
            .main-group {
                width: 100%;
            }
        }
    }

    .sixth-tab-section {
        .manually-form-section {
            form {
                .form-group {
                    width: 100%;
                }
            }
        }

        padding: 20px;

        .select-section {
            width: 100%;
        }
    }

    .fifth-tab-sec {
        padding: 20px;
    }

    .personal-details-section {
        padding: 20px;
    }

    #business-overview {
        .select-section {
            margin-top: 10px;
            margin-bottom: 0;
            padding: 20px;

            .business-inner-div {
                width: 100%;
            }
        }
    }

    .main-selecting-option-div {
        .first-tab-form {
            padding: 20px;
        }
    }

    .last-tab-section {
        margin-bottom: 20px;
        padding: 20px;
    }

    /* stylelint-disable-next-line */
    .input-group.mb-3.group-end.pagination-pre-nxt {
        padding: 0;
    }

    .input-group.mb-3.group-end {

        /* stylelint-disable-next-line */
        a {
            padding: 5px 40px;
        }
    }

    .top-bottom-section {
        .version-dash {
            .logout-sec {

                /* stylelint-disable-next-line */
                a {
                    display: flex;
                    grid-gap: 10px;
                    align-items: center;
                }
            }
        }
    }

    .widget-div-section {
        .widget-card-section {
            ul {
                grid-template-columns: 1fr;

                /* stylelint-disable-next-line */
                li {
                    &:nth-child(3n + 0) {
                        grid-column: initial;
                    }
                }
            }
        }
    }

    .customer-deatil-tabs {
        .tablist-section {
            .tab-content {
                .inner-detail-tab {
                    ul {
                        li {
                            margin-right: 40px;
                            margin-bottom: 15px;
                        }
                    }
                }

                .detail-form-section {
                    width: 100%;
                    padding: 20px;
                }
            }

            .nav-tabs {
                grid-gap: 10px;
                grid-template-columns: repeat(4, 1fr);

                .nav-link {
                    font-size: 12px;
                }
            }
        }

        .detail-bank-section {
            form {
                display: block;
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
                padding: 20px;
            }
        }
    }

    .tabel-section {
        .inner-tabel-tab {
            .table {
                .lastBtnSection {
                    .button-group {
                        flex-wrap: initial;
                    }
                }

                td.lastBtnSection {
                    .view-group-sec {
                        flex-wrap: initial;
                        padding-right: 0;
                    }
                }

                td {
                    font-size: 12px;
                }

                th {
                    font-size: 12px;
                }
            }

            .bottom-table-foot {

                /* stylelint-disable-next-line */
                ul {
                    grid-template-columns: 1fr;

                    /* stylelint-disable-next-line */
                    li {
                        &:last-child {
                            justify-content: center;
                            height: 60px;

                            color: $primaryColor;

                            border-top: 1px solid #dadada;
                        }

                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }

                    .next-prev-btn {
                        .end-btn {
                            position: relative;

                            display: flex;
                            align-items: center;
                            height: 100%;
                            padding: 0 15px;

                            &:after {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                width: 1px;
                                height: 100%;

                                background: #dadada;

                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }

    .error-msg {
        p {
            align-items: flex-start;
        }
    }

    .header-table-section {

        /* stylelint-disable-next-line */
        ul {
            .select-two {
                input {
                    width: 100%;
                }

                select {
                    width: 100%;
                    height: 35px;

                    color: #939393;
                }
            }

            grid-gap: 10px;
            grid-template-columns: 1fr !important;

            li.last-header-section {
                .button-group-header {
                    grid-gap: 10px;
                    justify-content: flex-start;

                    button.filter-sec {
                        width: 100%;
                        height: 35px;
                    }

                    button.reset-sec {
                        width: 100%;
                        height: 35px;
                    }
                }
            }

            .select-one {
                select {
                    width: 100%;
                    height: 35px;

                    color: #939393;
                }
            }
        }
    }

    .progress-bar-1 {
        margin-top: 20px;
    }

    .progress-bar-2 {
        margin-top: 20px;
    }

    .navbar-expand-lg {

        /* stylelint-disable-next-line */
        .navbar-nav {

            /* stylelint-disable-next-line */
            .dropdown-menu {
                position: static;

                padding-bottom: 0;

                background: #121925;
                border: 0;
                box-shadow: none;

                /* stylelint-disable-next-line */
                .dropdown-item {
                    padding-top: 20px;
                    padding-bottom: 20px;

                    color: #fff !important;

                    /* stylelint-disable-next-line */
                    &:hover {
                        background: transparent;
                    }
                }
            }
        }
    }

    .navbar-light {
        .navbar-toggler-icon {
            background: transparent;
        }
    }

    .top-nav-section-div {

        /* stylelint-disable-next-line */
        ul {

            /* stylelint-disable-next-line */
            li {

                /* stylelint-disable-next-line */
                a {
                    padding: 0;

                    font-size: 10px;
                }

                padding: 10px;
            }

            li.active {
                &:before {
                    bottom: -2px;

                    height: 2px;
                }
            }
        }

        p {
            padding: 10px;

            font-size: 12px;
        }
    }

    /* stylelint-disable-next-line */
    .navbar.navbar-light {

        /* stylelint-disable-next-line */
        .navbar-nav {

            /* stylelint-disable-next-line */
            li {

                /* stylelint-disable-next-line */
                &:first-child {
                    margin-left: 15px;
                }
            }
        }

        /* stylelint-disable-next-line */
        a.navbar-brand {
            margin-left: 0;
        }
    }

    .right-logo {

        /* stylelint-disable-next-line */
        ul {
            a.right-logo-img {
                display: none;
            }
        }
    }

    /* stylelint-disable-next-line */
    .tabel-section {

        /* stylelint-disable-next-line */
        .inner-tabel-tab {

            /* stylelint-disable-next-line */
            .table {

                /* stylelint-disable-next-line */
                .lastBtnSection {

                    /* stylelint-disable-next-line */
                    .button-group {
                        flex-wrap: initial;
                    }
                }

                /* stylelint-disable-next-line */
                td.lastBtnSection {

                    /* stylelint-disable-next-line */
                    .view-group-sec {
                        flex-wrap: initial;
                        padding-right: 0;
                    }
                }

                /* stylelint-disable-next-line */
                td {
                    font-size: 12px;
                }

                /* stylelint-disable-next-line */
                th {
                    font-size: 12px;
                }
            }

            /* stylelint-disable-next-line */
            .bottom-table-foot {

                /* stylelint-disable-next-line */
                ul {
                    grid-template-columns: 1fr;

                    /* stylelint-disable-next-line */
                    li {

                        /* stylelint-disable-next-line */
                        &:last-child {
                            justify-content: center;
                            height: 60px;

                            color: $primaryColor;

                            border-top: 1px solid #dadada;
                        }

                        /* stylelint-disable-next-line */
                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }

                    /* stylelint-disable-next-line */
                    .next-prev-btn {

                        /* stylelint-disable-next-line */
                        .end-btn {
                            position: relative;

                            display: flex;
                            align-items: center;
                            height: 100%;
                            padding: 0 15px;

                            /* stylelint-disable-next-line */
                            &:after {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                width: 1px;
                                height: 100%;

                                background: #dadada;

                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    .tabel-section {
        .inneradd-tabel-tab {
            .table {
                .lastBtnSection {
                    .button-group {
                        flex-wrap: initial;
                    }
                }

                td.lastBtnSection {
                    .view-group-sec {
                        flex-wrap: initial;
                        padding-right: 0;
                    }
                }

                td {
                    font-size: 12px;
                }
            }

            .bottom-table-foot {

                /* stylelint-disable-next-line */
                ul {
                    grid-template-columns: 1fr;

                    /* stylelint-disable-next-line */
                    li {
                        &:last-child {
                            justify-content: center;
                            height: 60px;

                            border-top: 1px solid #dadada;
                        }

                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }

                    .next-prev-btn {
                        .end-btn {
                            position: relative;

                            display: flex;
                            align-items: center;
                            height: 100%;
                            padding: 0 15px;

                            &:after {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                width: 1px;
                                height: 100%;

                                background: #dadada;

                                content: "";
                            }
                        }
                    }
                }
            }
        }

        /* stylelint-disable-next-line */
        .inner-tabel-tab {

            /* stylelint-disable-next-line */
            .table {

                /* stylelint-disable-next-line */
                th {
                    font-size: 12px;
                }
            }
        }
    }

    .tab-content.border-tab-section {
        .tab-pane {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    /* stylelint-disable-next-line */
    .turnover-main-container {

        /* stylelint-disable-next-line */
        .turnover-div-main {

            /* stylelint-disable-next-line */
            .left_section {
                width: 100% !important;
            }

            /* stylelint-disable-next-line */
            .right_section {
                width: 100% !important;
            }

            grid-gap: 30px;
        }
    }

    .card_rate {
        padding: 0;

        #accordion {
            display: block;

            .left_section {
                width: 100%;
            }

            .right_section {
                width: 100%;
                margin-top: 40px;
            }
        }

        .card-header {
            .right-content {
                input {
                    width: 70px;
                    padding: 0 5px;
                }
            }
        }

        .card-body {
            .right-content {
                input {
                    width: 70px;
                    padding: 0 5px;
                }
            }
        }
    }

    .business_type {
        padding: 0;

        .business_type_ineer {
            display: block;
        }

        /* stylelint-disable-next-line */
        .left_section {
            width: 100%;
        }

        /* stylelint-disable-next-line */
        .right_section {
            width: 100%;
        }
    }

    .customer_registration {
        display: block;
        padding: 0 20px;

        /* stylelint-disable-next-line */
        .left_section {
            clear: both;
            width: 100%;
            margin-top: 40px;
        }

        /* stylelint-disable-next-line */
        .right_section {
            clear: both;
            width: 100%;
        }
    }

    /* stylelint-disable-next-line */
    .customer-deatil-tabs {

        /* stylelint-disable-next-line */
        .tablist-section {

            /* stylelint-disable-next-line */
            .tab-content {

                /* stylelint-disable-next-line */
                .inner-detail-tab {

                    /* stylelint-disable-next-line */
                    ul {

                        /* stylelint-disable-next-line */
                        li {
                            margin-right: 40px;
                            margin-bottom: 15px;
                        }
                    }
                }

                /* stylelint-disable-next-line */
                .detail-form-section {
                    width: 100%;
                    padding: 20px;
                }
            }

            /* stylelint-disable-next-line */
            .nav-tabs {
                grid-gap: 10px;
                grid-template-columns: repeat(4, 1fr);

                /* stylelint-disable-next-line */
                .nav-link {
                    font-size: 12px;
                }
            }
        }

        /* stylelint-disable-next-line */
        .detail-bank-section {

            /* stylelint-disable-next-line */
            form {
                display: block;
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
                padding: 20px;
            }
        }
    }

    .customer-deatil-tabs.profile-detail-page {
        .tablist-section {
            .tab-content {
                #tabs-1 {
                    .detail-form-section {
                        form {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }

                #tabs-3 {
                    .detail-form-section {
                        form {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }
            }
        }
    }

    .big-search-bar-section {
        .content-secrch {
            .input-div-section {
                width: 80%;
            }

            .search-div-sec {
                width: 20%;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .card-section {
        .row {
            .col-lg-2 {
                flex: initial;
                max-width: 25%;
            }
        }
    }
}

@media (max-width: 991px) {
    .header-profiletable-section {
        ul {
            grid-gap: 10px;
            grid-template-columns: 1fr !important;

            li.last-header-section {
                .button-group-header {
                    grid-gap: 10px;
                    justify-content: flex-start;

                    button.filter-sec {
                        width: 100%;
                        height: 35px;
                    }

                    /* stylelint-disable-next-line */
                    button.reset-sec {
                        width: 100%;
                        height: 35px;
                    }
                }
            }

            .select-one {
                select {
                    width: 100%;
                    height: 35px;

                    color: #939393;
                }
            }

            .select-two {
                select {
                    width: 100%;
                    height: 35px;
                    margin: 0;

                    color: #d7d7d6;

                    border: 1px solid #d7d7d6;
                    border-radius: 3px;
                }

                input {
                    width: 100%;
                    margin: 0;

                    color: #d7d7d6;

                    border: 1px solid #d7d7d6;
                    border-radius: 3px;
                }
            }
        }
    }

    .progress-bar-1 {
        margin-top: 20px;
    }

    .progress-bar-2 {
        margin-top: 20px;
    }

    .tabel-section {
        .inneradd-tabel-tab {
            .table {
                .lastBtnSection {
                    .button-group {
                        flex-wrap: initial;
                    }
                }

                td.lastBtnSection {
                    .view-group-sec {
                        flex-wrap: initial;
                        padding-right: 0;
                    }
                }

                td {
                    font-size: 12px;
                }
            }

            .bottom-table-foot {
                ul {
                    grid-template-columns: 1fr;

                    li {
                        &:last-child {
                            justify-content: center;
                            height: 60px;

                            border-top: 1px solid #dadada;
                        }

                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }

                    .next-prev-btn {
                        .end-btn {
                            position: relative;

                            display: flex;
                            align-items: center;
                            height: 100%;
                            padding: 0 15px;

                            &:after {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                width: 1px;
                                height: 100%;

                                background: #dadada;

                                content: "";
                            }
                        }
                    }
                }
            }
        }

        .inner-tabel-tab {
            .table {
                th {
                    font-size: 12px;
                }
            }
        }
    }

    .error-msg {
        p {
            align-items: flex-start;
        }
    }

    .customer-deatil-tabs.profile-detail-page {
        .tablist-section {
            .tab-content {
                #tabs-1 {
                    .detail-form-section {
                        form {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }

                #tabs-3 {
                    .detail-form-section {
                        form {
                            grid-template-columns: repeat(1, 1fr);
                        }
                    }
                }
            }
        }
    }

    .big-search-bar-section {
        .content-secrch {
            .input-div-section {
                width: 80%;
            }

            .search-div-sec {
                width: 20%;
            }
        }
    }

    .login-section {
        .middle-section {
            .left-section {
                padding: 20px 20px;
            }

            border-radius: 20px;

            .header {
                border-radius: 20px 20px 0 0;
            }
        }
    }

    .big-deatil-tabs {
        div#tabs-1 {
            .nav-tabs {
                li {
                    margin: 0;
                }

                padding-right: 20px;
                padding-left: 20px;
            }
        }

        div#tabs-2 {
            .nav-tabs {
                li {
                    margin: 0;
                }

                padding-right: 20px;
                padding-left: 20px;
            }
        }

        .main-tab-div-section {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .customer-registration-section {
        .nav-tabs.parent-tab {
            .nav-link {
                padding: 10px;

                line-height: 15px;

                border: 1px solid #e2e2e2;
            }

            .nav-link.active {
                padding: 10px;

                border: 1px solid #e2e2e2;
            }
        }

        .table-responsive {
            padding-right: 0;
            padding-left: 0;
        }

        .turnover-main-container {
            padding: 40px 0 0 0;
        }
    }

    .tab-content.border-tab-section {
        .tab-pane {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    .card_rate {
        padding: 0;

        #accordion {
            display: block;

            .left_section {
                width: 100%;
            }

            .right_section {
                width: 100%;
                margin-top: 40px;
            }
        }

        /* stylelint-disable-next-line */
        .card-header {
            .right-content {
                input {
                    width: 70px;
                    padding: 0 5px;
                }
            }
        }

        .card-body {

            /* stylelint-disable-next-line */
            .right-content {
                input {
                    width: 70px;
                    padding: 0 5px;
                }
            }
        }
    }

    .mobile-icon {
        position: absolute;
        top: 12px;
        right: 0;
        left: 0;

        text-align: center;

        /* stylelint-disable-next-line */
        svg {
            width: 80px;
        }
    }

    /* stylelint-disable-next-line */
    .navbar.navbar-light {
        .navbar-nav {

            /* stylelint-disable-next-line */
            li {

                /* stylelint-disable-next-line */
                &:first-child {
                    margin-left: 15px;
                }
            }
        }

        /* stylelint-disable-next-line */
        a.navbar-brand {
            margin-left: 0;
        }
    }

    /* stylelint-disable-next-line */
    .right-logo {

        /* stylelint-disable-next-line */
        ul {

            /* stylelint-disable-next-line */
            a.right-logo-img {
                display: none;
            }
        }
    }

    /* stylelint-disable-next-line */
    #tabs-1 {
        padding-right: 0;
        padding-left: 0;
    }

    #tabs-2 {
        padding-right: 0;
        padding-left: 0;
    }

    .business_type {
        padding: 0;

        .business_type_ineer {
            display: block;
        }

        /* stylelint-disable-next-line */
        .left_section {
            width: 100%;
        }

        /* stylelint-disable-next-line */
        .right_section {
            width: 100%;
        }
    }

    .customer_registration {
        display: block;
        padding: 0 20px;

        /* stylelint-disable-next-line */
        .left_section {
            clear: both;
            width: 100%;
            margin-top: 40px;
        }

        /* stylelint-disable-next-line */
        .right_section {
            clear: both;
            width: 100%;
        }
    }

    /* stylelint-disable-next-line */
    .big-deatil-tabs {

        /* stylelint-disable-next-line */
        .nav-tabs {
            grid-template-columns: repeat(3, 1fr);
            padding: 20px;
        }
    }

    /* stylelint-disable-next-line */
    .big-deatil-tabs {

        /* stylelint-disable-next-line */
        div#tabs-1 {

            /* stylelint-disable-next-line */
            .nav-tabs {

                /* stylelint-disable-next-line */
                li {
                    margin: 0;
                }

                padding-right: 20px;
                padding-left: 20px;
            }
        }

        /* stylelint-disable-next-line */
        div#tabs-2 {

            /* stylelint-disable-next-line */
            .nav-tabs {

                /* stylelint-disable-next-line */
                li {
                    margin: 0;
                }

                padding-right: 20px;
                padding-left: 20px;
            }
        }

        /* stylelint-disable-next-line */
        .main-tab-div-section {
            padding-right: 0;
            padding-left: 0;
        }
    }

    /* stylelint-disable-next-line */
    .customer-registration-section {

        /* stylelint-disable-next-line */
        .nav-tabs.parent-tab {

            /* stylelint-disable-next-line */
            .nav-link {
                padding: 10px;

                line-height: 15px;

                border: 1px solid #e2e2e2;
            }

            /* stylelint-disable-next-line */
            .nav-link.active {
                padding: 10px;

                border: 1px solid #e2e2e2;
            }
        }

        /* stylelint-disable-next-line */
        .table-responsive {
            padding-right: 0;
            padding-left: 0;
        }

        /* stylelint-disable-next-line */
        .turnover-main-container {
            padding: 40px 0 0 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .login-section {
        .container {
            padding: 100px;
        }

        .middle-section {
            .left-section {
                form {
                    padding: 10px;

                    text-align: center;
                }
            }
        }
    }

    .row.radio-btn {
        padding: 10px 70px;
    }

    input[type="password"] {
        width: 26px;
        height: 26px;
        margin: 0 5px;

        font-size: 15px;
        text-align: center;

        border: 1px solid #d7d7d6;

        &::-webkit-inner-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }

        &::-webkit-outer-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1004px) {
    .login-section {
        .container {
            padding: 0 60px;
        }

        .middle-section {
            .left-section {
                form {
                    padding: 10px;

                    text-align: center;
                }
            }
        }
    }

    .row.radio-btn {
        padding: 10px 150px;
    }

    input[type="password"] {
        width: 26px;
        height: 26px;
        margin: 0 5px;

        font-size: 15px;
        text-align: center;

        border: 1px solid #d7d7d6;

        &::-webkit-inner-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }

        &::-webkit-outer-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }
    }
}

@media only screen and (min-width: 1004px) and (max-width: 1200px) {
    input[type="password"] {
        width: 39px;
        height: 39px;
        margin: 0 5px;

        font-size: 15px;
        text-align: center;

        border: 1px solid #d7d7d6;

        &::-webkit-inner-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }

        &::-webkit-outer-spin-button {
            margin: 0;

            -webkit-appearance: none;
        }
    }
}

@media (max-width: 1299px) {
    .right-logo {
        right: 0;
        left: 0;
    }

    .top-nav-section-div {
        .container {
            .user-main-div {
                right: 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .mobile-icon {
        display: none;
    }

    .card-img-lg-responsive {
        max-height: 250px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .customer-deatil-tabs {
        .tablist-section {
            .nav-tabs {
                .nav-link {
                    &:after {
                        top: 30px;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    height: 100%;
                    padding: 20px 30px;
                }

                .nav-link.active {
                    &:after {
                        top: 30px;
                    }
                }
            }
        }
    }

    .bottom-list-section {
        ul {
            flex-wrap: wrap;

            li {
                width: 20% !important;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 481px) {
    .customer-registration-section {
        .nav-tabs.parent-tab {
            li {
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .customer-registration-section {
        .nav-tabs.parent-tab {
            grid-gap: 0;
            grid-template-rows: repeat(1, 1fr) !important;
        }
    }
}

@media (min-width: 767px) and (max-width: 1600px) {
    .customer-deatil-tabs.new-design-table-sec {
        .tablist-section {
            .nav-tabs {
                li {
                    width: 22%;
                }
            }
        }
    }

    .top-trans-section {
        .left-section {
            width: 28%;
        }

        .mid-section {
            width: 42%;
        }
    }
}

@media (min-width: 360px) and (max-width: 1600px) {
    .customer-deatil-tabs {
        .tablist-section {
            .nav-tabs {
                .nav-link {
                    padding-right: 5px;
                    padding-left: 5px;
                }
            }
        }
    }
}

@media (min-width: 1023px) {
    .first-tabing-sec {
        .top-trans-section {
            .form-sec {
                grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.6fr;
            }
        }
    }
}

@media (min-width: 1300px) and (max-width: 1600px) {
    .container {
        max-width: 1200px;
    }
}

/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/

/**
 Color variables
**/

$bdy-clr: #4c4c4c;


.login-footer{
    margin-bottom: 30px;

    text-align: center;

    ul{
        li{
            display: inline-block;
            padding: 0 10px;
            @media (max-width:480px){
                margin-bottom: 10px;
            }
            a{
                color: $bdy-clr;
            }
        }
    }
}

.appFooter{
    background-color: $whiteColor;
}

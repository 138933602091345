@import "ngSelect";


.form-group, .input-group{
    .pay-msg {
        height: 120px;
    }

    &.primary{
        display:flex;
        flex-direction: column;

        .label, label {
            margin-bottom: 10px;

            color:$colorGray;
            font-size: $formLabelSize;
        }

        &.inline{
            flex-direction: row;
            align-items:center;
            justify-content: flex-end;

            label{
                margin:0;
            }
        }

        .form-control{
            &.defaultInput{
                font-size: 11px;
            }
        }

        input{
            height:37px;
        }

        .detailDescription{
            font-size: $textSizeNormal
        }

        input, select{
            width:100%;

            background-color:$whiteColor;

            border: $standardBorderWidth solid $borderColor;
            border-radius:0;

            &:disabled{
                background-color:$disableBgColor;
            }

            &[type=checkbox]{
                width:auto;
            }
        }
        .ng-select-container{
            min-height:37px;
            margin:0;

            border: $standardBorderWidth solid $borderColor;
            border-radius: 0;

            input{
                height: auto;
            }
        }
        textarea {
            height: 120px;

            background-color:$whiteColor;

            border: $standardBorderWidth solid $borderColor;
            border-radius:0;

            &:disabled{
                background-color:$disableBgColor;
            }
        }

        &.telephone{
            .telephone .iti{
                height: 38px !important;

                input{
                    height: 38px;
                }
            }
        }
    }
}
.inputFieldHidden{
    visibility: hidden;
    opacity: 0;
}

.inputFullWidth{
    width:100%;
}



@import "componentLoader";
@import "globalLoader";

.appLoader  {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    .appLoaderContent{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;

        .appLoadingFooter{
            margin-top: $standardSpacing;
        }
    }

    .sectionOverflow{
        position:absolute;
        top: -20px;
        right:0;
        bottom:0;
        left:0;
        .appLoaderContent{
            opacity:1;
        }
    }

    &.appLoadingComponent{
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 35px;

        background: $whiteColor;
        opacity: 1;

        &.loaderFlow {
            position: relative;

            width: 1px;
            margin: 0;
            .appLoaderContent {
                position: absolute;
                top: 10px;
            }
        }
        &.positionStart{
            .appLoader{
                align-items: flex-start;

                .appLoaderContent{
                    margin-top: 160px;
                }
            }

        }
    }

    &.appLoadingComponentTable{
        position: absolute;
        top: 150px;
        bottom: 0;
        left: 0;
        z-index: 1000;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin:0;

        background: $whiteColor;
        opacity: 0;
    }


    &.appLoadingGlobal{
        position: absolute;
        top: 290px;
        z-index: 10;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100vh;
        margin: 0;

        background: $whiteColor;
    }

    &.noMargin {
        margin: 0;
    }

    .la-ball-pulse {
        display: block;
        width: 54px;
        height: 18px;

        color: #fff;

        font-size: 0;

        .la-dark {
            color: #333;
        }

        >div {
            position: relative;

            display: inline-block;
            float: none;

            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            width: 10px;
            height: 10px;
            margin: 4px;

            background-color: currentColor;
            border: 0 solid currentColor;
            border-radius: 100%;

            -webkit-animation: ball-pulse 1s ease infinite;
            -moz-animation: ball-pulse 1s ease infinite;
            -o-animation: ball-pulse 1s ease infinite;
            animation: ball-pulse 1s ease infinite;

            &:nth-child(1) {
                -webkit-animation-delay: -200ms;
                -moz-animation-delay: -200ms;
                -o-animation-delay: -200ms;
                animation-delay: -200ms;
            }

            &:nth-child(2) {
                -webkit-animation-delay: -100ms;
                -moz-animation-delay: -100ms;
                -o-animation-delay: -100ms;
                animation-delay: -100ms;
            }

            &:nth-child(3) {
                -webkit-animation-delay: 0ms;
                -moz-animation-delay: 0ms;
                -o-animation-delay: 0ms;
                animation-delay: 0ms;
            }
        }

        &.la-sm {
            width: 26px;
            height: 8px;

            >div {
                width: 4px;
                height: 4px;
                margin: 2px;
            }
        }

        .la-2x {
            width: 108px;
            height: 36px;

            >div {
                width: 20px;
                height: 20px;
                margin: 8px;
            }
        }

        .la-3x {
            width: 162px;
            height: 54px;

            >div {
                width: 30px;
                height: 30px;
                margin: 12px;
            }
        }

    }
}






@for $i from 0 through 70 {

    /**
    Define global margin
     */
    .mt-#{$i} {
        margin-top: #{$i}px;
    }

    .mr-#{$i} {
        margin-right: #{$i}px;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }

    .ml-#{$i} {
        margin-left: #{$i}px;
    }

    .appMt-#{$i} {
        margin-top: #{$i}px;
    }

    .appMr-#{$i} {
        margin-right: #{$i}px;
    }

    .appMri-#{$i} {
        margin-right: #{$i}px !important;
    }

    .appMb-#{$i} {
        margin-bottom: #{$i}px;
    }

    .appMbI-#{$i} {
        margin-bottom: #{$i}px !important;
    }

    .appMl-#{$i} {
        margin-left: #{$i}px;
    }

    .appMa-#{$i} {
        margin: #{$i}px;
    }

    .appMx-#{$i} {
        margin-right: #{$i}px;
        margin-left: #{$i}px;
    }

    .appMy-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
    }

    /**
        Define global padding
     */
    .pt-#{$i} {
        padding-top: #{$i}px;
    }

    .pr-#{$i} {
        padding-right: #{$i}px;
    }

    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }

    .pl-#{$i} {
        padding-left: #{$i}px;
    }

    .appPt-#{$i} {
        padding-top: #{$i}px;
    }

    .appPr-#{$i} {
        padding-right: #{$i}px;
    }

    .appPri-#{$i} {
        padding-right: #{$i}px !important;
    }

    .appPb-#{$i} {
        padding-bottom: #{$i}px;
    }

    .appPl-#{$i} {
        padding-left: #{$i}px;
    }

    .appPa-#{$i} {
        padding: #{$i}px;
    }
    .appPaI-#{$i} {
        padding: #{$i}px !important;
    }

    .appPx-#{$i} {
        padding-right: #{$i}px;
        padding-left: #{$i}px;
    }

    .appPy-#{$i} {
        padding-top: #{$i}px;
        padding-bottom: #{$i}px;
    }
}


@for $i from 10 through 500 {
    .colWidth-#{$i} {
        width: #{$i}px;
    }
}

.customer_registration {
    display: block;
    grid-gap: 20px;
    justify-content: space-between;
    padding: 0 35px;

    @media (max-width: 1500px) {
        grid-gap: 0;
    }

    /* stylelint-disable-next-line */
    .left_section {
        flex: 0 0 29% !important;
        max-width: 29% !important;
        padding: 30px;

        border: 1px solid #dfdfdf;

        @media (max-width: 1500px) {
            max-width: 25% !important;
            padding: 25px;
        }

        @media (max-width: 991px) {
            margin-top: 0;
        }

        @media (max-width: 480px) {
            max-width: 100% !important;
        }

        /* stylelint-disable-next-line */
        h2 {
            margin-top: 25px;
            margin-bottom: 5px;

            font-size: 14px;
        }

        /* stylelint-disable-next-line */
        p {
            font-size: 12px;
            line-height: 16px;
        }

        p.para1 {
            margin-top: 15px;
            margin-bottom: 20px;
        }

        p.para2 {
            margin-bottom: 10px;
        }

        /* stylelint-disable-next-line */
        input {
            width: 100%;
            margin-bottom: 15px;
            padding: 10px 15px;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;
        }

        /* stylelint-disable-next-line */
        button {
            float: right;
            padding: 2px 15px;

            color: #fff;
            font-weight: 400;
            font-size: 11px;
            line-height: 20.12px;
            text-transform: capitalize;

            background: #307aff;
            border: 1px solid #307aff;
            border-radius: 5px;
            box-shadow: none;

            /* stylelint-disable-next-line */
            &:hover {
                background: #2a6cdd;
            }
        }
    }

    /* stylelint-disable-next-line */
    .right_section {
        padding: 0;

        .page-1 {
            .main-tab-div-section {
                grid-template-columns: 1fr 1fr;
                padding-bottom: 60px;

                @media (max-width: 991px) {
                    display: block;
                }

                .tab-content {
                    // padding: 35px;

                    border: none;

                    .numbers-input {
                        margin-top: 20px;

                        .iti {
                            width: 100%;
                        }
                    }
                }

                /* stylelint-disable-next-line */
                .iti {
                    input {
                        height: 38px;

                        font-size: 12px;
                    }
                }

                .contacts {
                    .lower-section {
                        .numbers-input {
                            .iti--allow-dropdown {
                                width: 100%;
                            }

                            input {
                                font-size: 12px;
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line */
                .right {
                    position: relative;

                    padding: 35px;

                    border: none;
                    // top: 88px;

                    /* stylelint-disable-next-line */
                    input:not([type="radio"]) {
                        display: block;
                        height: 38px;

                        padding: 5px 45px 5px 15px;

                        color: $blackColor;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }

                    h3 {
                        margin-bottom: 25px;

                        font-weight: 400;
                        font-size: $titleFontSize;
                    }

                    .para2 {
                        margin-bottom: 25px;

                        font-weight: 500;
                        font-size: 12px;
                    }

                    /* stylelint-disable-next-line */
                    h2 {
                        margin: 10px 0;

                        font-weight: 600;

                        font-size: 14px;
                    }

                    .sole-input {

                        /* stylelint-disable-next-line */
                        label {
                            margin-bottom: 4px;
                        }

                        /* stylelint-disable-next-line */
                        input {
                            width: 100%;
                            height: 38px;
                            margin-bottom: 15px;

                            padding: 5px 45px 5px 15px;

                            color: #7c7c7c;
                            font-size: 12px;

                            border: 1px solid #d7d7d6;
                        }
                    }
                }

                .question {
                    p {
                        font-weight: 400;
                        font-size: 12px;
                    }

                    .answerSelection {
                        display: flex;
                        justify-content: space-around;

                        width: 40%;

                        .answer {
                            display: flex;
                            align-items: center;

                            label {
                                font-weight: 500;
                                font-size: $textSizeNormal;
                            }

                            input[type=radio] {
                                padding: 0.25em;

                                border-radius: 3px;

                                outline: 0.1em solid #888888;
                                outline-offset: 2px;

                                -webkit-appearance: none;
                            }

                            input[type=radio]:checked {
                                display: inline-block;

                                background-color: $blue-clr;
                                outline: 0.1em solid $blue-clr;
                            }
                        }
                    }
                }
            }

            /* stylelint-disable-next-line */
            .lower-section {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 10px;

                /* stylelint-disable-next-line */
                .para1 {
                    margin: 10px 0;

                    font-size: 18px;
                }

                /* stylelint-disable-next-line */
                .para2 {
                    margin: 10px 0;

                    font-size: 14px;
                }

                /* stylelint-disable-next-line */
                h2 {
                    margin: 10px 0;

                    font-weight: 600;

                    font-size: 14px;
                }

                .contacts {
                    display: flex;
                    flex-wrap: wrap;

                    @media (max-width: 480px) {
                        display: block;
                    }

                    >div {
                        display: block;
                        width: 100%;
                    }

                    /* stylelint-disable-next-line */
                    .numbers-input {
                        display: flex;
                        grid-gap: 4px;

                        margin-top: 32px;

                        @media (max-width: 991px) {
                            grid-gap: 24px;
                            margin-top: 51px;
                        }

                        @media (max-width: 480px) {
                            grid-gap: 0;
                            margin-top: 0;
                            margin-left: 0;
                        }

                        /* stylelint-disable-next-line */
                        input {
                            display: block;

                            width: 286.3px;
                            padding: 5px 15px 5px 45px;

                            color: #7c7c7c;

                            font-size: 12px;

                            border: 1px solid #d7d7d6;
                        }
                    }
                }
            }

            ul {
                margin-top: 10px;

                /* stylelint-disable-next-line */
                li {
                    margin-bottom: 10px;

                    font-size: 12px;
                    line-height: 16px;

                    /* stylelint-disable-next-line */
                    span {
                        display: block;
                        margin-bottom: 5px;

                        font-weight: bold;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    /* stylelint-disable-next-line */
                    p {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .bottom_box_main {
                margin-top: 35px;

                /* stylelint-disable-next-line */
                h2 {
                    margin-bottom: 5px;

                    font-size: 14px;
                }
            }

            .first-tab-form {
                .form-group {

                    /* stylelint-disable-next-line */
                    input {
                        position: relative;

                        /* stylelint-disable-next-line */
                        &:checked+label {

                            /* stylelint-disable-next-line */
                            &:after {
                                position: absolute;
                                top: 2px;
                                left: 318px !important;

                                display: block;
                                width: 6px;
                                height: 6px;

                                background: #343434;
                                border-radius: 50%;

                                content: "";

                                @media (max-width: 1500px) {
                                    left: 331px !important;
                                }

                                @media (min-width: 992px) and (max-width: 1200px) {
                                    left: 333px !important;
                                }

                                @media (min-width: 769px) and (max-width: 991px) {
                                    left: 400px !important;
                                }
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line */
                h3 {
                    margin-bottom: 25px;

                    font-weight: 400;
                    font-size: $titleFontSize;
                }

                .director-list {
                    display: flex;
                    justify-content: space-between;

                    .tool {
                        position: relative;

                        display: inline-block;
                    }

                    .tool .tooltiptext {
                        position: absolute;
                        top: 15px;
                        right: 10px;
                        z-index: 1;

                        width: 255px;
                        padding: 20px !important;

                        color: #595959;
                        text-align: left;

                        background-color: #fff;
                        border: 1px solid #dfdfdf;
                        border-radius: 3px;

                        visibility: hidden;

                        h3 {
                            margin-bottom: 10px;

                            color: #111111;
                        }

                        /* stylelint-disable-next-line */
                        p {
                            margin-bottom: 0;
                        }
                    }

                    .tool:hover .tooltiptext {
                        visibility: visible;
                    }
                }

                /* stylelint-disable-next-line */
                .sole-input {

                    /* stylelint-disable-next-line */
                    label {
                        margin-bottom: 4px;
                    }

                    /* stylelint-disable-next-line */
                    input {
                        width: 100%;
                        height: 38px;
                        margin-bottom: 15px;

                        padding: 5px 45px 5px 15px;

                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }
                }
            }
        }

        .page-2 {
            padding: 30px 30px 28px;

            @media (max-width: 991px) {
                padding: 20px;
            }

            border: 1px solid #dfdfdf;

            .turnover-main-container {
                grid-template-columns: 1fr 1fr;
                padding-bottom: 60px;

                /* stylelint-disable-next-line */
                .ng-select {
                    height: 46px;
                }

                .turnover-div-main {
                    display: block;

                    @media (max-width: 480px) {
                        grid-gap: 0;
                    }

                    /* stylelint-disable-next-line */
                    input {
                        width: 100%;

                        @media (max-width: 480px) {
                            width: 100%;
                        }
                    }
                }

                /* stylelint-disable-next-line */
                .left-section {
                    width: 50%;
                }

                /* stylelint-disable-next-line */
                .right {
                    width: 50%;

                    @media (max-width: 480px) {
                        width: 100%;
                    }

                    /* stylelint-disable-next-line */
                    input {
                        display: block;
                        width: 100%;

                        padding: 5px 20px 5px 45px;

                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }

                    .para1 {
                        margin: 10px 0;

                        font-size: 18px;
                    }

                    .para2 {
                        margin: 20px 0;

                        font-size: 14px;
                        line-height: 20px;
                    }

                    /* stylelint-disable-next-line */
                    h2 {
                        margin: 10px 0;

                        font-weight: 600;

                        font-size: 14px;
                    }
                }
            }
        }

        .page-3 {
            @media (max-width: 991px) {
                padding: 20px;
            }

            border: none;

            .contact-input {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 0.5fr 0.5fr 0.5fr 0.7fr 0.7fr;
                margin: 16px 16px 16px 0;

                /* stylelint-disable-next-line */
                label {
                    padding-bottom: 5px;
                    padding-left: 2px;
                }

                /* stylelint-disable-next-line */
                input {
                    font-size: 12px;

                    border-radius: 5px;
                }
            }

            .main-tab-div-section {
                display: flex;
                grid-gap: 55px;

                @media (max-width: 480px) {
                    display: block;
                }

                /* stylelint-disable-next-line */
                .right {
                    width: 50%;
                    padding: 0 25px;

                    @media (max-width: 480px) {
                        width: 100%;
                        padding: 0;
                    }

                    /* stylelint-disable-next-line */
                    input {
                        display: block;
                        padding: 5px 15px;

                        color: #7c7c7c;
                        font-size: 12px;

                        border: 1px solid #d7d7d6;
                    }

                    .para1 {
                        margin: 10px 0;

                        font-size: 18px;
                    }

                    .para2 {
                        margin: 20px 0;

                        font-size: 14px;
                    }

                    /* stylelint-disable-next-line */
                    h2 {
                        margin: 10px 0;

                        font-weight: 600;

                        font-size: 14px;
                    }
                }
            }

            /* stylelint-disable-next-line */
            .lower-section {
                //display: flex;
                //grid-gap: 65px;
                padding: 20px;

                @media (max-width: 1500px) {
                    grid-gap: 55px;
                }

                @media (max-width: 991px) {
                    grid-gap: 50px;
                }

                @media (max-width: 480px) {
                    display: block;
                }

                /* stylelint-disable-next-line */
                input {
                    font-size: 12px;
                }

                .lower-left-section {
                    display: flex;
                    flex-direction: column;

                    /* stylelint-disable-next-line */
                    h3 {
                        margin-bottom: 20px;
                    }

                    .repeating-fields {
                        .repeating-fields-row {
                            display: grid;
                            grid-gap: 10px;
                            grid-template-columns: 0.5fr 0.5fr 0.5fr 0.7fr 0.7fr;
                            margin: 16px 16px 16px 0;

                            &:first-child {
                                margin-top: 0;
                            }

                            .remove-link {
                                padding-top: 12px;

                                font-size: 80%;
                            }
                        }
                    }
                }

                .lower-rightSection {
                    padding: 0 20px;

                    @media (max-width: 480px) {
                        padding: 20px 0;
                    }

                    .para1 {
                        margin: 10px 0;

                        font-size: 18px;
                    }

                    .para2 {
                        margin: 10px 0;

                        font-size: 14px;
                    }

                    /* stylelint-disable-next-line */
                    h2 {
                        margin: 10px 0;

                        font-weight: 600;

                        font-size: 14px;
                    }

                    .contacts {
                        display: flex;

                        .numbers-input {
                            display: flex;
                            flex-direction: column;
                            grid-gap: 4px;

                            margin-left: 16px;

                            @media (max-width: 1500px) {
                                grid-gap: 55px;
                                margin-top: 10px;
                            }

                            /* stylelint-disable-next-line */
                            input {
                                display: block;

                                padding: 5px 15px;

                                color: #7c7c7c;
                                font-size: 12px;

                                border: 1px solid #d7d7d6;

                                &::placeholder {
                                    color: #707070;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-section-btn {
                .next-btn-section {
                    margin-left: 10px;

                    &[disabled] {
                        background: #ccc;
                        border: 1px solid #ccc;
                    }
                }

                padding: 10px 0;

                text-align: right;
            }
        }
    }
}

.emailBusinessAddress {
    float: none;
    width: 100%;
    height: 38px;
    padding: 9px 15px;

    color: $blackColor;
    font-size: 12px;

    border: 1px solid #d7d7d6;
    border-radius: 2px;
}

.addressInfoTitle {
    margin: 10px 0;

    padding: 0;

    font-weight: 400;
    font-family: Roboto, sans-serif;

    border: 0;
}

.leftSideImg {
    margin-top: 10px;
}

.fixFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 319px;
}

/* stylelint-disable-next-line */
.verticalLine {
    >div[class^="col-"] {
        margin-bottom: 0px;
        padding-top: 10px;
        padding-bottom: 0px !important;

        border-left: 2px solid #307aff;
    }

    div[class^="col-"]:first-child {
        border-left: none !important;
    }
}

.paddingShorthand {
    padding: 0.375rem 0.75rem !important;
}

.customInput {
    font-size: 12px;
}

// /* stylelint-disable-next-line */
.verticalLines {
    border-right: 2px solid $primaryColor;
}

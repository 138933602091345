.quotesModal {
    .modal-content{
        .modal-body{
            display:flex;
            flex-direction: column;
            align-items: center;
            padding: 0 $standardSpacing;

            .input-group{
                width:70%;

                input{
                    margin: 0 auto;
                    padding: 6px;

                    text-align: center;

                    border: $standardBorderWidth solid $primaryColor;
                    border-radius: 5px;
                }
            }

            img{
                width: 200px;
            }
            .quotesModaltext{
                display:flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

.modal{
    &.financeModal{
        animation-name: animZoomIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        .modal-dialog{
            max-width: 600px;

        }
    }
}


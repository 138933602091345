.tooltipContainer{
    position:relative;

    .popoverContent{
        position:absolute;
        top: -5px;
        left:20px;

        width: 250px;
        padding: 5px 20px;

        background: #fff;

        border:1px solid #dfdfdf;

        visibility: hidden;
        opacity:0;
    }

    &:hover .popoverContent {
        position:absolute;
        z-index: 10;

        visibility: visible;
        opacity: 1;

        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
}

/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/

.main-chat-section .bottom-chat-section .second-person .left-section {
    .person-icon {
        .second-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            margin-right: 10px;

            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            text-decoration: none;

            background: #e11455;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}

.main-chat-section .bottom-chat-section .first-person .rightSection {
    .person-icon {
        .first-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            margin-right: 10px;

            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            text-decoration: none;

            background: #e11455;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}

.main-chat-section .bottom-chat-section .chat-text-field .send-input {
    form {
        position: relative;
        button {
            position: absolute;
            top: 0;
            right: 0;

            padding-right: 10px;

            background: transparent;
            border: 0;
            img {
                width: 20px;
            }
        }
    }
}

.inline-check {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    margin-top: 5px;
    input {
        width: auto;
        &.checkboxDefault{
            width: $defaultCheckboxWidth;
            height: $defaultCheckboxHeight;
        }
    }
    .lable {
        /* stylelint-disable-line */
        display: inline-block;
    }
    .exactAmount{
        margin:0;

        color: $primaryColor;
        font-size:$regularFontSize;
    }
}

.copyTooltip {
    position: relative;

    display: inline-block;

    .tooltip-text {
        position: absolute;
        top: -21%;
        left: 35px;
        z-index: 1;

        width: 40px;
        padding: 5px 0;

        color: #fff;
        text-align: center;

        background-color: #307aff;
        border-radius: 6px;

        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;

        transition: visibility 0s, opacity 0.5s ease-in-out;
        &.visible {
            visibility: visible;
            opacity: 1;
        }
        &:hover{
            visibility: visible;
        }
        &::after {
            position: absolute;
            top: 100%;
            left: 50%;

            margin-left: -5px;

            border-color: transparent transparent lightgrey transparent;
            border-style: solid;
            border-width: 5px;

            visibility: hidden;

            content: "";

        }



    }

}
.copyClipBoard{
    margin-left: 20px;
}


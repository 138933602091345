.top-header-drop {
    position: relative;

    min-width: 170px;

    margin-right: 50px;
    .demo-drop-main {
        position: relative;
        .demo-btn {
            button {
                width: 100%;
                padding: 5px 30px 5px 20px;

                color: #fff;
                font-weight: normal;
                font-size: 11px;
                line-height: 22.3px;
                text-align:left;

                background: #2c3e5d;
                border: 0;
                border-radius: 4px;
            }
        }
        .drop-icon {
            position: absolute;
            top: 7px;
            right: 13px;

            cursor: pointer;
            svg {
                width: 10px;
                height: 10px;
                path {
                    fill: $White-clr;
                }
            }
        }
    }
    .drop-down-header {
        position: absolute;
        top: 40px;
        left: 0;
        z-index: 999;

        min-width: 300px;
        min-height: 250px;
        max-height: 400px;
        padding: 0;
        padding: 0 0 0 0;
        overflow-y: auto;


        background: #fff;

        border: 1px solid #e3e3e3;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

        &.customerDropdownContainer {
            animation-name: animFadeIn;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
        }

        .js-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            margin-right: 0;

            color: #fff;
            font-size: 18px;
            text-transform: uppercase;
            text-decoration: none;
            vertical-align: middle;


            background: #307aff;
            border-radius: 50%;
            cursor: default;
        }

        .noblackclr {
            justify-content: unset !important;

            background: none;

            border: none;
        }
        .top-header-section {
            margin-bottom: 10px;

            padding: 0;

            border-bottom: 1px solid #dfdfdf;

            .top-inner-section {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 8px;
                align-items: center;
                width: 100%;
                padding: 10px 20px 10px 20px;
                span {
                    color: #232323;
                    font-weight: 400;
                    font-size: 14px;
                }
                .js-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 42px;
                    height: 42px;
                    margin-right: 10px;

                    background: #307aff;
                    border-radius: 50%;
                    cursor: default;

                    span{
                        color: #fff;
                        font-size: 21px;
                        text-transform: uppercase;
                        text-decoration: none;
                    }
                }
            }
        }
        .search-section {
            margin-bottom: 10px;

            border-bottom: 1px solid #dfdfdf;
            .search-input {
                padding: 10px 20px 10px 20px;
                input {
                    width: 100%;
                    padding-bottom: 10px;

                    color: #aeaeaf;
                    font-size: 10px;

                    border: 0;
                    border-bottom: 1px solid #d6d6d8;
                    &:focus-visible {
                        outline: 0;
                    }
                }
            }
            ul {
                max-height: 160px;
                overflow-y: scroll;
                li {
                    a {
                        display: block;
                        padding: 10px 20px 10px 20px;

                        color: #232323;
                        font-size: 12px !important;
                        text-decoration: none;
                        &:hover {
                            background: #ededef;
                        }
                    }
                    &.active {
                        background-color: #e6e6ea;
                    }
                }
            }
        }
        /* stylelint-disable-next-line */
        ul {
            /* stylelint-disable-next-line */
            li {
                font-size: 12px;
                &:hover {
                    background: #f3f4f7;
                }
                /* stylelint-disable-next-line */
                a {
                    color: $Black-clr !important;
                    font-size: 12px;
                }
                /* stylelint-disable-next-line */
                span {
                    font-size: 14px;
                }
                /* stylelint-disable-next-line */
                &.main-row {
                    /* stylelint-disable-next-line */
                    a {
                        @include flexPort2;
                    }
                }
            }
            &.top-section {
                li {
                    padding: 5px 20px 5px 20px;
                    &:first-child {
                        padding-top: 5px;
                    }
                    &.main-header {
                        display: flex;
                        grid-gap: 10px;
                        align-items: center;

                        color: $Black-clr;
                        font-weight: 400;
                        font-size: 14px !important;

                        cursor: pointer;
                        &:hover {
                            background: #f3f4f7;
                        }
                    }
                    /* stylelint-disable-next-line */
                    a {
                        display: flex;
                        grid-gap: 10px;
                        align-items: center;

                        font-size: 12px !important;

                        text-decoration: none;
                        &.blue-clr {
                            color: #307aff !important;
                        }
                        .add-secting {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;

                            width: 30px;
                            height: 30px;

                            color: #0071cf;
                            font-size: 20px;

                            background: #e6e6ea;
                            border-radius: 5px;
                        }
                    }
                }
            }
            &.middle-section {
                border-top: 1px solid #e3e3e3;
                border-bottom: 1px solid #e3e3e3;
                cursor: pointer;
                /* stylelint-disable-next-line */
                a {
                    font-weight: 400;
                    font-size: 12px;
                    text-decoration: none;
                }
                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px 10px 20px;

                    color: #000;
                }
            }
        }
    }
}

.account-summary-section {
    .top-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 10px;
        padding-left: 14px;

        border-bottom: 1px solid #dfdfdf;
        .right-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            h2 {
                color: #4c4c4c;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }
            span {
                display: inline-block;
                padding-top: 10px;
                padding-left: 22px;

                color: #706f6f;
                font-size: 11px;
                line-height: 18.64px;
            }
        }
        .left-export {
            position: relative;
            /* stylelint-disable-next-line */
            button {
                padding: 2px 18px;

                color: #4c4c4c;
                font-weight: 400;
                font-size: 11px;
                line-height: 20.12px;

                background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                box-shadow: 1px 1px 5px -2px #888888;
                /* stylelint-disable-next-line */
                &:hover {
                    color: #0491df;
                }
            }
            span {
                display: inline-flex;
                margin-right: 20px;

                color: #8f8f8f;
                font-size: 14px;
            }
        }
        .toggle-btn-option {
            position: absolute;
            top: 50px;
            right: 0;

            width: 150px;

            background: #fff;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            ul {
                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px;

                    font-size: 13px;

                    border-bottom: 2px solid #e4e4e4;
                    a {
                        color: $primaryColor;
                        font-size: 15.4px;
                        line-height: 18.48px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
/* stylelint-disable-next-line */
.account-summary-section {
    .customer-product {
        padding-top: 30px;
    }
    /* stylelint-disable-next-line */
    .top-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 10px;
        padding-left: 14px;

        border-bottom: 1px solid #dfdfdf;
        /* stylelint-disable-next-line */
        input.form-control {
            display: inline-block;
        }
        /* stylelint-disable-next-line */
        input {
            width: 30%;
            height: 26px;
            margin: 4px;
            padding: 0.375rem 1rem;

            color: #495057;
            font-weight: 400;

            line-height: 1.5;

            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;

            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &::placeholder {
                color: #9c9898;
                font-size: 12px;
            }
        }
        .period {
            .message-box {
                display: inline-block;
            }
        }
        .period.hide {
            display: none;
        }
        .edit {
            display: none;
            margin-left: 44px;
        }
        .edit.active {
            display: block;
        }
        button.com-btn {
            padding: 2px 18px;

            color: #000;
            font-weight: 400;
            font-size: 11px;
            line-height: 20.12px;

            background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
            border: 1px solid #d8d8d8;
            border-radius: 1px;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            img {
                position: relative;
                top: 2px;

                width: 13px;
            }

            span {
                margin-left: 8px;
            }
        }
        /* stylelint-disable-next-line */
        button {
            @include buttonStyle;
            /* stylelint-disable-next-line */
            &:hover {
                color: #0491df;
            }
        }
        /* stylelint-disable-next-line */
        .right-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            /* stylelint-disable-next-line */
            h2 {
                color: #4c4c4c;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }
            .message-box {
                padding: 5px 0 0 43px;

                font-size: 11px;

                line-height: 34px;
            }
            /* stylelint-disable-next-line */
            a {
                padding: 5px 0 0 43px;

                font-size: 11px;
            }
            /* stylelint-disable-next-line */
            span {
                display: inline-block;
                padding-top: 10px;
                padding-left: 22px;

                color: #706f6f;
                font-size: 11px;
                line-height: 18.64px;
            }
        }
        /* stylelint-disable-next-line */
        .left-export {
            position: relative;
            /* stylelint-disable-next-line */
            span {
                display: inline-flex;
                margin-right: 20px;

                color: #8f8f8f;
                font-size: 14px;
            }
        }
        /* stylelint-disable-next-line */
        .toggle-btn-option {
            position: absolute;
            top: 50px;
            right: 0;

            width: 150px;

            background: #fff;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            ul {
                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px;

                    font-size: 13px;

                    border-bottom: 2px solid #e4e4e4;
                    /* stylelint-disable-next-line */
                    a {
                        color: $primaryColor;
                        font-size: 15.4px;
                        line-height: 18.48px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .col-row {
        .create-new {
            display: flex;
            grid-gap: 5px;
            align-items: center;
            padding: 8px 30px;

            color: $White-clr;
            font-weight: 400;
            font-size: 13px;
            line-height: 20.12px;
            text-transform: capitalize;

            background: $blue-clr;
            border: 1px solid $blue-clr;
            border-radius: 5px;
            box-shadow: none;
            &:hover {
                color: $White-clr;

                background: #2a6cdd;
            }
        }
    }
}
.transaction-div-table {
    .inner-tabel-tab {
        .table {
            td {
                /* stylelint-disable-next-line */
                a {
                    color: $primaryColor;
                }
            }
        }
    }
}
.customer-deatil-tabs {
    .tablist-section {
        background: #fff;
        border-radius: 5px 5px 0 0;
        .nav-tabs {
            display: grid;
            grid-gap: 7px;
            grid-template-columns: repeat(5, 1fr);

            background: #f6f6f6;
            border: 0;
            .nav-link {
                position: relative;

                padding: 7px 10px;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                line-height: 26.4px;
                text-align: center;

                background: #f6f6f6;
                border: 2px solid #e2e2e2;
                box-shadow: none;
                &:after {
                    position: absolute;
                    top: 22px;
                    right: 15px;

                    display: none;
                    width: 10px;
                    height: 7px;

                    background: url(../../img/arrow-top.png);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }
            .nav-link.active {
                position: relative;

                background: #fff;
                border-bottom: 0;
                box-shadow: none;
                &:after {
                    top: 22px;

                    transform: rotate(180deg);
                }
            }
            /* stylelint-disable-next-line */
            li {
                &:first-child {
                    margin-left: 12px;
                }
            }
        }
        .tab-content {
            background: transparent;
            border: 2px solid #e2e2e2;
            box-shadow: none;
            .inner-detail-tab {
                ul {
                    padding: 30px 60px;

                    background: #fff;
                    li {
                        display: inline-block;
                        margin-right: 75px;
                        padding: 0 0 8px 0;

                        color: #000;
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: none;
                    }
                    li.current {
                        position: relative;

                        color: #dc1445;

                        background: transparent;
                        &:before {
                            position: absolute;
                            right: 0;
                            bottom: -1px;
                            left: 0;

                            display: inline-block;
                            width: 100%;
                            height: 2px;

                            background: #dc1445;

                            content: "";
                        }
                    }
                }
            }
        }
        .inner-tabel-tab {
            width: 95%;
            margin: 0 auto;
        }
    }
    .tab-table-section {
        .inner-tabel-tab {
            width: 98%;
        }
    }
    .detail-bank-section {
        margin-top: 30px;
        .form-group {
            /* stylelint-disable-next-line */
            label {
                margin-bottom: 0;
                padding-left: 20px;

                font-size: 14px;
            }
        }
        /* stylelint-disable-next-line */
        form {
            display: grid;
            grid-gap: 20px 50px;
            grid-template-columns: repeat(2, 1fr);
            width: 70%;
            padding: 0 60px;
            .bottom-btn-section {
                display: flex;
                flex-wrap: wrap;
                grid-row: 5/5;
                grid-column: 1/3;

                justify-content: flex-end;
                /* stylelint-disable-next-line */
                button {
                    padding: 10px 45px;

                    color: #fff;
                    font-size: 12px;

                    border: 0;
                    border-radius: 30px;
                }
                .verify-btn {
                    button {
                        background: #3aaa35;
                    }
                }
                .update-btn {
                    button {
                        background: #706f6f;
                    }
                    margin-left: 15px;
                }
            }
            /* stylelint-disable-next-line */
            .update-btn {
                /* stylelint-disable-next-line */
                label {
                    margin-bottom: 15px;
                    padding-left: 50px;
                }
            }
        }
    }
    .inner-bank-tab {
        .top-header-section {
            padding: 30px 60px;

            background: #fff;
            h2 {
                position: relative;

                display: inline-block;

                color: #dc1445;
                font-weight: bold;
                font-size: 16px;
                &:after {
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;

                    display: inline-block;
                    width: 100%;
                    height: 2px;

                    background: #dc1445;

                    content: "";
                }
            }
        }
    }
    .new-design-table-sec {
        .inner-tabel-tab {
            padding-top: 0;
        }
    }
}
.new-design-table-sec.customer-deatil-tabs {
    .tablist-section {
        .nav-tabs {
            .nav-link {
                padding: 7px 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                font-family: "Roboto", sans-serif;
                line-height: 26.4px;

                background: #f6f6f6;
                border: 2px solid #e2e2e2;
                border-bottom: 0;
                box-shadow: none;
            }
            .nav-link.active {
                position: relative;

                background: #fff;
                &:before {
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    left: 0;

                    display: block;
                    width: 100%;
                    height: 4px;

                    background: #fff;

                    content: "";
                }
            }
            /* stylelint-disable-next-line */
            li {
                &:first-child {
                    margin-left: 14px;
                }
            }
        }
        .tab-content {
            background: transparent;
            border: 2px solid #e2e2e2;
            box-shadow: none;
        }
    }
    .tab-table-section {
        .inner-tabel-tab {
            width: 100%;
            padding-top: 0;
        }
    }
}
.transaction-section {
    .format {
        height: 42px;
        margin-bottom: 38px;

        background-color: #f3f3f3;

        .inline-block {
            display: inline-block;
            padding: 0 5px;
        }
        /* stylelint-disable-next-line */
        ul {
            min-height: 55px;
            max-height: 55px;
            padding-left: 10px;
            /* stylelint-disable-next-line */
            li:not(:last-child) {
                padding: 0 10px;

                border-right: 1px solid #a0a0a0;
            }
            span.inline-block {
                margin: 12px 10px 0 10px;
            }
        }
    }
}
// TODO need to check
/* stylelint-disable */
:host ::ng-deep .iti {
    width: 100%;
}
/* stylelint-enable */
.virtual {
    padding-top: 20px;
    .search-btn {
        float: right;
    }
    .top-trans-section {
        .virtual-left-section {
            width: 20%;
            @media (min-width: 1300px) and (max-width: 1600px) {
                width: 35%;
            }
            @media (max-width: 1200px) {
                width: 40%;
            }
            h2 {
                font-weight: 500;
                font-size: 12.5px;
            }
            /* stylelint-disable-next-line */
            span {
                font-size: 12px;
            }
        }
    }
}
/* stylelint-disable-next-line */
.top-trans-section {
    .back-btn {
        margin-left: 2vw;
        padding: 2px 18px;

        color: #fff;
        font-weight: 400;
        font-size: 11px;
        line-height: 20.12px;
        text-transform: capitalize;

        background: $primaryColor;
        border: 1px solid $primaryColor;
        border-radius: 5px;
        box-shadow: none;
        /* stylelint-disable-next-line */
        &:hover {
            background: #2a6cdd;
        }
    }
    .adjust-btn {
        padding: 5px 22px;

        color: #fff;
        font-weight: 400;
        font-size: 13px;
        line-height: 20.12px;
        text-transform: capitalize;

        background: $primaryColor;
        border: 1px solid $primaryColor;
        border-radius: 5px;
        box-shadow: none;
        /* stylelint-disable-next-line */
        &:hover {
            background: #2a6cdd;
        }
    }
    .btn-sec-all {
        display: flex;
        grid-gap: 10px;
    }
}
.customer-design {
    .width-100 {
        width: 100% !important;
    }
    .form-section-toggle {

        .commission-form-sec {
            grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.6fr !important;
        }

        .commission-form-sec-col7 {
            grid-template-columns: 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr !important;
        }

        .commission-residual-form-sec {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.6fr !important;
        }
        .form-sec {
            position: relative;

            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr;
            align-items: flex-end;

            &.cashbackCol{
                grid-template-columns: 1fr 1fr 1.3fr 1fr 2fr 1fr 0.8fr;
            }
            .third-input {
                .ng-arrow-wrapper {
                    padding-top: 2px;
                }
            }
            &:nth-child(2) {
                .first-input {
                    display: flex;
                    align-items: center;
                    input {
                        position: relative;

                        width: 100%;
                        margin: 0;
                    }
                    /* stylelint-disable-next-line */
                    label {
                        // margin: 0 10px 0 10px !important;

                        font-size: 11px;
                    }
                }
            }
            /* stylelint-disable-next-line */
            input {
                width: 100%;
                height: 32px;
                padding: 5px 5px;

                color: $Grey2-clr;
                font-size: 11px;
                line-height: 21.12px;

                /* stylelint-disable-next-line */
                &:focus-visible {
                    border: 2px solid #ebebea;
                    outline: 0;
                }
                &::placeholder {
                    color: #9c9898;
                }
            }
            label.filter-label {
                margin-bottom: 5px;

                font-size: 11px;
            }
            label.residualFilterLabel {
                margin-bottom: 5px;

                font-weight: 500;

                font-size: 11px;
            }
            label.label-align {
                margin-left: 12px;
            }

            label.cashbackInputLabel {
                margin-bottom: 5px;

                color: $grayColor;
                font-size: $tableBodyFontSize;
            }

            .ng-select-container {
                width: 100%;
                height: 33px;
                min-height: auto;
                padding: 0;

                color: $Grey2-clr;
                font-size: 12px;

                /* stylelint-disable-next-line */
                input {
                    height: auto;
                    &::placeholder {
                        font-size: 11px;
                        line-height: 21.12px;
                    }
                }

                .ng-value-container{
                    .ng-value{
                        color: $Grey2-clr;
                    }
                }
            }
            .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
                overflow: visible !important;

                font-size: 11px;
            }
            .ng-select .ng-clear-wrapper .ng-clear {
                font-size: 15px;
            }
            .clear-btn {

                .clear_btns {
                    padding: 2px 18px;

                    font-weight: 400;
                    font-size: 11px;
                    line-height: 25px;
                }

                .cancel_btns {
                    position: absolute;
                    top: -10px;
                    right: 5px;

                    margin: 0;

                    padding: 0;

                    background: transparent !important;
                    border: 0 !important;
                }
                .cancel_btns_commision {
                    position: absolute;
                    top: -14px;
                    right: 0;

                    margin: 0;
                    padding: 0;

                    background: transparent !important;
                    border: 0 !important;
                }
                .right-0 {
                    right: 0 !important;
                }
            }
        }
        .bottom-form-sec {
            margin-top: 0;

            background: #ffffff;
            .btn-sec-all {
                width: 210px;
                /* stylelint-disable-next-line */
                button {
                    padding: 6px 20px;

                    color: #fff;
                    font-weight: 400;

                    background: $primaryColor;
                    border: 1px solid $primaryColor;
                    box-shadow: none;
                }
            }
        }
    }
    .margin-b {
        margin-bottom: 15px;
    }
    .customer-search {
        display: flex;
        justify-content: space-around;
        .advanced-srch {
            width: 210px;
            /* stylelint-disable-next-line */
            button {
                margin-left: 42px;
                padding: 6px 20px;
            }
        }
    }

    .disabled {
        opacity: 0.6;

        pointer-events: none;
    }
}

.oneColumn{
    grid-template-columns: 0.3fr 0.3fr 4.5fr 0.3fr 11.3fr 3fr !important;
}

.customerApplicationBtn{
    min-width: 145px;
    max-width: 150px;
}

.customerNavbarAdjustemnt{
    margin-bottom: 0.2px;
}

.selectedProduct{
    background-color: #fff;

    border: none;
}

.quoteDate{
    width: 107px;
}

.label-sec{
    font-size: 11px;
    color: #4c4c4c
}

/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/
.navbar-light {
    .navbar-toggler {
        svg {
            path {
                fill: #fff;
            }
        }

        &:focus-visible {
            border-color: transparent;
            outline: 0;
        }

        &:focus {
            border-color: transparent;
            outline: 0;
        }
    }
}

.headerFixed{
    position: fixed;
    top: 0;

    z-index: 1000;

    width: 100%;
}

.navbar.navbar-light {
    padding: 0.8rem 1rem 0.8rem 1rem;

    @media (max-width: 480px) {
        padding: 1.2rem 1rem 1rem 1rem;
    }

    @media (min-width: 481px) and (max-width: 767px) {
        padding: 1.2rem 1rem 1rem 1rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        padding: 1.2rem 1rem 1rem 1rem;
    }

    background: #262626;

    /* stylelint-disable-next-line */
    .container {
        max-width: 100%;

        @media (min-width: 2000px) {
            max-width: 1550.5px;
        }

        /* stylelint-disable-next-line */
        a {
            color: #fff;

            transition: color ease-in-out 0.2s;

            &.active {
                position: relative;

                color: $primaryColor;

                @media (min-width: 992px) {
                    &:before {
                        position: absolute;
                        right: 0;
                        bottom: -30px;
                        left: 0;

                        display: block;
                        width: 100%;
                        height: 3px;

                        background: $primaryColor;

                        transition: all ease-in-out 0.2s;

                        content: "";
                    }
                }
            }

            /* stylelint-disable-next-line */
            &:hover {
                color: $primaryColor;
            }
        }

        /* stylelint-disable-next-line */
        .top-header-drop {

            /* stylelint-disable-next-line */
            li {
                a {
                    color: $Black-clr !important;
                    font-size: 13px;
                }
            }
        }

        a.navbar-brand {
            position: relative;

            margin-right: 25px;
            margin-left: 0;
            padding-left: 0;

            font-size: 13px;
            line-height: 19.66px;
            // @media(min-width: 992px){
            //     margin-left: 25px;
            // }
        }

        /* stylelint-disable-next-line */
        .first-drop-down-menu {

            /* stylelint-disable-next-line */
            a {
                color: $primaryColor !important;
                font-size: 11px;
                text-decoration: none;
            }

            a.mark-text {
                color: #4c4c4c !important;
            }
        }

        /* stylelint-disable-next-line */
        .drop-down-menu {

            /* stylelint-disable-next-line */
            a {
                padding: 0;

                color: $Black-clr !important;
                font-size: 11px;
            }
        }

        .right-logo {
            position: relative;
            right: 20px;

            display: flex;
            grid-gap: 10px;
            align-items: center;

            text-align: right;

            svg {
                path {
                    transition: all ease-in-out 0.2s;
                }
            }

            /* stylelint-disable-next-line */
            a {
                display: inline-block;

                text-decoration: none;
            }

            ul {
                display: flex;
                grid-gap: 25px;
                align-items: center;

                @media (max-width: 480px) {
                    grid-gap: 8px;
                }

                li {
                    a {
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    span {
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    a.right-logo-img {
                        svg {
                            width: 95px;
                            height: 60px;
                        }
                    }

                    .faq-icon {
                        &:hover {
                            svg {
                                path {
                                    fill: #fff !important;
                                }
                            }
                        }
                    }

                    .js-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 48px;
                        height: 48px;
                        margin-right: 10px;

                        color: #fff;
                        font-size: 24px;
                        text-transform: uppercase;
                        text-decoration: none;

                        background: #307aff;
                        border-radius: 50%;
                        cursor: pointer;

                        &:hover {
                            color: #fff !important;
                        }
                    }
                }
            }

            .right-logo-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 125px;
                height: 60px;
                overflow: hidden;

                cursor: default;
                /* stylelint-disable-next-line */
                svg {
                    width: 100% !important;
                    height: auto !important;
                }

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            /* stylelint-disable-next-line */
            img {
                max-width: 100%;
            }
        }

        .navbar-toggler {

            /* stylelint-disable-next-line */
            svg {

                /* stylelint-disable-next-line */
                path {
                    fill: $White-clr;
                }
            }

            &:focus-visible {
                border-color: transparent;
                outline: 0;
            }

            &:focus {
                border-color: transparent;
                outline: 0;
            }
        }
    }
}

.navbar-expand-lg {

    /* stylelint-disable-next-line */
    .navbar-nav {

        /* stylelint-disable-next-line */
        .dropdown-menu {
            position: absolute;
            top: 58px;

            border-radius: 3px;
            box-shadow: 1px 3px 11px 0 #8b8b8b;

            /* stylelint-disable-next-line */
            .dropdown-item {
                padding: 10px 20px 10px 40px;

                color: #000 !important;
            }
        }
    }
}

/* stylelint-disable-next-line */
.navbar-nav {

    /* stylelint-disable-next-line */
    li {
        position: relative;

        margin-right: 15px;
        margin-left: 20px;

        padding-left: 0;

        /* stylelint-disable-next-line */
        &:first-child {
            margin-right: 14px;
            margin-left: 15px;
        }

        /* stylelint-disable-next-line */
        .dropdown-menu {

            /* stylelint-disable-next-line */
            .dropdown-item {
                position: relative;

                /* stylelint-disable-next-line */
                &:active {
                    background-color: #f8f9fa;
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-sub-arrow {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 8px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/sub-arrow.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-check {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/check.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-info-circle-solid {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/info-circle-solid.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-exclamation-triangle-solid {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/exclamation-triangle-solid.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-undo-alt-solid {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/undo-alt-solid.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-cogs-solid {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/cogs-solid.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            .dropdown-item-map-marked-alt-solid {

                /* stylelint-disable-next-line */
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/map-marked-alt-solid.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    li.reporting {

        /* stylelint-disable-next-line */
        > a {

            /* stylelint-disable-next-line */
            &:before {
                position: absolute;
                top: 6px;
                left: 0;

                display: inline-block;
                width: 20px;
                height: 20px;

                background: url(../../img/reporting.svg);
                background-repeat: no-repeat;
                background-size: contain;

                content: "";
            }

            /* stylelint-disable-next-line */
            &:hover {

                /* stylelint-disable-next-line */
                &:before {
                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/reporting-hover.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    li.general {

        /* stylelint-disable-next-line */
        > a {
            display: flex;
            align-items: center;

            padding-top: 0.3125rem;
            padding-bottom: 0.3125rem;

            font-size: 13px;
            line-height: 19.66px;

            /* stylelint-disable-next-line */
            &:before {
                display: inline-flex;
                align-items: center;

                content: "";
            }
        }
    }

    /* stylelint-disable-next-line */
    li.general.cross-arrow-icon {

        /* stylelint-disable-next-line */
        > a {
            // &:before {
            //     width: 23.0216px;
            //     height: 23.908px;

            //     background: url(../../img/cross-arrow.svg);
            //     background-repeat: no-repeat;
            //     background-size: contain;
            // }
            // &:hover {
            //     &:before {
            //         width: 23.0216px;
            //         height: 23.908px;

            //         background: url(../../img/cross-arrow-red.svg);
            //         background-repeat: no-repeat;
            //         background-size: contain;
            //     }
            // }
        }
    }

    /* stylelint-disable-next-line */
    li.general.pay-icon {

        /* stylelint-disable-next-line */
        > a {
            /* stylelint-disable-next-line */
            // &:before {
            //     width: 23.0088px;
            //     height: 14.381px;

            //     background: url(../../img/pay.svg);
            //     background-repeat: no-repeat;
            //     background-size: contain;
            // }
            // &:hover {
            //     &:before {
            //         width: 23.0088px;
            //         height: 14.381px;

            //         background: url(../../img/pay-red.svg);
            //         background-repeat: no-repeat;
            //         background-size: contain;
            //     }
            // }
        }
    }

    /* stylelint-disable-next-line */
    li.general.user-icon {

        /* stylelint-disable-next-line */
        > a {
            /* stylelint-disable-next-line */
            // &:before {
            //     width: 14.8056px;
            //     height: 16.2924px;

            //     background: url(../../img/user.svg);
            //     background-repeat: no-repeat;
            //     background-size: contain;
            // }
            // &:hover {
            //     &:before {
            //         width: 14.8056px;
            //         height: 16.2924px;

            //         background: url(../../img/user-red.svg);
            //         background-repeat: no-repeat;
            //         background-size: contain;
            //     }
            // }
        }
    }

    /* stylelint-disable-next-line */
    li.messages {

        /* stylelint-disable-next-line */
        > a {

            /* stylelint-disable-next-line */
            &:before {
                position: absolute;
                top: 5px;
                left: 0;

                display: inline-block;
                width: 20px;
                height: 20px;

                background: url(../../img/messages.svg);
                background-repeat: no-repeat;
                background-size: contain;

                content: "";
            }

            /* stylelint-disable-next-line */
            &:hover {

                /* stylelint-disable-next-line */
                &:before {
                    display: inline-block;
                    width: 20px;
                    height: 20px;

                    background: url(../../img/messages-hover.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    li.logout {

        /* stylelint-disable-next-line */
        > a {

            /* stylelint-disable-next-line */
            &:before {
                position: absolute;
                top: 6px;
                left: 0;

                display: inline-block;
                width: 22px;
                height: 22px;

                background: url(../../img/logout.svg);
                background-repeat: no-repeat;
                background-size: contain;

                content: "";
            }

            /* stylelint-disable-next-line */
            &:hover {

                /* stylelint-disable-next-line */
                &:before {
                    display: inline-block;
                    width: 22px;
                    height: 22px;

                    background: url(../../img/logout-hover.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    li.exchange-alt-solid {

        /* stylelint-disable-next-line */
        > a {

            /* stylelint-disable-next-line */
            &:before {
                position: absolute;
                top: 5px;
                left: 0;

                display: inline-block;
                width: 20px;
                height: 20px;

                background: url(../../img/exchange-alt-solid.svg);
                background-repeat: no-repeat;
                background-size: contain;

                content: "";
            }
        }
    }
}

.breadcrumb-section {
    height: 84px;

    background-color: #f6f6f6;
    // background: #fff;

    border-bottom: 1px solid #dfdfdf;

    /* stylelint-disable-next-line */
    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;

        margin-bottom: 11px;
        padding: 27px 0 0 0;

        @media (max-width: 480px) {
            flex-wrap: nowrap;
        }

        .left-section {
            h3 {
                margin-bottom: 10px;

                font-weight: bold;
                font-size: 20px;
            }

            p {
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
            }

            /* stylelint-disable-next-line */
            h2 {
                font-size: 18px;
            }

            .breadcrumb {
                margin-bottom: 15px;
                padding: 0;

                font-size: 11px;

                background: transparent;
            }

            .breadcrumb-item {

                /* stylelint-disable-next-line */
                a {
                    color: $primaryColor;
                    font-weight: 500;
                    text-decoration: none;
                }
            }

            .breadcrumb-item.active {
                color: #000;
                font-weight: 500;
            }
        }
    }
}

nav.breadcrumb-section.bg-clr-div {
    background: #f6f6f6;
    border: 0;
}

.top-nav-section-div {

    /* stylelint-disable-next-line */
    .container {
        .user-main-div {
            position: relative;
            left: -40px;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    display: flex;
    align-items: flex-end;

    background: #fff;
    border-bottom: 2px solid #e3e3e3;

    /* stylelint-disable-next-line */
    p {
        padding: 25px 0;

        color: #5e5759;
        font-weight: 500;
        font-size: 16px;
    }

    /* stylelint-disable-next-line */
    ul {
        padding: 0 40px;

        /* stylelint-disable-next-line */
        li {
            display: inline-block;
            padding: 25px 20px;

            /* stylelint-disable-next-line */
            a {
                color: #5e5759;
                font-weight: 500;
                font-size: 12px;
                text-decoration: none;
            }
        }

        li.active {
            position: relative;

            &:before {
                position: absolute;
                right: 0;
                bottom: -4px;
                left: 0;

                display: inline-block;
                height: 3px;

                background: #5e5759;

                content: "";
            }
        }
    }

    .user-container {
        height: 100%;
    }
}

.drop-down-main {
    position: relative;

    /* stylelint-disable-next-line */
    .drop-down-menu {
        position: absolute;
        top: 49px;
        left: 0;
        z-index: 999;

        width: 150px;

        background: $White-clr;
        box-shadow: 1px 6px 13px 1px #c1c1c2;

        &.accountDropDownContainer {
            animation-name: animFadeIn;
            animation-duration: 0.5s;
        }

        li.nav-item.logout {
            display: flex;
            grid-gap: 8px;
            align-items: center;
        }

        /* stylelint-disable-next-line */
        ul {
            display: block !important;

            text-align: left;

            /* stylelint-disable-next-line */
            li {
                padding: 15px 20px;

                border-bottom: 1px solid #e3e3e3;

                /* stylelint-disable-next-line */
                &:last-child {
                    border-bottom: 0;
                }

                /* stylelint-disable-next-line */
                h2 {
                    display: block;

                    color: #000;
                    font-size: 13px;
                    text-align: left;
                }

                /* stylelint-disable-next-line */
                span {
                    display: block;
                    margin-top: 5px;

                    font-size: 12px;
                }

                /* stylelint-disable-next-line */
                &:hover {
                    background: #ededef;
                }
            }
        }
    }
}

/* stylelint-disable-next-line */
.first-drop-down-menu {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 999;

    width: 380px;
    padding: 10px;

    background: #fff;
    border: 1px solid #e3e3e3;
    box-shadow: 1px 6px 13px 1px #c1c1c2;

    &.notificationContainer {
        overflow: auto;

        transform: scale(0);

        animation-name: animZoomIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        .notificationContent {
            position: relative;

            .notificationBody{
                max-height: 350px;

                overflow-y: scroll;

                &.noScroll{
                    overflow: auto;
                }
            }

            .footer{
                position: absolute;
                bottom:0;
                left: 0;

                width: 100%;
            }
        }

        .notificationHeader{
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    /* stylelint-disable-next-line */
    .close-btn {
        cursor: pointer;
    }

    /* stylelint-disable-next-line */
    h2 {
        padding: 10px 15px 15px 15px;

        color: #4c4c4c;
        font-size: 16px;
        text-align: left;
    }

    /* stylelint-disable-next-line */
    ul {
        display: block !important;

        /* stylelint-disable-next-line */
        li {
            padding: 10px 15px 10px;

            color: #4c4c4c;
            font-size: 11px;
            line-height: 20px;
            text-align: left;

            background: #f6f6f6;
            border-bottom: 1px solid #e3e3e3;

            .notify-msg {
                display: flex;
                grid-gap: 40px;

                /* stylelint-disable-next-line */
                svg {
                    filter: invert(40%) sepia(19%) saturate(4050%) hue-rotate(195deg) brightness(103%) contrast(103%);
                }
            }

            .notify-date {
                display: flex;
                margin-top: -10px;

                font-size: 10px;
            }
        }

        /* stylelint-disable-next-line */
        ul {
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 10px;

            /* stylelint-disable-next-line */
            li {

                /* stylelint-disable-next-line */
                span {
                    font-size: 11px;
                }
            }
        }
    }
}

.noti-icon {
    position: relative;

    .notification-msg {
        position: absolute;
        top: -4px;
        right: -7px;
        z-index: 999;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;

        color: $White-clr;
        font-size: 12px;

        background: #e11455;
        border-radius: 50%;
    }
}

@media (max-width: 576px) {
    .navbar-collapse {
        display: flex;

        .mr-auto {
            margin-left: -20px;
        }
    }
}

.header-logo {
    text-align: center;

    /* stylelint-disable-next-line */
    a {
        display: inline-block;
    }

    /* stylelint-disable-next-line */
    img {
        max-width: 80%;
    }
}

.msg-icon {
    position: relative;

    .notification-msg {
        position: absolute;
        top: -4px;
        right: -7px;
        z-index: 999;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 15px;
        height: 15px;

        color: #fff;
        font-size: 12px;

        background: #e11455;
        border-radius: 50%;
    }
}

/* stylelint-disable-next-line */
.version-dash {

    /* stylelint-disable-next-line */
    p {
        margin-right: 15px;
        padding-top: 3px;
    }

    /* stylelint-disable-next-line */
    select {

        /* stylelint-disable-next-line */
        &:focus {
            outline: 0;
        }
    }
}

.ui-widget-header {
    background: #9d9d9c;
}

.breadcrumb-section.home-page {
    .container {
        .left-section {

            /* stylelint-disable-next-line */
            h2 {
                margin-bottom: 8px;

                font-weight: 500;
            }

            p {
                font-weight: 500;
            }
        }
    }
}

.table{
    &.default{
        thead{
            background-color:$primaryColor;
            th{
                color: $whiteColor;

                font-weight:500;
                font-size: $tableTitleFontSize;
            }
        }

        tbody{
            tr{
                td{
                    padding: 8px 10px;

                    font-size:$tableBodyFontSize;

                    .input{
                        &.inputDefault{
                            width: 50px;
                            height: 26px;
                            padding: 3px;

                            font-size:11px;
                            text-align: center !important;

                            border: 1px solid $borderColor;
                        }
                        &.inputBorderless{
                            width: 42px;
                            height: 23px;
                            padding: 3px;

                            font-size:11px;
                            text-align: center !important;

                            border: none;
                        }
                    }

                    .select{
                        &.selectDefault{
                            .ng-select-container{
                                width:58px;
                                height:25px;
                                padding: 4px 8px;

                                border: 1px solid $borderColor;
                                border-radius:0;
                                .standardFee2{
                                    width: 58px;
                                }

                                .ng-placeholder, .ng-value-container{
                                    padding:0 0 0 1px;
                                }
                            }
                            .ng-arrow-wrapper{
                                width:18px;
                                padding:0;
                            }
                            &.ng-select-focused{
                                .ng-select-container{
                                    box-shadow:none;
                                }
                            }
                        }
                    }
                    .pciPlusAlign{
                        position:relative;
                        left:-11px;
                    }

                    .box{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 6px 10px;

                        border:1px solid $borderColor;
                    }
                }
            }
        }
    }

    &.table-striped {
        thead {
            color: $primaryColor;
            font-weight: 400;
            font-size: 12px;
            line-height: 19.2px;

            background: #f6f6f6;
            border: $standardBorderWidth solid $primaryColor;

            &.tableHeadPrimary {
                background-color: $primaryColor;
                border: 0 !important;

                th {
                    color: white !important;
                    font-weight: 500 !important;

                    border-bottom: 0 !important;
                }

                th:nth-child(1) {
                    padding-right: 63px !important;

                    text-align: center;
                }

                th:nth-child(2) {
                    padding-left: 20px !important;
                }
            }
        }
        tbody {
            tr {
                --bs-table-striped-bg: #fff;
                border-bottom: $standardBorderWidth solid $borderColor;

                /* stylelint-disable-next-line */
                &.active {
                    background: #f6f6f6;
                }

                .blue-color {
                    color: #307aff;
                }

                .drop-row {
                    span {
                        color: $primaryColor;
                    }

                    @media (max-width: 1500px) {
                        grid-gap: 5px;
                    }

                    @media (max-width: 991px) {
                        grid-gap: 5px;
                        margin-top: -1px;
                    }

                    @media (max-width: 480px) {
                        width: 80px;
                    }
                }
            }

            .collapse {
                background-color: #f6f6f6 !important;
            }
        }

        .lastBtnSection {

            /* stylelint-disable-next-line */
            .view-group-sec {
                .view-btn-sec {
                    &:hover {
                        opacity: 0.8;
                    }

                    width: initial;
                    padding: 8px 45px;

                    background: #3aaa35;
                }
            }
        }

    }

    &.vtTable{
        tbody {
            tr {
                border-bottom: none !important;
                &.amexType{
                    border-top: 2px solid $primaryColor;
                }
                td {
                    border-bottom: none !important
                }
            }

        }
    }

    &.noRecords{
        margin-bottom: 60px;
    }

    &.tableResponsive{
        &.col4{

            tr{
                /* stylelint-disable-next-line */
                th{
                    width: 25% !important;
                    @include tableResponsive;
                }

                td{
                    @include tableResponsive;
                }
            }
        }

        &.col3{

            tr{
                /* stylelint-disable-next-line */
                th{
                    width: 33.33% !important;
                    @include tableResponsive;
                }

                td{
                    @include tableResponsive;
                }
            }
        }
    }

    &.table-borderless {

        /* stylelint-disable-next-line */
        tr {
            &.noBorder {
                &:last-child {
                    border-bottom: none !important;
                }
                /* stylelint-disable-next-line */
                td {
                    padding: 0 !important;

                    border: none !important;
                }
            }
        }
    }

    .action-buttons {
        .btn {
            margin: 3px 0;
        }
    }
}


.input-group {
    > .input-group-append {
        > .btn {
            padding: 0 10px;
        }
    }
}
.customer-deatil-tabs-form {
    #tabs-1 {
        padding-top: 20px !important;

        background: #f6f6f6 !important;
    }
    .detail-form-section {
        padding: 0 !important;
    }
}

.inner-section-heading{
    margin-bottom: 10px !important;

    font-weight: 400;
    font-size: 13px;

    p{
        font-weight: 500;
    }
}

.profile {
    max-width: 1200px;
    min-height: 900px;
    margin: 50px auto 0;
    padding-top: 35px;

    background-color: #fff;
    border: 1px solid $borderColor;

    /* stylelint-disable-next-line */
    .noti-container {
        width: 95% !important;
        table {
            /* stylelint-disable-next-line */
            thead {
                /* stylelint-disable-next-line */
                tr {
                    /* stylelint-disable-next-line */
                    th {
                        img {
                            width: 14px;
                            height: 15px;
                            margin: 4px 7px -4px 0px;
                        }
                    }
                }
            }
        }
    }
    /* stylelint-disable-next-line */
    .container {
        width: 95%;
        margin: 0 auto;

        background-color: #fff;
        .login-history {
            padding: 20px 0 10px;

            font-size: 15px;
        }
        /* stylelint-disable-next-line */
        .table-responsive {
            .table {
                /* stylelint-disable-next-line */
                thead {
                    color: #0491df;

                    font-weight: normal;

                    background-color: #f6f6f6;
                    /* stylelint-disable-next-line */
                    tr {
                        /* stylelint-disable-next-line */
                        th {
                            position: relative;

                            height: 34px;
                            padding: 0 10px;

                            color: $primaryColor;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 19.2px;
                            vertical-align: middle;
                            &:nth-child(1) {
                                width: 18%;
                            }
                            &:nth-child(2) {
                                width: 10%;
                            }
                            &:nth-child(3) {
                                width: 9%;
                            }
                            &:nth-child(4) {
                                width: 9%;
                            }
                            &:nth-child(5) {
                                width: 10%;
                            }
                            &:nth-child(6) {
                                width: 6%;
                            }
                        }
                    }
                }
                /* stylelint-disable-next-line */
                tbody {
                    /* stylelint-disable-next-line */
                    tr {
                        /* stylelint-disable-next-line */
                        td {
                            height: 35px;
                            padding: 0 10px;

                            color: #4c4c4c;
                            font-size: 11px;
                            line-height: 11px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        /* stylelint-disable-next-line */
        .section {
            padding-top: 20px;
            // border-bottom: 1px solid #000;
            /* stylelint-disable-next-line */
            p {
                padding-bottom: 10px;
            }
            .second-otp {
                padding-top: 20px;
            }

            .sub-section {
                margin-bottom: 10px;
            }
            code-input#password {
                margin: 20px 0 0 0;
            }
            .form-group {
                label {
                    padding-left: 2px;
                }
            }
            /* stylelint-disable-next-line */
            label {
                padding-left: 2px;

                font-size: 13px;
            }
        }
    }
    .no-pad {
        padding: 0 0 20px !important;
    }
    .form-group-buttons {
        display: flex;
        justify-content: space-between;
        padding: 0 0 1rem 0;
        .notify-msg {
            width: 80%;
            padding: 0 30px;

            line-height: 20px;
            p {
                padding-top: 5px;
            }
        }
        .notify-save {
            margin-right: 28px;
        }
        .back-div-sec {
            width: 120px;
            margin-left: 30px;
            padding: 1px 10px;

            color: #fff;
            font-size: 11px;
            line-height: 20.12px;

            background: #307aff;
            border: 1px solid #307aff;
            border-radius: 5px;
            box-shadow: none;
            @media (max-width: 768px) {
                width: 15vw;
                padding: 4px 5px;
            }
        }
        .auth-div-sec {
            height: 36px;
            margin-top: 4px;
            margin-bottom: 0 !important;
            padding: 4px 18px;

            color: #fff;
            font-size: 11px;
            line-height: 20.12px;

            background: #307aff;
            border: 1px solid #307aff;
            border-radius: 5px;
            box-shadow: none;
            @media (max-width: 768px) {
                width: 15vw;
                padding: 4px 5px;
            }
            &:disabled {
                color: #000;

                background-color: #f6f6f6;
                border: 1px solid #dfdfdf;

                cursor: default;
            }
        }


        .user-create-margin {
            margin: 0 30px 0 5px !important;
        }
    }
    .container-header {
        display: flex;
        justify-content: space-around;
        margin: 0 40vw 0 4vw;
        padding-bottom: 20px;
        @media (min-width: 1300px) and (max-width: 1600px) {
            margin: 0 25vw 0 4vw;
        }
        @media (min-width: 992px) and (max-width: 1399px) {
            margin: 0 25vw 0 4vw;
        }
        @media (max-width: 991px) {
            margin: 0 20px;
        }
        @media (max-width: 480px) {
            flex-wrap: wrap;
        }
        /* stylelint-disable-next-line */
        .notification-icon {
            display: flex;
            /* stylelint-disable-next-line */
            @media (max-width: 480px) {
                padding: 10px;
            }
            /* stylelint-disable-next-line */
            p {
                padding-left: 12px;
            }
            img {
                width: 16px;
                height: 18px;
            }
        }
    }
    hr {
        width: 90%;
    }
    .notification-inner-section {
        width: 90%;
        margin: 0 auto;
        .col-md-20 {
            width: 10%;
            margin-left: 32px;
        }
        .col-md-select {
            width: 10%;
            margin-top: 45px;
            margin-left: 32px;
        }
        .col-md {
            width: 20%;
            margin-left: 20px;
            @media (min-width: 481px) and (max-width: 767px) {
                margin-left: 0;
            }
        }
        /* stylelint-disable-next-line */
        p {
            padding: 20px 0;

            font-weight: 500;
        }
        .after-heading {
            padding: 40px 0;
        }
        /* stylelint-disable-next-line */
        img {
            padding: 10px 0;
        }
        input[type="checkbox"] {
            position: relative;

            margin-right: 22px;

            vertical-align: middle;

            @media only screen and (min-width: 1004px) and (max-width: 1200px) {
                margin-right: 5px;
            }
        }
    }
    .edit-group-inner-section {
        width: 95%;
        margin: 0 auto;
        .more-pad {
            padding-left: 10px;
        }
        /* stylelint-disable-next-line */
        label {
            margin-bottom: 9px;
            padding-left: 3px;
        }
        .description {
            /* stylelint-disable-next-line */
            p {
                padding: 16px;

                font-size: 11px;
                line-height: 27px;

                border: 1px solid #dfdfdf;
            }
        }
        .dashboard-div {
            margin-top: 50px;
        }
        .permission-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @media (max-width: 500px) {
                margin-top: 20px;
            }
        }
    }
}
/* stylelint-disable-next-line */
.form-group {
    .iti {
        width: 100% !important;
    }
}

.heading-font {
    font-size: smaller;
}


.permission-section{
    margin-top: 17px;
}

.permission-heading{
    margin-bottom: 6px;
    padding-left: 0 !important;

    font-weight: 500;
    font-size: 14px;
}

.dashboard-heading{
    margin-top: 20px;
    margin-bottom: 15px;
    padding-left: 0 !important;

    font-weight: 500;
    font-size: 14px;
}

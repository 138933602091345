.tableContainer.default.minHeight {
    min-height: 471px;
}

.tableContainer.default {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tableRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}

.tableHeader, .tableCell {
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 9px 12px;

}

.tableHeader {
    background-color: #307aff;
    color: white;
    font-weight: normal;
    font-size: 12px;
    height: 34px;
    line-height: 17px;
}

.tableCell {
    color: #4C4C4C;
    font-weight: normal;
    font-size: 11px;
    vertical-align: middle;
    padding: 9px 12px;
    height: 43px;
    line-height: 28px;
}

.tableCell a {
    color: #007bff;
    text-decoration: none;
}

.tableCell a:hover {
    text-decoration: underline;
}


.tableContainer.default .bottom-table-foot.stretch {
    margin: 0 -32px;
}

.tableContainer.default .bottom-table-foot {
    margin-top: 0 !important;
    background: #fff;
    border-top: 1px solid #dadada;
}

.noData {
    padding: 9px 12px;
    color: #4C4C4C;
    font-weight: normal;
    font-size: 11px;
    vertical-align: middle;
}


.alertsSection {
    max-width: 1200px;
    min-height: 400px;
    color: $grayColor;

    background-color: $whiteColor;
    .alertsSubSection {
        width: 100%;
        padding: 0 32px 0 32px;

        font-size: $textSizeNormal;

        .firstAlertSection {
            border-bottom: 2px solid $borderColor;
        }

        .alertMainArea {
            display: flex;
            padding: 0;

            .alertContent {
                box-sizing: border-box;
                width: 65%;
                padding-right: 10px;
            }

            .dropdownContainer {
                display: flex;
                justify-content: flex-end;
                width: 50%;

                .dropdown {
                    width: 200px;
                    height: 37px;
                }

                .mailSection {
                    position: relative;

                    .emailInput {
                        margin-right: 25px;
                        padding: 0 40px 0 12px;

                        font-size: 12px;
                        line-height: 35px;

                        border: 1px solid $borderColor;

                        &.emailFormControl:focus {
                            border-color: $lightBlue;
                            outline: 0;
                            box-shadow: 0 0 0 0.25rem $darkBlue;
                        }

                        &.errorMessage:focus {
                            border-color: $errorColor;
                        }
                    }

                    .errorText {
                        margin: 6px 0 0 0;

                        color: $errorColor;
                    }

                    .icon {
                        position: absolute;
                        top: 12px;
                        right: 0;
                    }

                    .removeIcon {
                        position: absolute;
                        right: 0;

                        padding: 12px 0 0 0;
                    }
                }
            }
        }
    }
}

.tab-link {
    padding: 0.8rem 2rem !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

/**
 Color variables
**/

$bdy-clr: #4c4c4c;
$White-clr: #fff;
$Black-clr: #000;
$Black2-clr: #000a1e;
$Grey-clr: #DFDFDF;
$Grey2-clr: #4C4C4C;
$blue-clr: #307aff;
$backHover: #efefef;
$msgSearchBorder: #dedede;
$msgSearchBorderHover: #c2dbfe;



/**
 mixin variables
**/
@mixin gridPort($par1) {
    display: grid;
    grid-template-columns: $par1;
}

@mixin posFix{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin flexPort{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@mixin flexPort2{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

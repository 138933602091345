.componentLoader{
    &.animationFadeOut{
        animation-delay: .5s;
        .appLoader{
            animation-name: animFadeOut;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
        }
    }
}

.gridTableContainer {
    display: grid;
    width: 100%;

    &.default {
        grid-template-columns: 1.3fr 1.7fr 1fr 1fr 1fr;
    }

    .gridTableHeader,
    .gridTableBody {
        display: contents;
    }

    .gridTableHeaderItem {
        padding: 10px;

        color: white;
        font-weight: normal;
        font-size: 12px;
        text-align: left;

        background-color: #317aff;
        border-bottom: 1px solid #dfdfdf;

        &.girdSpanCol {
            grid-column: span 2;
        }
    }

    .gridTableBodyItem {
        padding: 10px;

        font-size: 12px;
        text-align: left;

        border-bottom: 1px solid #dfdfdf;

        &.girdSpanCol {
            grid-column: span 2;
        }
    }
}

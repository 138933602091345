.business_type {
    background: #fff;

    /* stylelint-disable-next-line */

    .row {
        // justify-content: space-between;

        &.disableCustomStyle {
            justify-content: inherit;
            padding: inherit;
        }

        .mcc-container {
            @media (max-width: 480px) {
                padding: 20px;
            }

            @media (max-width: 991px) {
                padding: 15px;
            }

            .next-btn-section {
                &.common-btn-section {

                    &:disabled,
                    &[disabled] {
                        background: #ccc;
                        border: 1px solid #ccc;
                    }
                }

                &.success {
                    background-color: $successColor !important;
                    border: 1px solid $successColor !important;
                }
            }

            /* stylelint-disable-next-line */

        }

        /* stylelint-disable-next-line */
        .left-section {
            flex: 0 0 45.333333%;
            max-width: 45.333333%;
            padding: 30px 44px;

            font-size: $regularFontSize;

            line-height: 22px;

            border: 1px solid $primaryColor;

            @media (max-width: 991px) {
                margin: 20px;
            }

            /* stylelint-disable-next-line */
            h1 {
                font-weight: 600;
                font-size: 14px;

                line-height: 29px;
            }

            /* stylelint-disable-next-line */
            p {
                margin: 15px 0px;
            }
        }

        /* stylelint-disable-next-line */
        .rightSection {
            flex: 0 0 50.333333%;
            max-width: 50.333333%;

            @media (max-width: 991px) {
                max-width: 100% !important;
            }

            @media (max-width: 480px) {
                max-width: 38% !important;
            }

            .first-tab-form {
                .form-group {
                    input {

                        /* stylelint-disable-next-line */
                        &:checked+label {

                            /* stylelint-disable-next-line */
                            &:after {
                                position: absolute;
                                top: 3px;
                                left: -58px;

                                display: block;
                                width: 6px;
                                height: 6px;

                                background: none;
                                border-radius: 50%;

                                content: "";
                            }
                        }
                    }
                }
            }

            .company_name {
                .company_number {

                    button[disabled]:hover {
                        background-color: #ccc;
                        border: 1px solid #ccc;
                    }


                    .vat {
                        /* stylelint-disable-next-line */
                        padding: 15px;

                        /* stylelint-disable-next-line */
                        .main-group {
                            float: left;
                            margin-right: 30px;
                            margin-bottom: 12px;

                            /* stylelint-disable-next-line */
                            input {
                                position: relative;

                                display: inline-block;

                                width: 10px;
                                height: 10px;
                                margin: 15px 10px 15px 0;
                                padding: 5px;

                                vertical-align: middle;

                                background: #f1f1f1;
                                background-color: transparent;
                                border: 1px solid #343434;
                                border-radius: 2px;
                                box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
                                cursor: pointer;

                                -webkit-appearance: none;
                                content: "";

                                /* stylelint-disable-next-line */
                                &:checked+label {
                                    position: relative;

                                    /* stylelint-disable-next-line */
                                    &:after {
                                        position: absolute;
                                        bottom: 4px;
                                        left: -19px;

                                        display: block;
                                        width: 6px;
                                        height: 6px;

                                        background: #343434;
                                        border-radius: 50%;

                                        content: "";
                                    }
                                }
                            }
                        }
                    }

                    .mb-3 {
                        padding: 0;
                    }
                }
            }
        }
    }

    .business_type_ineer {
        display: flex;
        grid-gap: 60px;

        &.addressDetail {
            grid-gap: 1px;
        }

        /* stylelint-disable-next-line */
        .left_section {
            display: grid;
            grid-column-gap: 30px;
            grid-template-columns: 1fr 1fr;

            width: 100%;
            margin-top: 16px;

            border: none;

            /* stylelint-disable-next-line */
            .turnover-head-section {
                display: flex;
                flex-wrap: wrap;
                grid-column-start: 1;
                grid-column-end: 3;

                width: 60%;
            }

            &.third-in-section {

                // grid-template-columns: 1fr;
                /* stylelint-disable-next-line */
                .main-group {
                    display: flex;
                    grid-gap: 10px;

                    /* stylelint-disable-next-line */
                    input {
                        margin: 0 0 10px 0;
                    }

                    /* stylelint-disable-next-line */
                    label {
                        height: max-content;
                        margin: 0 0 10px 0;

                        font-size: 12px;
                    }
                }
            }

            /* stylelint-disable-next-line */
            label {
                width: 50%;
            }

            /* stylelint-disable-next-line */
            .left-input-section {
                display: flex;
                grid-gap: 5px;
                align-items: center;
                justify-content: center;

                margin: -12px 0 -10px -120px;

                /* stylelint-disable-next-line */
                input {
                    width: 12px;
                    margin: 0;
                }

                /* stylelint-disable-next-line */
                label {
                    width: 100%;
                    margin: 2px 0 0 0;

                    font-size: 12px;
                }

                .businessTradText {
                    margin-left: -35px;
                }
            }

            img {
                width: 200px;
                height: 182px;

                @media (max-width: 480px) {
                    width: 150px;
                    height: 147px;
                }
            }

            /* stylelint-disable-next-line */
            .turnover-div-main {
                display: flex;

                /* stylelint-disable-next-line */
                .left {
                    width: 100%;

                    /* stylelint-disable-next-line */
                    .ng-select {

                        /* stylelint-disable-next-line */
                        .ng-select-container {
                            min-height: 38px !important;
                        }

                        .ng-value-container {
                            .ng-input {
                                input {
                                    height: 25px;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    /* stylelint-disable-next-line */
                    input {
                        width: 100%;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        border-radius: 5px;

                        &::placeholder {
                            color: #000;
                        }
                    }
                }

                /* stylelint-disable-next-line */
                .right {
                    width: 100%;

                    /* stylelint-disable-next-line */
                    input {
                        width: 90%;
                    }
                }
            }
        }

        .right_section {
            .company_name {
                margin-top: 15px;
                padding: 15px;

                overflow: hidden;

                border: 1px solid #dfdfdf;

                h3 {
                    margin-bottom: 15px;
                }

                /* stylelint-disable-next-line */
                p {
                    margin-bottom: 20px;

                    font-size: 14px;
                    line-height: 20px;
                }

                /* stylelint-disable-next-line */
                select {
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 6.5px 15px;

                    font-size: 12px;

                    border: 1px solid #d7d7d6;
                    border-radius: 2px;
                }

                /* stylelint-disable-next-line */
                .main-group {
                    float: left;
                    margin-right: 30px;

                    /* stylelint-disable-next-line */
                    input {
                        position: relative;

                        display: inline-block;
                        margin: 15px 10px 15px 0;
                        padding: 5px;

                        vertical-align: middle;

                        background: #f1f1f1;
                        background-color: transparent;
                        border: 1px solid #343434;
                        border-radius: 2px;
                        box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
                        cursor: pointer;

                        -webkit-appearance: none;
                        content: "";

                        /* stylelint-disable-next-line */
                        &:checked+label {
                            position: relative;

                            /* stylelint-disable-next-line */
                            &:after {
                                position: absolute;
                                bottom: 13px;
                                left: -19px;

                                display: block;
                                width: 6px;
                                height: 6px;

                                background: #343434;
                                border-radius: 50%;

                                content: "";
                            }
                        }
                    }
                }
            }

            .section-end {
                margin-top: 20px;

                /* stylelint-disable-next-line */
                p {
                    margin-top: 25px;

                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .main-tab-div-section {
        padding: 0;

        background: transparent;
    }

    /* stylelint-disable-next-line */
    .left_section {

        // width: 75%;
        @media (max-width: 480px) {
            width: 311%;
        }

        /* stylelint-disable-next-line */
        .vat {

            /* stylelint-disable-next-line */
            .main-group {
                display: flex;
                align-items: center;
                float: left;
                margin-right: 30px;
                margin-bottom: 0;

                /* stylelint-disable-next-line */
                input {
                    position: relative;

                    display: inline-block;

                    margin: 0 10px 0 0;

                    vertical-align: middle;

                    background-color: transparent;
                    border: 1px solid #343434;
                    border-radius: 2px;
                    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
                    cursor: pointer;

                    content: "";

                    /* stylelint-disable-next-line */
                    &:checked+label {
                        position: relative;

                        /* stylelint-disable-next-line */
                        &:after {
                            position: absolute;
                            bottom: 6px;
                            left: -19px;

                            display: block;
                            width: 6px;
                            height: 6px;

                            background: none;
                            border-radius: 50%;

                            content: "";
                        }
                    }
                }
            }
        }

        .company_address {

            /* stylelint-disable-next-line */
            .vat {
                padding-top: 15px;

                /* stylelint-disable-next-line */
                .main-group {
                    float: left;
                    margin-right: 30px;
                    margin-bottom: 12px;

                    /* stylelint-disable-next-line */
                    input {
                        position: relative;

                        display: inline-block;

                        width: 10px;
                        height: 10px;
                        margin: 15px 10px 15px 0;
                        padding: 5px;

                        vertical-align: middle;

                        background: #f1f1f1;
                        background-color: transparent;
                        border: 1px solid #343434;
                        border-radius: 2px;
                        box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
                        cursor: pointer;

                        -webkit-appearance: none;
                        content: "";

                        /* stylelint-disable-next-line */
                        &:checked+label {
                            position: relative;

                            /* stylelint-disable-next-line */
                            &:after {
                                position: absolute;
                                bottom: 4px;
                                left: -19px;

                                display: block;
                                width: 6px;
                                height: 6px;

                                background: #343434;
                                border-radius: 50%;

                                content: "";
                            }
                        }
                    }
                }
            }

            /* stylelint-disable-next-line */
            label {
                margin-bottom: 10px;

                font-size: $titleFontSize;
            }
        }

        /* stylelint-disable-next-line */
        select {
            width: 100%;
            margin-bottom: 15px;
            padding: 6.5px 15px;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;
        }

        /* stylelint-disable-next-line */
        h3 {
            margin-bottom: 10px;
        }

        /* stylelint-disable-next-line */
        p {
            font-size: 12px;
            line-height: 15px;
        }

        /* stylelint-disable-next-line */
        input {
            &.is-invalid {
                border-color: $errorColor;
            }

            float: none;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;
        }

        .radio-btn {
            float: none;

            margin-right: 50px;

            margin-bottom: 0px !important;

            padding: 0px !important;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;
        }
    }

    /* stylelint-disable-next-line */
    .right_section {
        width: 50%;

        @media (max-width: 480px) {
            width: 100%;
        }

        /* stylelint-disable-next-line */
        label {
            margin-bottom: 5px;

            font-weight: 500;
            font-size: 14px;
            line-height: 33.54px;
        }

        /* stylelint-disable-next-line */
        input {
            margin-right: 15px;
            margin-bottom: 15px;
            padding: 7.5px 15px;

            font-size: 12px;

            border: 1px solid #d7d7d6;
            border-radius: 2px;
        }

        .toggle-btn-tab {
            display: flex;
            grid-gap: 10px;
            margin: 15px 0;

            /* stylelint-disable-next-line */
            p {
                font-size: 12px;
                line-height: 15px;
            }
        }
    }

    /* stylelint-disable-next-line */
    .verticalLine {
        >div[class^="col-"] {
            margin-bottom: 0px !important;
            padding-top: 10px !important;
            padding-bottom: 0px !important;

            border-left: 2px solid #307aff !important;
        }

        div[class^="col-"]:first-child {
            border-left: none !important;
        }
    }
}

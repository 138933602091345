.globalLoader{
    position: absolute;
    top: 86px;
    z-index: 10;

    width: 100%;
    height: 100vh;

    background:#ffffff;

    .appLoader{
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;

        padding: 80px 0;
    }

    &.animationFadeOut{
        animation-delay: .25s;
        .appLoader{
            animation-name: animFadeOut;
            animation-duration: 0.25s;
            animation-fill-mode: forwards;
        }
    }

}

.logout{
    &.globalLoader{
        top:0;
    }
}

.accountPage {
    .accountTab{
        font-size: $textSizeNormal;

        border-bottom: 1px solid $Grey-clr;
    }
    .sectionHeader {
        display: flex;
        align-items: center;
        height:34px;

        color: $whiteColor;

        background-color: $primaryColor;

        h1,
        h2,
        h3,
        h4 {
            color: $whiteColor;

            font-weight: normal;
        }
    }

    .darkgray {
        color: #696969 !important;
    }

    .border-lightgray {
        border: 1px solid #E3E3E3 !important;
    }

    .pad-right-8px {
        padding-right: 8px !important;
    }

    .pad-left-8px {
        padding-left: 8px !important;
    }

    .float-right {
        float: right !important;
    }

    .f-10 {
        font-size: 10px !important;
    }

    .f-12 {
        font-size: 12px !important;
    }

    .f-13 {
        font-size: 13px !important;
    }

    .f-14 {
        font-size: 14px !important;
    }

    .f-15 {
        font-size: 13px !important;
    }

    .w-20 {
        width: 20% !important;
    }

    .lightblue {
        color: #2C80FC !important;
    }

    .F6F6F6 {
        background-color: #F6F6F6 !important;
    }

    .blue-header {
        background-color: #307aff !important;

        label {
            color: white !important;
        }

        a {
            color: white !important;
        }
    }

    .blue-header-td {
        background-color: #307aff !important;

        tr {
            td {
                color: white !important;
            }
        }
    }

    #tblAuditLog {
        tr {
            td {
                padding: 10px !important;

                font-size: 12px !important;
            }
        }
    }

    #subDetailTable {
        tr {
            td {
                padding: 10px !important;

                font-size: 12px !important;
            }
        }
    }

    #tblAuditLog .hidden_row {
        display: none;
    }

    .display-none {
        display: none;
    }

    .D3D3D3 {
        background-color: #D3D3D3 !important;
    }

    .pointer {
        cursor: pointer;
    }

    .modal-header {
        display: flow-root !important;
    }

    .inputblock {
        display: inline-block;
        width: 30px;
        height: 30px;

        text-align: center;

        border: 1px solid lightgray;
    }

    .mr5 {
        margin-right: 5px !important;
    }

    .pr3 {
        padding-right: 1rem !important;
    }

    .pl3 {
        padding-left: 1rem !important;
    }

    .btn-outline-default {
        color: lightgray;

        border: 1px solid lightgray;
    }

    .border-round {
        border: 1px solid lightgrey;
        border-radius: 15px;
    }

    .left-aside {
        position: sticky;
        top: 16px;
    }

    .cursorpointer {
        cursor: pointer !important;
    }

    a.customer-detail.cursorpointer {
        color: $primaryColor;
    }

    .remove-underline {
        color: $White-clr;
        text-decoration: none;
    }

    .remove-underline2 {
        text-decoration: none;
    }

}

.business-overview-section {
    padding: 23px;
}

.scroll-to-top {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.hover-state-leftbar{
    &:hover{
        background: #f4f4f4;
    }
}

.sectionOverview{
    background: $White-clr;
    border: 1px solid $borderColor !important;
}


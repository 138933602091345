.poweredByPayPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    p {
        margin-right: 5px;

        color: $Black-clr;
        font-size: $textSizeNormal;
    }

    &.small{
        picture{
            display:flex;
            img{
                width: 65px;
                height:auto !important;
            }
        }
    }

    &.medium{
        picture{
            display:flex;
            img{
                width: 120px;
                height:auto !important;
            }
        }
    }

    &.large {
        p {
            color: $bdy-clr;
            font-weight:500;
            font-size: $textSizeLarge;
        }

        picture{
            display:flex;
            img{
                width: 150px;
                height:auto !important;
            }
        }
    }
}

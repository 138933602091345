.vat-side-loading div.appLoader {
    margin: 10px 0 !important;
}

.width-85-left{
    float: left !important;
    width: 85% !important;
}

.width-10-right{
    float: right;
    width: 10%;
}

.top-section-btn .next-btn-section[disabled]{
    background: #ccc;
    border: 1px solid #ccc;
}

.customer_registration .right_section .page-1 .main-tab-div-section .right .solo-input-right label{
    display: block;
}

.customer_registration .right_section .page-1 .main-tab-div-section .right .solo-input-right .sole-phone-number .iti{
    width: 100% !important;
    margin-bottom: 15px;
}

.customer_registration .right_section .page-1 .main-tab-div-section .right .solo-input-right .sole-phone-number input{
    border-radius: 0 !important;
}

.big-deatil-tabs .nav-tabs li a .tab-title{
    cursor: pointer;
}

.big-deatil-tabs .nav-tabs li a.active .tab-title{
    cursor: default;
}
.customer-design .nav-item{
    margin-right: .5rem;
}
.customer-design  .nav-tabs .nav-link {
    border: 1px solid #dee2e6;
    border-bottom: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

}
/* stylelint-disable-next-line */
.customer-design .nav-link {
    padding: 0.8rem 5rem;

    color: #307aff;
    font-weight: 500;
    font-size: 16px;
}
.customer-design .nav-tabs .nav-item.show .nav-link,
.customer-design .nav-tabs .nav-link.active {
    color: #307aff;
}

.searchArea{
    display: flex;
    justify-content: flex-start;
    width: 100%;

    column-gap:15px;
}
.searchArea .form-group{
    position: relative;

    margin-bottom: 0;
}

/* .searchArea .form-group::after{
    position: absolute;
    top: 6px;
    right: 5px;

    width: 20px;
    height: 20px;

    background-image: url('../img/search-icon.svg');
    background-size: cover;

    content: '';
} */

/* stylelint-disable-next-line */
.searchArea .form-group input:focus{
    border-color: #307aff;
    outline: 0;
    box-shadow: inherit;
}
.btn-blue{
    margin-left: 20px;
    padding: 2px 30px;

    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20.12px;

    background: #307aff;
    border: 1px solid #307aff;
    border-radius: 5px;
    box-shadow: none;
}
.bs-datepicker .bs-media-container{
    grid-gap: 10px;
}
.theme-green .bs-datepicker-head{
    background-color: #0078C8;
}
.theme-green .bs-datepicker-body table td.week span{
    color: #0078C8;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after{
    background-color: #0078C8;
}
.green-clr{
    text-decoration: none!important;

    background: #007f0c;
    border: 1px solid #007f0c;
}
.green-clr:hover{
    color: #fff;
}
.customer-design .customer-icon{
    color: #666 !important;
    font-size: 20px;
}
.customer-design .transaction-div-table .inner-tabel-tab .table td a.btn-blue{
    display: inline-block;
    margin: 0;
    padding: 4px 12px;

    color: #fff;

    border: 0;
}

.quotesModaltext p{
    font-size: 14px;
    text-align: center;
}
.quotesModal .modal-footer{
    justify-content: center;
    padding: 10px 0 20px 0;

    border-top: 0;

}

.quotesModal .modal-body{
    padding: 30px 0 15px 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container{
    /* border: 1px solid #ccc !important;; */
    box-shadow: none !important;
}

.green-next-btn{
    width: 150px;
    margin-top: 16px;
    padding: 2px 15px;

    color: #fff;
    font-weight: 400;
    font-size: 11px;
    line-height: 20.12px;
    text-transform: initial;
    text-decoration: none !important;

    background: #007f0c;
    border: 1px solid #007f0c;
    border-radius: 5px;
    box-shadow: none;


}
.green-next-btn:hover{
    background: #02910f;
    border: 1px solid #02910f;
}

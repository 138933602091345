.first-drop-down-menu {
    &.notificationContainer {
        max-height: 550px;

        animation-name: animZoomIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        .close-btn{
            color:$grayColor;
        }

        ul {
            li {
                .notify-msg{
                    justify-content: space-between;

                    a {
                        cursor:pointer;

                        svg {
                            fill: $dangerColor;

                            &:hover{
                                fill: $primaryColor;
                            }
                        }
                    }
                }
                span {
                    color: $blackColor !important;
                }
            }

            &.footer{
                display:flex !important;
                justify-content: space-between;

                margin-top:0;

                padding-bottom:0;

                background: #fff;

                li{
                    background: #fff;

                    border:none;
                }
            }
        }
    }
}

.notifications-table-primary{
    background-color: #f6f6f6;
}

// .category-head{
//     td{
//         background-color: #317aff;
//         color: white !important;
//         font-weight: 500 !important;;
//     }
// }


.category-head{
    td{
        color: #000 !important;
        font-weight: 500 !important;

        background-color: #f6f6f6;
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: Roboto, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    background: #f6f6f6;
    font-size: 15px;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.btn-primary{
  font-size: 12px;
}

/* CSS used here will be applied after bootstrap.css */

/* custom checkboxes */

/***** RADIO BUTTON STYLES *****/
.rdio {
    position: relative;
}
.rdio input[type="radio"] {
    opacity: 0;
}
.rdio label {
    padding-left: 10px;
    cursor: pointer;
    margin-bottom: 7px !important;
}
.rdio label:before {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 1px;
    left: 0;
    content: "";
    display: inline-block;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    border: 1px solid #9d9d9c;
    background: #fff;
}
.rdio input[type="radio"] {
    margin: 0px;
}
.rdio input[type="radio"]:disabled + label {
    color: #999;
}
.rdio input[type="radio"]:disabled + label:before {
    background-color: #fbc52d;
}
.rdio input[type="radio"]:checked + label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 4px;
    display: inline-block;
    font-size: 11px;
    width: 10px;
    height: 10px;
    background-color: #fbc52d;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}
.rdio-default input[type="radio"]:checked + label:before {
    border-color: #9d9d9c;
}
.rdio-primary input[type="radio"]:checked + label:before {
    border-color: #9d9d9c;
}
.rdio-primary input[type="radio"]:checked + label::after {
    background-color: #9d9d9c;
}
.custom-checkbox > [type="checkbox"],
.custom-checkbox label {
    /*margin-bottom:0px !important;*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.custom-checkbox > [type="checkbox"]:not(:checked),
.custom-checkbox > [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label,
.custom-checkbox > [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 22px;
    cursor: pointer;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox > [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -9px;
    width: 17px;
    height: 17px;
    border: 1px solid #fbc52d;
    background: #ffffff;
    border-radius: 2px;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox > [type="checkbox"]:checked + label:after {
    font: normal normal normal 12px/1 "Glyphicons Halflings";
    content: "\e013";
    position: absolute;
    top: 50%;
    margin-top: -7px;
    left: 2px;
    color: #fbc52d;
    xtransition: all 0.2s;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
.custom-checkbox > [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
.custom-checkbox > [type="checkbox"][data-indeterminate] + label:after {
    content: "\2212";
    left: 2px;
    opacity: 1;
    transform: scale(1);
}
.custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:before {
    box-shadow: none;
    background-color: #eeeeee;
    border-color: #eeeeee;
    cursor: not-allowed;
    opacity: 1;
    color: #dadada;
}
.custom-checkbox > [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    background-color: #eeeeee;
    border-color: #eeeeee;
    cursor: not-allowed;
    opacity: 1;
    color: #fbc52d;
}
.custom-checkbox > [type="checkbox"]:disabled:checked + label:after {
    color: #fbc52d;
    cursor: not-allowed;
}
.custom-checkbox > [type="checkbox"]:disabled + label {
    color: #aaa;
    cursor: not-allowed;
}
.custom-checkbox > [type="checkbox"]:checked:focus + label:before,
.custom-checkbox > [type="checkbox"]:not(:checked):focus + label:before {
    border: 1px solid #fbc52d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-checkbox label:hover:before {
    border: 1px solid #fbc52d !important;
}
.custom-checkbox [type="checkbox"]:disabled:not(:checked) + label:hover:before,
.custom-checkbox [type="checkbox"]:disabled:checked + label:hover:before {
    border: 1px solid #fbc52d !important;
}

/* toggle button */

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bcbcbc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 4px;
    background-color: #dbdbdb;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    top: 5px;
}

input:checked + .slider {
    background-color: #bcbcbc;
}

input:focus + .slider {
    box-shadow: 0 0 1px #bcbcbc;
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
/* =================== Task 92 ======================= */

.custom-checkbox > [type="checkbox"]:not(:checked), .custom-checkbox > [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label, .custom-checkbox > [type="checkbox"]:checked + label {
    position: relative;

    padding-left: 22px;

    cursor: pointer;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label::before, .custom-checkbox > [type="checkbox"]:checked + label::before {
    position: absolute;
    top: 50%;
    left: 0;

    width: 17px;
    height: 17px;
    margin-top: -9px;

    background: #fff;
    border: 1px solid #fbc52d;
    border-radius: 2px;

    content: '';
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label::after, .custom-checkbox > [type="checkbox"]:checked + label::after {
    position: absolute;
    top: 50%;
    left: 2px;

    margin-top: -7px;

    color: #fbc52d;
    font: normal normal normal 12px/1 'Glyphicons Halflings';

    content: '\e013';
    xtransition: all 0.2s;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label::after {
    transform: scale(0);
    opacity: 0;
}

.custom-checkbox > [type="checkbox"]:checked + label::after {
    transform: scale(1);
    opacity: 1;
}

.custom-checkbox > [type="checkbox"][data-indeterminate] + label::after {
    left: 2px;

    transform: scale(1);
    opacity: 1;

    content: '\2212';
}

.custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label::before {
    color: #dadada;

    background-color: #eee;
    border-color: #eee;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.custom-checkbox > [type="checkbox"]:disabled:checked + label::before {
    color: #fbc52d;

    background-color: #eee;
    border-color: #eee;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.custom-checkbox > [type="checkbox"]:disabled:checked + label::after {
    color: #fbc52d;

    cursor: not-allowed;
}

.custom-checkbox > [type="checkbox"]:disabled + label {
    color: #aaa;

    cursor: not-allowed;
}

.custom-checkbox > [type="checkbox"]:checked:focus + label::before, .custom-checkbox > [type="checkbox"]:not(:checked):focus + label::before {
    border: 1px solid #fbc52d;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%);
}

.custom-checkbox label:hover::before {
    border: 1px solid #fbc52d !important;
}

.custom-checkbox [type="checkbox"]:disabled:not(:checked) + label:hover::before, .custom-checkbox [type="checkbox"]:disabled:checked + label:hover::before {
    border: 1px solid #fbc52d !important;
}

/* =================== Task 92 end ======================= */

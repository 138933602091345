/**
*************************************
For lint issue fix
Remove this comment when you add code.
*************************************
*/
@use "sass:math";
@function pxToRem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}

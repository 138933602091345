.setup-complete {
    .summary-auth-fee-val {
        display: initial;
    }
    /* stylelint-disable-next-line */
    .page-1 {
        display: grid;
        flex-wrap: nowrap;
        grid-gap: 20px;

        @media (max-width: 480px) {
            padding: 0;
        }
        /* stylelint-disable-next-line */
        .row {
            flex-wrap: nowrap !important;
        }
        /* stylelint-disable-next-line */
        .left_section {
            flex-wrap: wrap !important;
            grid-gap: 20px;
            width:100%;
            margin:0;

            .lower-section {
                margin-bottom: 0;
                padding: 0;

                /* stylelint-disable-next-line */
                .nav-tabs.parent-tab {
                    background: transparent;
                }
                @media (max-width: 480px) {
                    width: 100%;
                    padding: 10px;
                    overflow: scroll;
                }
                /* stylelint-disable-next-line */
                h2 {
                    margin-bottom: 20px;
                }
                .table {
                    /* stylelint-disable-next-line */
                    tbody {
                        /* stylelint-disable-next-line */
                        tr {
                            line-height:15px;
                            /* stylelint-disable-next-line */
                            td {
                                font-size: 12px;
                                text-align: left;
                            }
                        }
                    }
                }

                .customer-header {
                    display: flex;
                    justify-content: right;
                    .customer-registration-cms {
                        margin: 10px !important;

                        font-size: 10px !important;
                    }

                    /* stylelint-disable-next-line */
                    p {
                        padding: 0 10px;

                        color: #307aff;
                        font-size: 14px;

                        background-color: #fff;
                    }
                }
            }
            .upper-section {
                display: grid;
                grid-template-columns: 1.8fr 1fr 1fr 1fr;
                padding: 0;

                color: #333;
                line-height: 25px;

                /* stylelint-disable-next-line */
                h2 {
                    margin-bottom: 10px;
                }
                ul {
                    /* stylelint-disable-next-line */
                    li {
                        display: flex;
                    }
                }
            }
        }
        .right_section {
            flex-wrap: nowrap;
            grid-gap: 20px;
            margin-top: 0;

            .upper-section {
                margin-bottom: 16px;
                padding: 0;

                @media (max-width: 480px) {
                    padding: 0;
                }
                .gap {
                    min-height:auto;
                    margin-bottom: 16px;
                }
                .card-body {
                    .right-content {
                        padding-left: 7px !important;
                    }
                }
                /* stylelint-disable-next-line */
                h2 {
                    margin-bottom: 20px;
                }
                .tab-content.border-tab-section {
                    padding: 20px;
                    .table {
                        /* stylelint-disable-next-line */
                        .tableBorder{
                            background-color: $White-clr;
                            border-top: 2px solid $blue-clr;
                            border-right: none;
                            border-bottom: 2px solid $blue-clr;
                            border-left: none;
                            th{
                                color: $Black-clr;
                            }
                        }


                        thead {
                            color: #0491df;
                            font-weight: 400;
                            font-size: $tableTitleFontSize;
                            line-height: 19.2px;

                            background: #f6f6f6;
                            /* stylelint-disable-next-line */
                            th {
                                height: 34px;
                                padding: 0 10px;

                                font-size: $tableTitleFontSize;
                                line-height: 34px;
                            }
                            /* stylelint-disable-next-line */
                            th {
                                /* stylelint-disable-next-line */
                                &:nth-child(1) {
                                    // width: 25%;
                                }
                                /* stylelint-disable-next-line */
                                &:nth-child(2) {
                                    // width: 25%;
                                }
                                /* stylelint-disable-next-line */
                                &:nth-child(3) {
                                    // width: 25%;

                                    // text-align: center;
                                }
                                /* stylelint-disable-next-line */
                                &:nth-child(4) {
                                    // width: 25%;
                                }
                                /* stylelint-disable-next-line */
                                &:nth-child(5) {
                                    // width: 25%;

                                    // text-align: center;
                                }
                                /* stylelint-disable-next-line */
                                &:nth-child(6) {
                                    // width: 25%;
                                }
                            }
                        }
                        /* stylelint-disable-next-line */
                        tbody {
                            /* stylelint-disable-next-line */
                            tr {
                                /* stylelint-disable-next-line */
                                td {
                                    font-size: 12px;
                                    // text-align: center;
                                }
                                .leftalign {
                                    text-align: left !important;
                                }
                            }
                            .show {
                                background-color: #f6f6f6;
                            }
                        }
                    }

                    .tab-pane {
                        padding: 10px;
                        @media (max-width: 480px) {
                            padding: 20px;
                        }
                        @media (max-width: 1500px) {
                            padding: 40px;
                        }
                        /* stylelint-disable-next-line */
                        .left_section {
                            flex: 0 0 100%;
                            max-width: 100%;
                            .top-header-section {
                                height: 60px;
                                padding: 0;

                                line-height: 19px;
                                .title-one {
                                    padding: 5px;
                                }
                                .title-two {
                                    width: 100px;
                                    padding: 5px;

                                    font-size: 12px;
                                }
                            }
                            .card-header {
                                display: flex;
                                padding: 8px;
                                .right-content {
                                    grid-gap: 30px;
                                    padding-left: 35px;
                                    .div {
                                        margin: 0 58px;
                                    }
                                }
                            }
                            /* stylelint-disable-next-line */
                            h2 {
                                margin: 0;
                            }
                        }
                    }
                }
                /* stylelint-disable-next-line */
                .nav-tabs.parent-tab {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 7px;
                    padding: 0;

                    background: transparent;
                    border-width: 0;
                    /* stylelint-disable-next-line */
                    li {
                        /* stylelint-disable-next-line */
                        &:first-child {
                            margin-left: 12px;
                        }
                    }
                    .nav-link {
                        justify-content: center;
                        height: 100%;
                        margin-bottom: -1px;
                        padding: 7px 10px;

                        color: #7c7c7c;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 26.4px;
                        text-align: center;

                        background: #f6f6f6;
                        border: 2px solid #e2e2e2;
                        border-bottom: 0;
                        box-shadow: none;
                    }
                    .nav-link.active {
                        position: relative;

                        color: #307aff;

                        background: #fff;
                        border: 2px solid #e2e2e2;
                        box-shadow: none;
                        &:before {
                            position: absolute;
                            right: 0;
                            bottom: -5px;
                            left: 0;

                            display: block;
                            width: 100%;
                            height: 5px;

                            background: #fff;

                            content: "";
                        }
                    }
                }
                .tab-content {
                    @media (max-width: 480px) {
                        width: 100%;
                        overflow: scroll;
                    }
                    .card_rate {
                        /* stylelint-disable-next-line */
                        @media (max-width: 991px) {
                            width: 67%;
                        }
                        @media (max-width: 480px) {
                            width: 100%;
                        }
                        /* stylelint-disable-next-line */
                        .left_section {
                            /* stylelint-disable-next-line */
                            .top-header-section {
                                padding: 3px 20px;

                                line-height: 1;
                                @media (max-width: 480px) {
                                    width: 472px;
                                }
                            }
                        }
                        /* stylelint-disable-next-line */
                        .card-header {
                            .left-content {
                                width: 56px;
                            }
                            /* stylelint-disable-next-line */
                            .right-content {
                                @media (max-width: 991px) {
                                    padding: 0;
                                }
                                @media (min-width: 992px) and (max-width: 1600px) {
                                    padding-left: 0;
                                }
                                div {
                                    margin: 0 58px;
                                    @media (max-width: 1500px) {
                                        margin: 0 52px;
                                    }
                                    @media (max-width: 991px) {
                                        margin: 0 38px;
                                    }
                                    @media (max-width: 480px) {
                                        margin: 0 35px;
                                    }
                                }
                                button {
                                    color: #307aff;

                                    background: transparent;
                                    border: transparent;
                                }
                            }
                            /* stylelint-disable-next-line */
                            input {
                                @media (max-width: 1600px) {
                                    margin: 0 25px;
                                }
                                @media (max-width: 991px) {
                                    margin: 0 36px;
                                }
                                @media (max-width: 480px) {
                                    margin: 0 3px;
                                }
                                width: 50px;
                                margin: 0 59px;

                                padding: 4px 5px;

                                text-align: center;

                                border: 1px solid #dfdfdf;
                            }
                        }
                    }
                }
                /* stylelint-disable-next-line */
                .card-body {
                    padding: 8px 10px;
                    @media (max-width: 480px) {
                        width: 472px;
                    }
                    /* stylelint-disable-next-line */
                    .left-content {
                        width: 56px;
                    }
                    /* stylelint-disable-next-line */
                    .right-content {
                        grid-gap: 38px;
                        padding-left: 32px !important;
                        /* stylelint-disable-next-line */
                        div {
                            margin: 0 48px;
                            @media (max-width: 991px) {
                                margin: 0 25px;
                            }
                            @media (max-width: 480px) {
                                margin: 0 35px;
                            }
                        }
                    }
                }
            }
        }
        /* stylelint-disable-next-line */
        .lower-section {
            grid-gap: 20px;

            color: #333;
            /* stylelint-disable-next-line */
            h2 {
                margin-bottom: 15px;
            }
            .top_box_main {
                line-height: 25px;
                .add-space {
                    margin-top: 34px;
                }
            }
            .director_top_box_main {
                line-height: 50px;

                .add-space {
                    margin-top: 34px;
                }
            }
            .directors-list {
                width: 50%;
                padding: 73px;

                border: 1px solid #dfdfdf;
                @media (max-width: 480px) {
                    padding: 30px;
                }
            }
            .required-list {
                width: 100%;
                padding: 20px 30px;

                border: 1px solid #dfdfdf;
                @media (max-width: 480px) {
                    padding: 30px;
                }
            }
        }
    }
    /* stylelint-disable-next-line */
    .page-2 {
        min-height: 500px;
        margin-top: 10vw;
        /* stylelint-disable-next-line */
        img {
            display: block;
            margin: 0 auto;
        }
        /* stylelint-disable-next-line */
        h2 {
            margin-top: 20px;

            text-align: center;
        }
    }
    /* stylelint-disable-next-line */
    .page-3 {
        min-height: 500px;
        margin-top: 10vw;

        /* stylelint-disable-next-line */
        img {
            display: block;
            margin: 0 auto;
        }
        /* stylelint-disable-next-line */
        h2 {
            margin-top: 20px;

            text-align: center;
        }
    }
    .page-4 {
        min-height: 500px;
        margin-top: 10vw;

        /* stylelint-disable-next-line */
        img {
            display: block;
            margin: 0 auto;
        }
        /* stylelint-disable-next-line */
        h2 {
            margin-top: 20px;

            text-align: center;
        }
        /* stylelint-disable-next-line */
        button {
            display: block;
            margin: 20px auto;

            padding: 0 30px;
        }
    }
    .btn{
        &.btnPlusIcon {
            padding : 0 5px 1px 0;

            color: $primaryColor;
        }
    }
}

.table-first-column-sizing{
    width: 25%;
    min-width: 154px;
    max-width: 200px;
}
.productDetails{
    width: 20%;
    min-width: 154px;
    max-width: 200px;
}
.table-second-column-sizing{
    width: 15%;
    min-width: 154px;
    max-width: 200px;
}
.table-third-column-sizing{
    width: 22%;
    min-width: 154px;
    max-width: 200px;
}
.table-fourth-column-sizing{
    width: 10%;
    min-width: 154px;
    max-width: 200px;
}
.table-fifth-column-sizing{
    width: 10%;
    min-width: 154px;
    max-width: 200px;
}

.setup-complete-btn {
    margin-left: 20px;
    padding: 6px 30px;

    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20.12px;
    text-decoration: none;

    background: #307aff;
    border: 1px solid #307aff;
    border-radius: 5px;
    box-shadow: none;

    &:hover{
        color: white !important;

        background: #307aff;
    }


}

.CSsTableWidth{
    width:550px
}

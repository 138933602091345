.home-div-tabel {
    padding: 15px 20px;

    background: #fff;
    border-radius: 15px;
    .header-table-section {
        ul {
            grid-template-columns: 0.5fr 1.7fr 1fr 1fr 1fr 1fr;
            align-items: center;

            background: #a0a0a0;
            border-radius: 10px 10px 0 0;
            li.last-header-section {
                .button-group-header {
                    padding: 0;
                }
            }
        }
    }
    h2 {
        padding-bottom: 15px;

        font-size: 20px;
        text-align: center;
    }
    .bottom-pagination {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        align-items: center;
        justify-content: center;

        padding: 20px 0 10px 0;

        text-align: center;
        button {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;
            padding: 0;

            background: transparent;
            border: 1px solid #a1a1a1;
            &:hover {
                background: #e3e3e3;
            }
        }
    }
}

.check-box-design {
    .form-group {
        display: block;
        margin-top: 60px;
        margin-bottom: 45px;
        padding-left: 40px;
        input {
            display: none;
            width: initial;
            height: initial;
            margin-bottom: 0;
            padding: 0;

            cursor: pointer;
            &:checked + label {
                &:after {
                    position: absolute;
                    top: 2px;
                    left: 7px;

                    display: block;
                    width: 5px;
                    height: 10px;

                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);

                    content: "";
                }
                &:before {
                    background: #000;
                }
            }
        }
        /* stylelint-disable-next-line */
        label {
            position: relative;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0 !important;

            cursor: pointer;
            /* stylelint-disable-next-line */
            &:before {
                position: relative;

                display: inline-block;
                margin-right: 5px;
                padding: 7px;

                vertical-align: middle;

                background-color: transparent;
                border: 2px solid #000;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                cursor: pointer;

                -webkit-appearance: none;
                content: "";
            }
        }
    }
}

label.remove-pad {
    padding-left: 0 !important;
}

.update-btn-sec {
    margin-top: 30px;

    text-align: right;
    /* stylelint-disable-next-line */
    button {
        padding: 14px 60px;

        color: #fff;
        font-size: 14px;

        background: #3aaa35;
        border: 0;
        border-radius: 30px;
        /* stylelint-disable-next-line */
        &:hover {
            opacity: 0.8;
        }
    }
}
.first-tab-section {
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 95%;
        margin-top: 50px;
    }
    .form-group {
        width: 48.5%;
        margin-bottom: 25px;
        input {
            width: 100%;
            height: 50px;
            padding: 0 20px;

            color: #a2a7a5;

            border: 1px solid #ebebe9;
            border-radius: 0;
        }
        /* stylelint-disable-next-line */
        label {
            display: block;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 10px;

            font-weight: 500;
            font-size: 14px;
        }
    }
    .form-group.group-in {
        width: 100%;
        span {
            display: inline-block;
            margin-top: 10px;
            padding-left: 15px;

            color: #a2a7a5;
            font-size: 14px;
            font-style: italic;
        }
        input {
            background: #f6f6f6;
        }
    }
}
.top-bottom-section {
    margin-top: 20px;

    color: #d5d5d5;
    font-size: 14px;
    text-align: right;
    svg {
        path {
            fill: #1a222e;
        }
    }
    .version-dash {
        display: flex;
        grid-gap: 20px;
        align-items: center;
        justify-content: flex-end;

        .logout-sec {
            a {
                color: #1a222e;
                font-size: 12px;
                text-transform: uppercase;
                text-decoration: none;
                &:hover {
                    color: #dc1445;
                    svg {
                        path {
                            fill: #dc1445;
                        }
                    }
                }
            }
        }
    }
}

.error-msg {
    margin-bottom: 20px;
    p {
        display: flex;
        padding: 10px 20px;

        color: #fff;
        font-size: 12px;
        line-height: 16px;

        background: #9d9d9c;
        border-radius: 5px;
        /* stylelint-disable-next-line */
        span {
            display: flex;
            align-items: center;
            padding-right: 10px;
            img {
                width: 20px;
            }
        }
    }
}
.card-section {
    .row {
        flex-direction: row;
        .col-lg-2 {
            display: flex;
            .card {
                width: 100%;

                border: 0;
                border-radius: 10px;
                box-shadow: 0 5px 17px 2px #c1c1c2;
            }
            .card-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                padding-top: 40px;
                padding-bottom: 60px;

                text-align: center;
                .card-img {
                    margin-bottom: 20px;
                }
                .card-img.admin-icon {
                    img {
                        width: 50%;
                    }
                }
                p {
                    margin-top: 10px;

                    color: #000;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .card-title {
            position: relative;

            margin-bottom: 0;

            font-size: 18px;
            line-height: 23px;
            /* stylelint-disable-next-line */
            a {
                color: #000;
                text-decoration: none;
            }
        }
        .bottom-icon {
            position: absolute;
            right: 0;
            bottom: 15px;
            left: 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            width: 100%;
            padding: 0 20px;
            /* stylelint-disable-next-line */
            img {
                width: 18px;
                height: 100%;
                object-fit: contain;
            }
            .edit {
                margin-right: 10px;
            }
        }
    }
}
.dash-manage {
    margin-bottom: 20px;
    /* stylelint-disable-next-line */
    a {
        position: relative;

        display: inline-block;
        padding: 15px 25px 15px 50px;

        color: #fff;
        font-size: 14px;

        background: #3aaa35;
        border-radius: 50px;
        &:before {
            position: absolute;
            top: 12px;
            left: 22px;

            display: inline-block;
            width: 20px;
            height: 20px;

            background-repeat: no-repeat;
            background-size: contain;

            content: "";
        }
    }
}
.product-main-div {
    margin-top: 50px;
    margin-bottom: 50px;
    /* stylelint-disable-next-line */
    ul {
        display: flex;
        grid-gap: 30px;
        width: 46%;
        margin: 0 auto;
        li {
            border-radius: 0 0 10px 10px;
            box-shadow: 3px 2px 11px -4px #888888;
            /* stylelint-disable-next-line */
            img {
                width: 100%;
                height: 170px;
                object-fit: contain;
            }
            .product-title {
                margin-top: 15px;
                margin-bottom: 10px;
                padding: 0 10px;
                /* stylelint-disable-next-line */
                a {
                    display: inline-block;

                    color: #000;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
            .product-details {
                padding: 0 10px 20px 10px;
                .month-input {
                    display: flex;
                    grid-gap: 10px;
                    align-items: center;
                    margin-bottom: 10px;

                    font-weight: 500;
                    font-size: 12px;
                }
                /* stylelint-disable-next-line */
                input {
                    width: 85%;
                    height: 35px;

                    border: 1px solid #dbdbdb;
                }
                .quantity-input {
                    display: flex;
                    grid-gap: 10px;
                    align-items: center;
                    margin-bottom: 10px;

                    font-weight: 500;
                    font-size: 12px;
                }
                .price-div {
                    span {
                        display: inline-block;

                        font-weight: 500;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.range-value {
    position: absolute;
    top: -20px;
    right: 0;
    left: 0;

    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    /* stylelint-disable-next-line */
    input {
        margin: 0;
    }
}
.header-table-section.setelment-header-table-section {
    ul {
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1.7fr;
    }
}

.users-div-tabel {
    .grid-table {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ratio-btn.green-btn {
            display: block;
            width: 12px;
            height: 12px;

            background: #3aaa35;
            border: 0;
            border-radius: 50%;
        }
    }
}

.error-tag {
    position: relative;

    &:after {
        position: absolute;
        top: 15px;

        right: -6px;

        display: flex;

        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        width: 15px;

        height: 15px;

        color: #fff;

        font-size: 9px;

        background: #e31916;

        border-radius: 50%;

        content: "!";
    }
}
.messages-tabel-section {
    .header-table-section {
        ul {
            align-items: center;
            margin-bottom: 0;
            padding: 10px 20px;
        }
    }
}
.top-down-icon {
    position: relative;
    &:before {
        position: absolute;
        top: 10px;
        right: 10px;

        display: inline-block;
        width: 10px;
        height: 18px;

        background-repeat: no-repeat;
        background-size: contain;

        content: "";
    }
}
.right-line {
    position: relative;
    &:after {
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;

        display: flex !important;
        align-items: center;
        justify-content: center;

        width: 1px;
        height: 65%;

        background: #fff;

        content: "";
    }
}
.text-center {
    text-align: center;
}
.verticle-aign-div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    align-items: center;
}
.users-div-tabel.tabel-section {
    .inner-tabel-tab {
        .table-striped {
            tbody {
                tr {
                    td {
                        button {
                            width: initial;
                            padding: 0;

                            background: transparent;
                        }
                        .grid-table-div {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}
.extra-space {
    width: 8%;

    text-align: center;
}
.content-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tabel-section.users-div-tabel {
    /* stylelint-disable-next-line */
    td {
        /* stylelint-disable-next-line */
        a {
            color: #212529;
            text-decoration: none;
        }
    }
}
.full-width {
    grid-row: 1/3;
    grid-column: 1/3;

    width: 100%;
    textarea {
        width: 100%;

        border: 2px solid #d6d6d5;
        border-radius: 10px;
    }
}
.business-info-section {
    /* stylelint-disable-next-line */
    input {
        border: 2px solid #d6d6d5;
    }
}

.detail-bank-section.business-info-section {
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 80px;
    form {
        .form-group {
            margin-bottom: 5px;
            label {
                margin-bottom: 8px;

                font-weight: bold;
            }
        }
    }
}

.fifth-tab-sec {
    padding: 50px 150px 180px 150px;
    h2 {
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 18px;
    }
    /* stylelint-disable-next-line */
    p {
        margin-bottom: 15px;

        font-weight: 500;
        font-size: 14px;
    }
    /* stylelint-disable-next-line */
    input {
        width: 100%;
        height: 45px;
        padding: 0 20px;

        border: 1px solid #ebebe9;
    }
}
.sixth-tab-section {
    margin-top: 50px;
    padding: 0 150px;
    .select-section {
        width: 35%;
        select {
            width: 100%;
            height: 45px;
            margin-bottom: 30px;
            padding: 0 20px;

            border: 1px solid #ebebe9;
        }
        /* stylelint-disable-next-line */
        input {
            width: 100%;
            height: 45px;
            padding: 0 20px;

            border: 1px solid #ebebe9;
        }
        h2 {
            margin-bottom: 20px;

            font-weight: 500;
            font-size: 18px;
        }
        /* stylelint-disable-next-line */
        p {
            margin-bottom: 20px;

            font-weight: 500;
            font-size: 14px;
        }
    }
    .manually-form-section {
        /* stylelint-disable-next-line */
        p {
            margin-top: 40px;

            font-weight: 500;
            font-size: 16px;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        /* stylelint-disable-next-line */
        .form-group {
            width: 48.5%;
            margin-bottom: 30px;
            input {
                width: 100%;
                height: 50px;
                padding: 0 20px;

                color: #a2a7a5;

                border: 1px solid #ebebe9;
                border-radius: 0;
            }
            /* stylelint-disable-next-line */
            label {
                display: block;
                box-sizing: border-box;
                width: 100%;
                margin-bottom: 10px;

                font-weight: 500;
                font-size: 14px;
            }
        }
    }
}
.personal-details-section {
    margin-top: 50px;
    padding: 0 150px;
    /* stylelint-disable-next-line */
    p {
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 18px;
    }
    /* stylelint-disable-next-line */
    .form-group {
        /* stylelint-disable-next-line */
        label {
            display: block;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 10px;

            font-weight: 500;
            font-size: 14px;
        }
        /* stylelint-disable-next-line */
        input {
            width: 100%;
            height: 50px;
            padding: 0 20px;

            color: #a2a7a5;

            border: 1px solid #ebebe9;
            border-radius: 0;
        }
    }
}
#business-overview {
    .select-section {
        margin-top: 50px;
        margin-bottom: 30px;
        padding: 0 150px;
        .business-inner-div {
            width: 65%;
        }
        h2 {
            margin-bottom: 30px;

            font-weight: 500;
            font-size: 18px;
        }
        p {
            margin-bottom: 20px;

            font-weight: 500;
            font-size: 16px;
        }
        select {
            width: 100%;
            height: 50px;
            padding: 0 20px;

            color: #a2a7a5;

            border: 1px solid #ebebe9;
            border-radius: 0;
        }
    }
}
.main-selecting-option-div {
    .first-tab-form {
        padding: 0 150px 10px 150px;
        /* stylelint-disable-next-line */
        h2 {
            font-weight: 500;
        }
        /* stylelint-disable-next-line */
        .form-group {
            input {
                &:checked + label {
                    &:after {
                        position: absolute;
                        top: 4px;
                        left: 7px;

                        display: block;
                        width: 5px;
                        height: 9px;

                        background: transparent;
                        border: solid #343434;
                        border-width: 0 2px 2px 0;
                        border-radius: 0;
                        transform: rotate(45deg);

                        content: "";
                    }
                }
            }
        }
    }
}
.input-group.mb-3.group-end {
    display: block;
    padding: 20px 80px;

    text-align: right;
    /* stylelint-disable-next-line */
    a {
        padding: 8px 50px;

        border: 0;
        border-radius: 20px;
        &:hover {
            border: 0;
            opacity: 0.8;
        }
    }
    a.btnNext {
        background: #3aaa35;
    }
    a.btnPrevious {
        background: #191919;
    }
}
.input-group.mb-3.group-end.pagination-pre-nxt {
    display: flex;
    justify-content: space-between;
}
.turnover-form {
    margin-top: 50px;
    padding: 0 80px;
    /* stylelint-disable-next-line */
    > h2 {
        margin-bottom: 20px;

        font-size: 18px;
    }
    .turnover-inner-section {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 30px;
        .common-div-sec {
            width: 48%;
            /* stylelint-disable-next-line */
            h2 {
                margin-bottom: 15px;

                font-size: 15px;
            }
            input {
                width: 100%;
                height: 50px;
                padding: 0 20px;

                border: 1px solid #ebebe9;
            }
        }
    }
}
.get-rates-btn {
    margin-top: 30px;

    text-align: center;
    /* stylelint-disable-next-line */
    button {
        padding: 12px 60px;

        color: #fff;

        background: #3aaa35;
        border: 0;
        border-radius: 20px;
    }
}
.main-third-section {
    .transaction-div-tabel {
        table.table.table-striped {
            margin-top: 30px;
            margin-bottom: 20px;

            border: 1px solid #e3e3e3;
            thead {
                color: #000;

                background: transparent;
                tr {
                    text-align: center;
                }
            }
            tbody {
                td {
                    text-align: center;
                }
                tr {
                    &:nth-of-type(odd) {
                        background: #dadada;
                    }
                    &:nth-of-type(even) {
                        background: #fff;
                    }
                }
            }
        }
    }

    .transaction-div-tabel.active {
        .inner-tabel-tab {
            display: block;
        }
    }
}
.bottom-card-section {
    /* stylelint-disable-next-line */
    ul {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        .tabel-section {
            /* stylelint-disable-next-line */
            .inner-tabel-tab {
                table.table.table-striped {
                    thead {
                        color: #000;

                        background: transparent;
                    }
                    border: 1px solid #e3e3e3;
                    .bold {
                        font-weight: 500;
                    }
                }
                /* stylelint-disable-next-line */
                h2 {
                    margin-bottom: 10px;

                    font-size: 16px;
                }
                .table-striped {
                    tbody {
                        /* stylelint-disable-next-line */
                        tr {
                            &:nth-of-type(odd) {
                                background: #f6f6f6;
                            }
                            &:nth-of-type(even) {
                                background: #fff;
                            }
                        }
                    }
                }
            }
        }
        .tabel-section.transaction-div-tabel.home-cardSection {
            .bottom-pay-btn {
                margin-top: 20px;

                text-align: center;
                a {
                    display: inline-block;
                    padding: 12px 80px;

                    color: #fff;

                    background: #3aaa35;
                    border: 0;
                    border-radius: 20px;
                }
            }
        }
    }
    ul.dashboard-comissions {
        grid-template-columns: repeat(1, 1fr);
    }
}
.widget-div-section {
    margin-bottom: 50px;
    .widget-card-section {
        ul {
            display: grid;
            grid-gap: 15px;
            grid-template-columns: 0.5fr 1fr;
            li {
                position: relative;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 110px 0;

                text-align: center;

                background: #fff;
                border-radius: 20px;
                box-shadow: 3px 2px 11px -4px #888888;
                &:nth-child(3n + 0) {
                    grid-column: 1/3;
                }
                .top-right-icon {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                .left-line {
                    position: absolute;
                    top: 40%;
                    left: 15px;

                    width: 2px;
                    height: 60px;

                    background: #d6d6d6;
                    border-radius: 10px;
                }
                .right-line {
                    position: absolute;
                    top: 40%;
                    right: 15px;

                    width: 2px;
                    height: 60px;

                    background: #d6d6d6;
                    border-radius: 10px;
                    &:after {
                        display: none !important;
                    }
                }
                .bottom-line {
                    position: absolute;
                    right: 0;
                    bottom: 15px;
                    left: 0;

                    width: 60px;
                    height: 2px;
                    margin: 0 auto;

                    background: #d6d6d6;
                    border-radius: 10px;
                }
                .edit-icon-sec {
                    position: absolute;
                    right: 55px;
                    bottom: 15px;
                }
                .delete-icon-sec {
                    position: absolute;
                    right: 20px;
                    bottom: 15px;
                }
                /* stylelint-disable-next-line */
                svg {
                    /* stylelint-disable-next-line */
                    &:hover {
                        /* stylelint-disable-next-line */
                        path {
                            fill: #dc1445;
                        }
                    }
                }
                .title-card {
                    display: inline-block;
                    margin-top: 15px;

                    color: #000;
                    font-weight: bold;
                    font-size: 16px;
                    text-decoration: none;
                    &:hover {
                        color: #dc1445;
                    }
                }
            }
        }
    }
}
.widget-sel-section {
    .first-tab-form {
        padding: 0;
        /* stylelint-disable-next-line */
        .form-group {
            display: inline-block;
            margin-right: 85px;

            font-weight: 500;
            /* stylelint-disable-next-line */
            label {
                /* stylelint-disable-next-line */
                &:before {
                    background: #fff;
                    border: 2px solid #bcbcbb;
                    border-radius: 50%;
                }
            }
            input {
                &:checked + label {
                    &:after {
                        position: absolute;
                        top: 5px;
                        left: 5px;

                        display: block;
                        width: 8px;
                        height: 8px;

                        background: #9d9d9c;
                        border-radius: 50%;

                        content: "";
                    }
                }
            }
        }
    }
    margin-bottom: 10px;
}
.last-tab-section {
    margin-top: 30px;
    margin-bottom: 200px;
    padding: 0 80px;
    /* stylelint-disable-next-line */
    p {
        margin-bottom: 30px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    /* stylelint-disable-next-line */
    li {
        position: relative;

        margin-bottom: 35px;
        padding-left: 30px;

        font-weight: 500;
        &:before {
            position: absolute;
            top: 0;
            left: 0;

            display: inline-block;
            width: 20px;
            height: 20px;

            background-repeat: no-repeat;
            background-size: contain;

            content: "";
        }
    }
}
.header-profiletable-section.header-profiletable-section {
    ul {
        grid-template-columns: 0.5fr 1fr 3fr 1.7fr;
    }
}
.header-profiletable-section {
    /* stylelint-disable-next-line */
    ul {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1.7fr;
        align-items: flex-end;
        padding: 10px 20px;
        .filter-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            /* stylelint-disable-next-line */
            span {
                display: inline-block;
                padding-left: 15px;

                font-weight: bold;
            }
            /* stylelint-disable-next-line */
            img {
                width: 25px;
            }
        }
        .select-one {
            text-align: center;
            /* stylelint-disable-next-line */
            select {
                width: 95%;
                margin: 0 auto;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
        }
        .select-two {
            text-align: center;
            /* stylelint-disable-next-line */
            select {
                width: 105%;
                margin: 0 20px;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
            /* stylelint-disable-next-line */
            input {
                width: 105%;
                margin: 0 20px;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
        }
        /* stylelint-disable-next-line */
        li.last-header-section {
            /* stylelint-disable-next-line */
            .button-group-header {
                display: flex;
                grid-gap: 20px;
                justify-content: flex-end;
                padding-right: 15px;
                /* stylelint-disable-next-line */
                button {
                    width: 140px;
                    padding: 8px 25px;

                    color: #fff;
                    font-size: 10px;

                    border: 0;
                    border-radius: 30px;
                }
                button.filter-sec {
                    width: initial;
                    padding: 8px 45px;

                    background: #191919;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                button.reset-sec {
                    &:hover {
                        opacity: 0.8;
                    }
                    width: initial;
                    padding: 8px 45px;

                    background: #706f6f;
                }
            }
        }
    }
}
input[type="password"] {
    width: 45px;
    height: 45px;
    margin: 0 20px;

    font-size: 25px;
    text-align: center;

    border: 1px solid #d7d7d6;
    &::-webkit-inner-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }
    &::-webkit-outer-spin-button {
        margin: 0;

        -webkit-appearance: none;
    }
}
.square-radio {
    position: relative;

    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 2px;

    background-color: #e9e8e6;
    border: 1px solid black;
    /* stylelint-disable-next-line */
    span {
        display: inline-block;
        padding: 0 20px;
    }
}
.square-radio--clicked {
    .square-radio--content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 8px;
        height: 8px;
        margin: auto;

        background-color: black;
        border-radius: 20px;
    }
}
.all-check-box {
    display: flex;
    grid-gap: 5px;
    margin: 10px 0 20px 0;
    /* stylelint-disable-next-line */
    input {
        position: static;

        margin: 0;
        padding: 0;
    }
    /* stylelint-disable-next-line */
    label {
        margin-bottom: 0;
    }
}
.common-select {
    .select2-container--default {
        .select2-selection--single {
            height: calc(1.5em + 0.75rem + 2px) !important;

            border: 1px solid #ced4da;
            .select2-selection__rendered {
                height: calc(1.5em + 0.75rem + 2px) !important;
            }
        }
        .select2-selection--multiple {
            border: 1px solid #ced4da;
        }
    }
}
.button-sections {
    margin-top: 10px;
    /* stylelint-disable-next-line */
    button {
        margin-right: 10px;
    }
}
.alert-dismissible {
    .close {
        padding: 0.25rem 1.25rem;
    }
}
.mat-checkbox-layout {
    .mat-checkbox-label {
        font-size: 12px;
    }
}
.mat-select-value {
    font-size: 12px;
}
.fade {
    &:not(.show) {
        display: none;
    }
}
.footer {
    width: 100%;
    margin-top: 20px;
    padding: 15px 0;

    background-color: #f5f5f5;
}
.footer-text {
    font-size: 13px;
}
.dropdown-divider.hidden {
    display: none;
}
.dropdown-item.divide {
    &:not(:last-of-type) {
        + {
            .dropdown-divider.hidden {
                display: block;
            }
        }
    }
}
p-calendar.is-invalid {
    .ui-calendar {
        /* stylelint-disable-next-line */
        input {
            border-color: #dc3545;
        }
    }
    ~ {
        .invalid-feedback {
            display: block;
        }
    }
}
.form-inline.is-invalid {
    ~ {
        .invalid-feedback {
            display: block;
        }
    }
}

.country-select {
    .flag-box {
        display: inline-block;
        width: 20px;
        margin-right: 6px;

        vertical-align: middle;
        .iti-flag {
            width: 20px;
        }
    }
    .country-name {
        vertical-align: middle;
    }
}
.th-filter {
    ng-select {
        font-weight: normal !important;
    }
}
.link {
    cursor: pointer;
}
.hidden-ks {
    visibility: hidden;
}
.cdk-overlay-container {
    z-index: 1060;
}
.ng-dropdown-panel {
    .ng-dropdown-panel-items {
        .ng-optgroup {
            white-space: normal !important;
        }
        .ng-option {
            font-size: 11px !important;
            white-space: normal !important;
        }
    }
}
.customer-detail-nav-design {
    margin-bottom: 39px;

    border: 1px solid #007bff;
}
.nav-pills {
    .nav-link {
        border-radius: 0;
    }
}
.btn-outline-primary-no-bg {
    &:hover {
        background: none !important;
    }
}
.rates-table {
    /* stylelint-disable-next-line */
    tr {
        cursor: pointer;
    }
}
.rates-table-values {
    /* stylelint-disable-next-line */
    tr {
        cursor: auto;
    }
}
.edited-rate {
    color: darkgoldenrod;
    font-weight: bold;
}
.inner-detail-tab {
    ul.tabs {
        margin: 0;
        padding: 0;

        list-style: none;
        /* stylelint-disable-next-line */
        li {
            display: inline-block;
            padding: 10px 15px;

            color: #222;

            background: none;
            cursor: pointer;
        }
        li.current {
            color: #222;

            background: #ededed;
        }
    }
    .tab-content {
        display: none;
        padding: 15px;

        background: #ededed;
    }
    .tab-content.current {
        display: inherit;
    }
}
.widget-m {
    margin: 10px;
}
.indicator {
    float: left;
    width: 20px;
    height: 20px;
    margin: 5px;

    border: 1px solid rgba(0, 0, 0, 0.2);
}
.mat-nav-list {
    width: 20%;
    min-width: 200px;
    overflow: auto;

    border-right: 1px solid grey;
    .active {
        background: #afddff;
    }
    .mat-list-item-focus.active {
        &:focus {
            background: #afddff;
        }
    }
}
.gridster-item-custom {
    padding-top: 13px;
    padding-bottom: 13px;

    border-radius: 20px;
    box-shadow: 3px 2px 7px -4px #888888;
}
.gridster-row {
    background-color: #e9ecef;
}
.gridster.display-grid {
    .gridster-column {
        background: #f6f6f6;
    }
    .gridster-row {
        background: #f6f6f6;
    }
}
.options {
    display: grid;
    padding: 10px;
}
.source-code-button {
    justify-self: end;
}
[draggable] {
    cursor: pointer;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.app-parent-dynamic {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    color: #fff;
    font-weight: 700;
    font-size: 24px;
}
.app-widget-a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    color: #fff;
    font-weight: 700;
    font-size: 24px;

    background: red;
}
.app-widget-b {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    color: #fff;
    font-weight: 700;
    font-size: 24px;

    background: #00f;
}
.app-widget-c {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    color: #fff;
    font-weight: 700;
    font-size: 24px;

    background: #7cfc00;
}
code {
    background: #d3d3d3;
}
.padding-sides-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
}
.common-btn-section {
    padding: 2px 15px;

    color: #fff;
    font-weight: 400;
    font-size: 11px;
    line-height: 20.12px;
    text-transform: capitalize;

    background: #307aff;
    border: 1px solid #307aff;
    border-radius: 5px;
    box-shadow: none;
    &:hover {
        background: #2a6cdd;
    }
}
.turnover-main-container {
    width: 95%;
    margin: 0 auto;
    .turnover-div-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;
        .left_section {
            width: 48%;
            /* stylelint-disable-next-line */
            label {
                display: block;
                width: 100%;
                margin-bottom: 12px;

                font-size: 12px;
            }
            input {
                display: block;
                width: 100%;
                padding: 8px 15px;

                color: #7c7c7c;
                font-size: 12px;

                border: 1px solid #d7d7d6;
            }
        }
        .right_section {
            width: 48%;
            /* stylelint-disable-next-line */
            label {
                display: block;
                width: 100%;
                margin-bottom: 12px;

                font-size: 12px;
            }
            input {
                display: block;
                width: 100%;
                padding: 8px 15px;

                color: #7c7c7c;
                font-size: 12px;

                border: 1px solid #d7d7d6;
            }
        }
    }
    /* stylelint-disable-next-line */
    h2 {
        font-size: 14px;
    }
}
.first-tab-form.fifth-tab-form {
    padding: 0;
}
.business-tab-form.fifth-tab-form {
    /* stylelint-disable-next-line */
    p {
        margin-bottom: 20px;
    }
    /* stylelint-disable-next-line */
    input {
        width: 100%;
        margin-bottom: 15px;
        padding: 8px 10px;

        font-size: 12px;

        border: 1px solid #d7d7d6;
        border-radius: 2px;
    }
}
.tab-content.border-tab-section {
    .tab-pane {
        padding: 40px 80px 20px 80px;

        background: #fff;
    }
    .first-tab-form {
        padding: 0;
    }
}
.popover-body {
    padding: 0;
}
.bs-popover-left {
    > .arrow {
        display: none !important;
    }
}

.invalid-partner-share{
    color: red;
    font-size: smaller;
}


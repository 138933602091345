$loyaltyFontSize: 17px;
$loyaltyBorderRadius: 5px;
$bonus-item-content-height: 60px;

.loyalCustomer{

    .loyaltyMeaningSection{
        margin-top: 8px;
        padding: 12px;
        p{
            font-weight:bold;
            font-size:14px;
            line-height: 30px;
        }

    }

    .priceDropdown{
        width: 64px;
        height: 25px;

        background-image: url("../../../img/dropdown_icon.png");
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
        border: 1px solid #dfdfdf;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .loyaltySection{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;

        .bonusItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .bonusItemContent{
                display:flex;
                align-items: center;
                justify-content: center;
                width:130px;
                height:  $bonus-item-content-height;
                padding: 20px;

                color: $whiteColor;
                text-align: center;

                background-color: $primaryColor;

                border-radius: $loyaltyBorderRadius;

                &.topUpContainer{
                    background-color: $whiteColor;
                    border: 1px solid $primaryColor;
                    border-radius: $loyaltyBorderRadius;
                }

                &.months{
                    width:150px;
                }
                p{
                    font-weight: bold;
                    font-size:$loyaltyFontSize;
                    line-height:$loyaltyFontSize;
                }

                &.inputContainer{
                    padding:0;

                    background-color: transparent;

                    .inputContent{
                        display:flex;
                        align-items: center;

                        .poundSign{
                            margin-left:40px;
                        }
                    }
                }

                &.selectedContainer{
                    display: block;
                    width: 150px;
                    padding:0;

                    font-weight: bold;

                    background-color: transparent;
                    .ng-select-container{
                        height:60px;
                        max-height:60px;

                        border: 1px solid $primaryColor;
                        border-radius: 5px;

                        input{
                            color: $primaryColor;
                        }
                        .ng-arrow-wrapper{
                            .ng-arrow{
                                border-color:$primaryColor transparent transparent;
                            }
                        }

                        .ng-value-container{
                            align-items: center;
                            justify-content: center;
                            .ng-value{
                                color:$primaryColor;
                                font-size:$loyaltyFontSize;
                            }
                        }
                    }
                }

                .inputBorderPrimary{
                    width: 100%;
                    height:60px;

                    color:$primaryColor;
                    font-weight: bold;

                    background-color: transparent;
                    border: none;
                    border-radius: 0;

                    outline: none;

                    &:focus{
                        box-shadow: none;
                    }
                    &::placeholder {
                        color: $primaryColor;

                    }
                }
            }
            .bonusItemFooter{
                max-width: 130px;
                height: 24px;
                margin-top: 10px;

                line-height: 16px;

                text-align: center;
                /* Style the info icon */
                .infoIconContainer {
                    position: relative;

                    display: inline-block;

                    cursor: pointer;
                }

                /* Hide the tooltip text by default */
                .tooltipText {
                    position: absolute;
                    bottom: 145%;
                    left: 50%;
                    z-index: 1;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 130px;

                    height: 30px;

                    padding: 5px;

                    color: $whiteColor;
                    text-align: center;

                    background-color: $primaryColor;
                    border-radius: 6px;
                    transform: translateX(-50%);
                    visibility: hidden;
                    opacity: 0;

                    transition: opacity 0.3s;
                }

                /* Show the tooltip text when hovering over the container */
                .infoIconContainer:hover .tooltipText {
                    visibility: visible;
                    opacity: 1;
                }

            }
        }
        .iconsHeight {
            // Set margin-bottom based on half the bonusItemContent height
            margin-bottom: calc(#{$bonus-item-content-height} / 2);
        }

    }
    .loyalInputBorder{
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
    }

}

.top-up-monthly-input::placeholder {
    color: $primaryColor;

    opacity: 1;
}

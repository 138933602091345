.main-chat-section {
    padding: 40px;

    background: #fff;
    .message-title-div {
        .mesage {
            display: block;
            margin: 32px 0 24px 0;

            color: #828282;
        }
    }
    .msg-top-header {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15px;

        border-bottom: 2px solid #f6f6f6;
        .left-section {
            width: 50%;
            p {
                margin-top: 15px;
            }
        }
        .rightSection {
            display: flex;
            grid-gap: 5px;

            align-items: flex-end;
            justify-content: flex-end;
            width: 50%;
        }
    }
    .bottom-chat-section {
        .chat-box {
            padding: 20px 0;
            .first-person {
                display: flex;
                grid-gap: 20px;
                justify-content: flex-end;
                padding: 3px;
                .left-section {
                    padding: 20px 20px 0 20px;

                    background: #f6f6f6;
                    border-radius: 15px 15px 0 15px;
                    p {
                        font-size: 12px;
                    }
                    .time-right {
                        display: block;

                        float: right;

                        padding: 8px 0;

                        color: #7c7c7c;

                        font-size: 8px;
                    }
                }
                .rightSection {
                    .person-icon {
                        .first-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 48px;
                            height: 48px;
                            margin-right: 10px;

                            color: #fff;
                            font-size: 24px;
                            text-transform: uppercase;
                            text-decoration: none;

                            background: #e11455;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    }
                    .time-right {
                        display: none;
                    }
                }
            }
            .second-person {
                display: flex;
                grid-gap: 20px;
                justify-content: flex-start;
                margin-top: 40px;
                .left-section {
                    .person-icon {
                        .second-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 48px;
                            height: 48px;
                            margin-right: 10px;

                            color: #fff;
                            font-size: 24px;
                            text-transform: uppercase;
                            text-decoration: none;

                            background: transparent;
                            border-radius: 50%;

                            cursor: pointer;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .time-right {
                        display: none;
                    }
                }
                .rightSection {
                    padding: 20px 20px 0 20px;

                    background: #f6f6f6;
                    border-radius: 15px 15px 15px 0;
                    p {
                        font-size: 12px;
                    }
                    .time-right {
                        display: block;
                        padding: 8px 0;

                        color: #7c7c7c;
                        font-size: 8px;
                    }
                }
            }
        }
        .chat-text-field {
            margin-top: 40px;
            .send-input {
                position: relative;

                cursor: pointer;

                form {
                    position: relative;
                    input {
                        width: 100%;
                        padding: 10px;

                        font-size: 12px;

                        background: #f6f6f6;
                        border: 0;
                    }
                    button {
                        position: absolute;
                        top: 0;
                        right: 0;

                        padding-right: 10px;

                        background: transparent;
                        border: 0;
                        /* stylelint-disable-next-line */
                        img {
                            width: 20px;
                            padding-top: 7px;
                        }
                    }
                }
            }
            .bottom-btn-section {
                display: flex;
                grid-gap: 20px;
                justify-content: flex-end;
                margin-top: 20px;
                .sm-btn {
                    padding: 2px 18px;

                    color: #4c4c4c;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20.12px;
                    text-transform: capitalize;

                    background: #f6f6f6;
                    border: 1px solid #d8d8d8;
                    border-radius: 5px;
                    &:hover {
                        background: #efefef;
                    }
                }
            }
        }
    }
}

.tabel-section.message-detail {
    .container {
        max-width: 81%;
    }
}
.account-summary-section {
    .customer-product {
        padding-top: 30px;
    }
    .top-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 18px;
        padding-bottom: 10px;
        padding-left: 14px;

        border-bottom: 1px solid #dfdfdf;
        /* stylelint-disable-next-line */
        input.form-control {
            display: inline-block;
        }
        /* stylelint-disable-next-line */
        input {
            width: 30%;
            height: 26px;
            margin: 4px;
            padding: 0.375rem 1rem;

            color: #495057;
            font-weight: 400;

            line-height: 1.5;

            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;

            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &::placeholder {
                color: #9c9898;
                font-size: 12px;
            }
        }
        .period {
            .message-box {
                display: inline-block;
            }
        }
        .period.hide {
            display: none;
        }
        .edit {
            display: none;
            margin-left: 44px;
        }
        .edit.active {
            display: block;
        }
        button.com-btn {
            padding: 2px 18px;

            color: #000;
            font-weight: 400;
            font-size: 11px;
            line-height: 20.12px;

            background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
            border: 1px solid #d8d8d8;
            border-radius: 1px;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            img {
                position: relative;
                top: 2px;

                width: 13px;
            }

            span {
                margin-left: 8px;
            }
        }
        /* stylelint-disable-next-line */
        button {
            @include buttonStyle;
            /* stylelint-disable-next-line */
            &:hover {
                color: #0491df;
            }
        }
        .right-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            h2 {
                color: #4c4c4c;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }
            .message-box {
                padding: 5px 0 0 43px;

                font-size: 11px;

                line-height: 34px;
            }
            a {
                padding: 5px 0 0 43px;

                font-size: 11px;
            }
            /* stylelint-disable-next-line */
            span {
                display: inline-block;
                padding-top: 10px;
                padding-left: 22px;

                color: #706f6f;
                font-size: 11px;
                line-height: 18.64px;
            }
        }
        .left-export {
            position: relative;
            /* stylelint-disable-next-line */
            span {
                display: inline-flex;
                margin-right: 20px;

                color: #8f8f8f;
                font-size: 14px;
            }
        }
        .toggle-btn-option {
            position: absolute;
            top: 50px;
            right: 0;

            width: 150px;

            background: #fff;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            ul {
                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px;

                    font-size: 13px;

                    border-bottom: 2px solid #e4e4e4;
                    a {
                        color: $primaryColor;
                        font-size: 15.4px;
                        line-height: 18.48px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .col-row {
        .create-new {
            display: flex;
            grid-gap: 5px;
            align-items: center;
            padding: 8px 30px;

            color: $White-clr;
            font-weight: 400;
            font-size: 13px;
            line-height: 20.12px;
            text-transform: capitalize;

            background: $blue-clr;
            border: 1px solid $blue-clr;
            border-radius: 5px;
            box-shadow: none;
            &:hover {
                color: $White-clr;

                background: #2a6cdd;
            }
        }
    }
}

.bottom-list-section {
    .list {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 25vw;
    }
    /* stylelint-disable-next-line */
    ul {
        display: flex;
        align-items: flex-end;

        padding-left: 75px;
        /* stylelint-disable-next-line */
        li {
            width: 25% !important;
            padding: 0 22px;

            @include mediumDesktop {
                width: 28% !important;
            }

            border-right: 2px solid #e4e4e4;
            /* stylelint-disable-next-line */
            a {
                padding-left: 8px;

                color: $primaryColor;
                font-size: 12px;
                line-height: 21.12px;
                text-decoration: none;
            }
            /* stylelint-disable-next-line */
            &:last-child {
                border-right: 0;
            }
            /* stylelint-disable-next-line */
            &:first-child {
                .price-sec {
                    padding-left: 0;
                }
            }
            /* stylelint-disable-next-line */
            .price-sec {
                padding: 0 10px;

                white-space: nowrap;
                text-align: center;

                .price {
                    display: inline-block;
                    margin-bottom: 5px;

                    color: #4c4c4c;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                }
                .price-label {
                    color: #4c4c4c;
                    font-size: 12.5px;
                    line-height: 21.12px;
                }
            }

            /* stylelint-disable-next-line */
            &:nth-child(1) {
                padding-left: 0;
            }
        }
    }
    margin-bottom: 50px;
    padding-top: 20px;
}

.message-list-section {
    border-bottom: 1px solid #dfdfdf;
    /* stylelint-disable-next-line */
    ul {
        display: flex;
        align-items: flex-end;

        padding-left: 0;
        /* stylelint-disable-next-line */
        li {
            width: 100%;
            padding: 0 22px;

            /* stylelint-disable-next-line */
            &:nth-last-child(2) {
                padding-left: 36px;

                border: 0;
            }
            /* stylelint-disable-next-line */
            a {
                padding-left: 8px;

                color: $primaryColor;
                font-size: 12px;
                line-height: 21.12px;
                text-decoration: none;
            }
            /* stylelint-disable-next-line */
            &:last-child {
                border-right: 0;
            }
            /* stylelint-disable-next-line */
            &:first-child {
                .price-sec {
                    padding-left: 0;
                }
            }
            /* stylelint-disable-next-line */
            .price-sec {
                padding: 0 10px;
                .price-label {
                    color: #4c4c4c;
                    font-weight: 600;
                    font-size: 10px;

                    line-height: 21.12px;
                }
                .message-detail-header {
                    color: #828282;
                    font-size: 13px;
                }
                .red {
                    color: #e63723 !important;
                }
            }

            /* stylelint-disable-next-line */
            &:nth-child(1) {
                padding-left: 0;
            }
        }
    }
}
.table-responsive {
    .row {
        .input-box {
            margin-top: 7px;
        }
        .message {
            margin-top: 5px;
        }
        .search {
            padding-left: 0;
        }
        .checkbox {
            margin-top: 7px;
        }
    }
    .table-header {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 0;
        .mid-section {
            bottom: 37px;
        }

        /* stylelint-disable-next-line */
        button {
            margin: 0 8px 8px 8px;
            padding: 2px 23px;

            color: #4c4c4c;
            font-weight: 400;
            font-size: 11px;
            line-height: 20.12px;

            background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
            border: 1px solid #d8d8d8;
            border-radius: 5px;
            box-shadow: 1px 1px 5px -2px #888888;
            /* stylelint-disable-next-line */
            &:hover {
                color: #0491df;
            }
        }
        /* stylelint-disable-next-line */
        .form-search {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: repeat(2, 1fr);
            select {
                width: 100%;
                height: 26.11px;
                min-height: auto;
                padding: 0;

                color: #b5b5b5;
                font-size: 12px;

                border: 2px solid #ebebea;
                border-radius: 4px;
                &:focus {
                    border: 2px solid #30a1ff;
                }
            }
            /* stylelint-disable-next-line */
            input {
                width: 100%;
                height: 26.11px;
                padding: 0.375rem 1.75rem;

                color: #495057;
                font-weight: 400;

                line-height: 1.5;

                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;

                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                &::placeholder {
                    color: #9c9898;
                }
            }
        }
    }
    .tabel-section.message-table-section {
        .new-design-table-sec.notification-cms {
            .top-trans-section {
                .rightSection {
                    width: 60%;
                }
                .left-section {
                    width: 40%;
                }
                .mid-section {
                    display: none;
                    width: auto;
                }
            }
        }
    }
    .tabel-section {
        .inner-tabel-tab {
            padding: 47px 52px 0 52px;

            background: #fff;
            border-radius: 1px;
        }
    }
}

/* stylelint-disable-next-line */
.messages-div-cms {
    border: 1px solid #dfdfdf;
    /* stylelint-disable-next-line */
    .top-trans-section {
        /* stylelint-disable-next-line */
        .rightSection {
            padding-right: 30px;
        }
    }
    .transaction-section {
        width: 95%;
        margin: 0 auto 40px auto;

        border-bottom: 1px solid #c1c1c1;
    }
}

.message-table-section {
    .top-header-sec {
        .inner-btn-sec {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 20px;
            padding: 20px 40px;

            border: 2px solid #e2e2e2;
            border-bottom: 0;
            button.com-btn {
                padding: 2px 18px;

                color: #fff;
                font-weight: 400;
                font-size: 11px;
                line-height: 20.12px;

                background: #307aff;
                border: 1px solid #307aff;
                border-radius: 5px;
                box-shadow: none;
                /* stylelint-disable-next-line */
                img {
                    position: relative;
                    top: 2px;

                    width: 13px;
                }

                span {
                    margin-left: 8px;
                }
            }
            /* stylelint-disable-next-line */
            button {
                /* stylelint-disable-next-line */
                &:hover {
                    background: #2a6cdd;
                }
            }
            button.undo-btn {
                display: flex;
                grid-gap: 5px;
                padding: 6px 10px;

                font-size: 11px;

                background: transparent;
                border: 0;
            }
        }
    }
}

.error-msg {
    margin-bottom: 20px;
    /* stylelint-disable-next-line */
    p {
        display: flex;
        padding: 10px 20px;

        color: $White-clr;
        font-size: 12px;
        line-height: 16px;

        background: #9d9d9c;
        border-radius: 5px;
        /* stylelint-disable-next-line */
        span {
            display: flex;
            align-items: center;
            padding-right: 10px;
            /* stylelint-disable-next-line */
            img {
                width: 20px;
            }
        }
    }
}
/* stylelint-disable-next-line */
.error-msg {
    margin-bottom: 20px;
    /* stylelint-disable-next-line */
    p {
        display: flex;
        padding: 10px 20px;

        color: $White-clr;
        font-size: 12px;
        line-height: 16px;

        background: #9d9d9c;
        border-radius: 5px;
        /* stylelint-disable-next-line */
        span {
            display: flex;
            align-items: center;
            padding-right: 10px;
            /* stylelint-disable-next-line */
            img {
                width: 20px;
            }
        }
    }
}

.new-design-table-sec.user-tabel-section {
    .tabel-section {
        .inner-tabel-tab {
            margin-top: 50px;

            border: $standardBorderWidth solid $borderColor;
            border-radius: 0;

            &.usersMinHeight{
                min-height: 1000px;
            }

            .table {
                th {
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 9%;
                    }

                    &:nth-child(3) {
                        width: 5%;
                    }

                    &:nth-child(4) {
                        width: 12%;
                    }

                    &:nth-child(5) {
                        width: 12%;
                    }

                    &:nth-child(6) {
                        width: 11%;
                    }

                    &:nth-child(7) {
                        width: 6%;

                        text-align: center;
                    }

                    &:nth-child(8) {
                        width: 5%;
                    }
                }

                td {
                    a {
                        color: $primaryColor;
                    }

                    &:nth-child(7) {
                        a {
                            margin-left: 24px;
                        }
                    }

                    &:nth-child(8) {
                        width: 90px;

                        a {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.user-tabel-section {

    // .users-div-tabel {
    //     .grid-table {
    //         width: 60px;
    //     }
    // }
    .users-div-tabel.tabel-section {
        .inner-tabel-tab {
            .table-striped {
                tbody {
                    tr {

                        /* stylelint-disable-next-line */
                        td {
                            .grid-table-div {
                                width: 54px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .verticle-aign-div {
        width: 140px;
    }
}

/* stylelint-disable-next-line */
.user-tabel-section {

    /* stylelint-disable-next-line */
    .verticle-aign-div {
        svg {
            width: 12px;
        }
    }
}

/* stylelint-disable-next-line */
.users-div-tabel {

    /* stylelint-disable-next-line */
    .grid-table {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ratio-btn.green-btn {
            display: block;
            width: 12px;
            height: 12px;

            background: #3aaa35;
            border: 0;
            border-radius: 50%;
        }

        .ratio-btn.red-btn {
            display: block;
            width: 12px;
            height: 12px;

            background: #da0013;
            border: 0;
            border-radius: 50%;
        }
    }
}

/* stylelint-disable-next-line */
.users-div-tabel.tabel-section {

    /* stylelint-disable-next-line */
    .inner-tabel-tab {

        /* stylelint-disable-next-line */
        .table-striped {

            /* stylelint-disable-next-line */
            tbody {

                /* stylelint-disable-next-line */
                tr {

                    /* stylelint-disable-next-line */
                    td {
                        button {
                            width: initial;
                            padding: 0;

                            background: transparent;

                            svg {
                                width: 12px;
                            }
                        }

                        /* stylelint-disable-next-line */
                        .grid-table-div {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}

.tabel-section.users-div-tabel {
    position: relative;

    /* stylelint-disable-next-line */
    td {

        /* stylelint-disable-next-line */
        a {
            color: #212529;
            text-decoration: none;
        }
    }
}

.user-group-div-sec {

    /* stylelint-disable-next-line */
    .tabel-section {

        /* stylelint-disable-next-line */
        .inner-tabel-tab {
            margin-top: 50px;

            border-radius: 0;
            .table-responsive {
                padding-bottom: 0;
            }
        }

        .border-style {
            border: 1px solid #dfdfdf;
        }
    }

    .users-div-tabel.tabel-section {
        .inner-tabel-tab {
            .table-striped {
                tbody {
                    tr {

                        /* stylelint-disable-next-line */
                        td {
                            .grid-table-div {
                                width: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.new-design-table-sec {
    .user-group-div-sec {

        /* stylelint-disable-next-line */
        .inner-tabel-tab {
            padding-top: 40px;

            border: 2px solid #e2e2e2;
            border-radius: 0;
        }
    }
}

.search-bar-user-section {
    position: relative;

    .content-secrch {
        display: flex;
        grid-gap: 15px;
        padding-top: 35px;

        border-top: 0;

        .svgs {
            width: 215px;
            height: 35px;

            @media (max-width: 500px) {
                width: 100%;
            }
        }

        input {
            width: 100%;
            height: 30px;
            padding: 5px 12px 6px;

            font-size: 12px;

            background-color: $White-clr;
            border: 2px solid $msgSearchBorder !important;

            border-radius: 0;

            /* stylelint-disable-next-line */
            &::placeholder {
                color: #575756;
                font-size: 12px;
            }

            &:focus-visible {
                border: 2px solid $msgSearchBorderHover !important;
                outline: 0;
            }
        }

        &::after {
            display: table;

            clear: both;

            content: "";
        }

        span {
            position: relative;
            top: -28px;

            left: 288px;

            /* stylelint-disable-next-line */
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.big-search-bar-section {
    position: relative;

    .content-secrch {
        display: flex;
        grid-gap: 20px;
        justify-content: flex-end;

        margin-bottom: 30px;
        padding-top: 20px;

        border-top: 0;

        .input-div-section {
            position: relative;

            width: 32%;
        }

        /* stylelint-disable-next-line */
        input {
            width: 100%;
            padding: 7.5px 30px;

            background: #fff;
            border: 1px solid #d7d7d6;

            /* stylelint-disable-next-line */
            &::placeholder {
                color: #d7d7d6;
            }
        }

        /* stylelint-disable-next-line */
        .search-div-sec button {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 4px 18px;

            color: #fff;
            font-size: 11px;
            line-height: 20.12px;
            text-transform: capitalize;

            background: #307aff;
            border: 1px solid #307aff;
            border-radius: 5px;
            box-shadow: none;
        }
    }
}

/* stylelint-disable-next-line */
.tabel-section {
    .container {
        max-width: 1200px;

        /* stylelint-disable-next-line */
        .topUserHeaderTab .rightSection button {
            margin-top: 33px;
        }
    }
}

.topCenter {
    padding: 0 30px;
}

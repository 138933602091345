.cardFeesComponent{
    .feesImg{
        margin-right:5px;

        &.fasterPayment{
            margin-left:0;
            img{
                width:30px;
            }
        }
        &.pciPlus{
            position: relative;

            margin-right: 34px;
            margin-left: 5px;

            img{
                position: absolute;
                top: -17px;

                width: 23px;
                margin-top: 10px;
            }
        }
    }
}

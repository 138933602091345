// Small devices (landscape phones, 576px and up)
@mixin phone {
    @media (min-width: $smallDevice) {
        @content
    }
}


// Medium devices (tablets, 768px and up)
@mixin tablet{
    @media (min-width: $tabletDevice) {
        @content
    }
}

// Large devices (desktops, 992px and up)
@mixin desktop{
    @media (min-width: $largeDevice) {
        @content
    }
}


// X-Large devices (large desktops, 1200px and up)
@mixin mediumDesktop{
    @media (min-width: $xLargeDevice) {
        @content
    }
}


// XX-Large devices (larger desktops, 1400px and up)
@mixin largeDesktop{
    @media (min-width: $xxLargeDevice) {
        @content
    }
}

.top-section {
    .right-title {
        display: flex;
        justify-content: space-between;
        .right-title-btn {
            width: 80px;
            padding: 2px 18px;

            color: #fff;
            font-weight: 400;
            font-size: 11px;
            line-height: 20.12px;
            text-transform: capitalize;

            background: #307aff;
            border: 1px solid #307aff;
            border-radius: 5px;
            box-shadow: none;
        }

        span {
            padding-left: 10px;

            color: #828282;
            font-size: 11px;
        }
    }
}

.commercial-header {
    ul {
        display: flex;
        justify-content: center;

        @media (max-width: 480px) {
            justify-content: flex-start;
            padding: 0;
        }

        li {
            @media (max-width: 480px) {
                width: 33%;
            }
        }
    }
}

.commercial-navs {
    div#tabs-1 {
        .nav-tabs {
            display: flex;
            grid-gap: 100px;
            padding: 35px 80px 20px 66px;

            background: #fff;
            border: none;

            @media (max-width: 1500px) {
                grid-gap: 60px;
            }

            @media (max-width: 991px) {
                grid-gap: 40px;
            }

            .finance-term {
                margin: -10px 0 9px;
                padding: 12px 20px;

                font-size: 14px;

                border: 1px solid #dfdfdf;

                @media (max-width: 1600px) {
                    width: 27%;
                }

                @media (max-width: 480px) {
                    width: 100%;
                }

                label {
                    margin-right: 10px;
                }

                select {
                    width: 100px;

                    padding: 0 18px;

                    border: 1px solid #dfdfdf;
                }
            }

            li {
                a {
                    display: inline-block;
                    padding-right: 0;
                    padding-bottom: 10px;
                    padding-left: 0;

                    color: $primaryColor;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 26.3px;
                }

                a.active {
                    border-bottom: 2px solid $primaryColor;
                }
            }
        }

        padding: 0;
    }

    .nav-tabs.parent-tab {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 7px;
        padding: 0;

        background: rgb(246, 246, 246);
        border-width: 0;

        .nav-link {
            justify-content: center;
            height: 100%;
            margin-bottom: -1px;
            padding: 8.1px 10px;

            color: $primaryColor;
            font-weight: 500;
            font-size: 16px;
            line-height: 26.4px;
            text-align: center;

            background: #f6f6f6;
            border: 1px solid #e2e2e2;
            border-bottom: none;

            box-shadow: none;
        }

        .nav-link.active {
            position: relative;

            color: #307aff;

            background: #fff;
            border: 1px solid #e2e2e2;
            border-bottom: none;
            box-shadow: none;

            &:before {
                position: absolute;
                right: 0;
                bottom: -1px;
                left: 0;

                display: block;
                width: 100%;
                // height: 5px;

                background: #fff;

                content: "";
            }
        }
    }

    div#tabs-4 {
        padding: 55px 0;

        .right_section {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

div#tabs-3 {
    background: #fff;

    .table-responsive {
        h2 {
            margin-bottom: 15px;

            color: #333333;
            font-size: 14px;
        }
    }
}
.productsTab {
    padding: 0 80px 20px 66px;
}
.cardRates {
    padding: 35px 80px 20px 66px;

}
.commercial-card-rate {
    padding-bottom: 30px;

    .left_section {
        @media (min-width: 480px) and (max-width: 991px) {
            margin: 0 39px;
        }

        .vt {
            display: flex;
            grid-gap: 120px !important;

            @media (max-width: 480px) {
                grid-gap: 0 !important;
            }
        }

        .rateTitle {
            margin-right: 32px;
        }

        .card-header {
            padding: 5px !important;
        }

        .top-header-section {
            display: flex;
            grid-gap: 44px;
            justify-content: flex-start;
            height: 34px;
            padding: 7px 5px 5px 10px;

            color: #0491df;
            font-weight: 400;
            font-size: 11px;
            line-height: 19.2px;

            background: #f6f6f6;
            border: none;

            .title-one {
                width: 40%;

                padding-top: 0;

                color: $whiteColor;

                font-size: 12px;
            }

            .title-2 {
                width: 65%;
            }

            .sub-head {
                display: flex;
                grid-gap: 62px;

                @media (max-width: 1599px) and (min-width: 992px) {
                    grid-gap: 45px;
                }

                .title-four {
                    font-size: 12px;
                }
            }
        }

        .card {
            .left-content {

                /* stylelint-disable-next-line */
                h2 {
                    width: 105px;
                    padding-top: 12px;

                    font-size: 11px;
                }
            }

            .card-header {
                display: flex;
                padding: 0;

                color: #000;
                font-size: 10px;

                line-height: 15px;

                background: transparent;

                .right-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding-left: 0;

                    /* stylelint-disable-next-line */
                    input {
                        width: 50px;
                        margin: 0;
                        padding: 4px 5px;

                        text-align: center;

                        border: 1px solid #dfdfdf;
                    }

                    /* stylelint-disable-next-line */
                    button {
                        color: #307aff;

                        background: transparent;
                        border: transparent;

                        @media (min-width: 991px) and (max-width: 1500px) {
                            padding-left: 70px;
                        }
                    }

                    .sub-row {
                        display: flex;
                        grid-gap: 20px;
                        margin: 0 45px;

                        @media (max-width: 1600px) {
                            margin: 0 25px;
                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                        }

                        @media (max-width: 480px) {
                            grid-gap: 0;
                            margin: 0 3px;
                        }
                    }

                    .vt-sub-row {
                        display: flex;
                        grid-gap: 42px;
                        margin: 0 35px;
                        padding-left: 3vw;

                        font-size: 11px;

                        @media (max-width: 1600px) {
                            grid-gap: 62px;

                            margin: 0 48px;
                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                            padding-left: 20vw !important;
                        }

                        @media (max-width: 480px) {
                            grid-gap: 0;
                            margin: 0 3px;

                            padding-left: 10vw !important;
                        }

                        span {
                            width: 75px;
                            padding: 10px 0;

                            @media (max-width: 1599px) and (min-width: 992px) {
                                width: 35px;
                            }
                        }
                    }
                }
            }

            .card-body {
                .left-content {
                    width: 80px;
                }

                display: flex;

                padding: 0;

                font-size: 10px;
                line-height: 15px;

                background: #f6f6f6;

                .commercial-heading {
                    padding: 10px 7px !important;
                }

                .right-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding-left: 0;

                    /* stylelint-disable-next-line */
                    input {
                        width: 50px;
                        margin: 0;
                        padding: 4px 5px;

                        text-align: center;

                        border: 1px solid #dfdfdf;
                    }

                    .sub-row {
                        display: flex;
                        grid-gap: 20px;
                        margin: 0 45px;

                        @media (max-width: 1600px) {
                            margin: 0 25px;
                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                        }

                        @media (max-width: 480px) {
                            margin: 0 3px;
                        }
                    }

                    .vt-sub-row {
                        display: flex;
                        grid-gap: 68px;
                        margin: 0 84px;
                        padding-left: 34px;

                        @media (max-width: 1600px) {
                            grid-gap: 50px;
                            margin: 0 81px;

                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                        }

                        @media (max-width: 480px) {
                            margin: 0 3px;
                        }

                        span {
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }

    .no-margin {
        margin: 0 !important;
    }

    /* stylelint-disable-next-line */
    .right_section {
        // margin: -48px 0px 0px 0px;
        margin: 0px 15px 0 15px;

        @media (max-width: 991px) {
            margin: 0 39px;
        }

        .collapse {
            border: none;
        }

        /* stylelint-disable-next-line */
        h2 {
            padding: 0 0 15px 0;
        }

        .top-space {
            margin-top: 35px;
        }

        .top-header-section {
            display: flex;
            grid-gap: 44px;
            height: 34px;
            padding: 7px 5px 5px 10px;

            color: #0491df;
            font-weight: 400;
            font-size: 11px;
            line-height: 19.2px;

            background: #f6f6f6;
            border: none;

            .title-one {
                width: 38%;
                padding-top: 0;

                font-size: 12px;
            }

            .title-2 {
                width: 65%;
            }

            .sub-head {
                display: flex;
                grid-gap: 10px;
            }
        }

        .card {
            /* stylelint-disable-next-line */
            padding: 0;

            border: 0;

            /* stylelint-disable-next-line */
            .left-content {
                width: 40%;

                /* stylelint-disable-next-line */
                h2 {
                    padding-top: 12px;

                    font-size: 11px;
                }
            }

            .card-header {
                display: flex;
                grid-gap: 44px;
                height: 36px;
                padding: 5px;

                font-size: 10px;
                line-height: 15px;

                background: transparent;

                /* stylelint-disable-next-line */
                .col {
                    display: flex;
                    align-items: center;
                    padding: 0;

                    font-size: 11px;
                }

                .right-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    width: 38%;

                    padding-left: 0;

                    font-size: 11px;

                    /* stylelint-disable-next-line */
                    input {
                        width: 50px;
                        margin: 0;
                        padding: 4px 5px;

                        text-align: center;

                        border: 1px solid #dfdfdf;
                    }

                    /* stylelint-disable-next-line */
                    .sub-row {
                        display: flex;
                        grid-gap: 20px;
                        margin: 0 45px;

                        @media (max-width: 1600px) {
                            margin: 0 25px;
                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                        }

                        @media (max-width: 480px) {
                            margin: 0 3px;
                        }
                    }
                }

                /* stylelint-disable-next-line */
                button {
                    color: #307aff;

                    background: transparent;
                    border: transparent;
                }
            }

            .card-body {
                .left-content {
                    width: 48%;
                }

                .left-h2 {
                    width: 48%;
                    padding: 12px 24px;
                }

                display: flex;

                padding: 5px;

                font-size: 10px;
                line-height: 15px;

                background: #f6f6f6;

                .right-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding-left: 0;

                    /* stylelint-disable-next-line */
                    input {
                        width: 50px;
                        margin: 0;

                        padding: 4px 5px;

                        text-align: center;

                        border: 1px solid #dfdfdf;
                    }

                    .sub-row {
                        display: flex;
                        grid-gap: 20px;
                        margin: 0 45px;

                        @media (max-width: 1600px) {
                            margin: 0 25px;
                        }

                        @media (max-width: 991px) {
                            margin: 0 36px;
                        }

                        @media (max-width: 480px) {
                            margin: 0 3px;
                        }
                    }
                }
            }
        }
    }

    .commercial-additional-services {
        .card {
            .card-header {

                /* stylelint-disable-next-line */
                h2 {
                    padding: 0;

                    font-size: 11px;
                }
            }
        }
    }
}

.advanceSearchContainer{
    position:relative;

    padding: $standardSpacing;

    .noPadding{
        padding:0;
    }

    &.withBorder{
        border: $standardBorderWidth solid $borderColor;
    }

    &.fullWidth{
        width:100%;

        .form-sec{
            grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

            &.col4{
                grid-template-columns: 1fr 1fr 2fr 0.4fr;
            }
            &.col5{
                grid-template-columns: 1fr 1fr 1fr 2fr .5fr !important;
            }
            &.col6{
                grid-template-columns: 1fr 1fr 1fr 1fr 0.9fr 1fr;
            }
            &.col7{
                grid-template-columns: 0.6fr 0.6fr 0.6fr 0.6fr 0.7fr
            }

            .clear-btn{
                position:relative;
            }
        }
    }

    .btnClose{
        position:absolute;
        top:10px;
        right:10px;
        z-index:10;

        padding:0;

        color: $primaryColor !important;

        background: transparent !important;
        border:none !important;

        &:hover{
            background: transparent !important;
        }
    }
}

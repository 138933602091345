/* stylelint-disable */
.login-main-section {
    height: 100%;

    header {
        display: none;
    }

    .notification {
        width: 40%;
    }

    .many-times-pin {
        display: none;
    }

    .many-times-pin.active {
        display: flex;
        grid-gap: 10px;
        justify-content: center;
        width: 100%;

        background-color: #ffcfcf;
        border: 1px solid #f90606;
        border-radius: 5px;
    }

    .user-inactive {
        display: none;
    }

    .user-inactive.active {
        display: flex;
        grid-gap: 10px;
        justify-content: center;
        width: 100%;

        background-color: #fcd4b1;
        border: 1px solid #fc8815;
        border-radius: 5px;

        svg {
            width: 20px;
            height: 20px;
        }

        .inactive-msg {
            margin-top: 4px;
        }
    }
}

.login-section {
    // height: 100%;

    background-color: white;
    // @media (max-width: 991px) {
    //     padding-bottom: 120px;
    // }

    .payPanelLogoContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .payPanelLogo {
            width: 200px;
            text-align: center;

            svg {
                width: 40%;
                height: 80px;
            }
        }
    }

    .tabel-section {
        padding-top: 100px;
    }

    // margin-bottom: 50px;

    .container {
        display: flex;
        flex-wrap: wrap;
        // align-content: center;
        justify-content: center;

        max-width: 1140px !important;

        height: 86vh;
        margin: 0 auto !important;

        padding: 0 250px;

        .row {
            position: absolute;

            top: 195px;

            width: 100%;

            @media (min-width: 768px) and (max-width: 1600px) {
                top: 145px;
            }
        }
    }

    .middle-section {
        position: relative;

        background: $White-clr;
        border-radius: 10px;

        form {
            display: flex;
            justify-content: center;
            width: 515px;
            height: 100%;
            margin: 0 auto;

            // .trust1 {
            //     display: none;
            //     width: initial;
            //     height: initial;
            //     margin-bottom: 0;
            //     padding: 0;

            //     cursor: pointer;

            //     &:checked {
            //         + {
            //             label {
            //                 width: auto;

            //                 padding-left: 20px !important;

            //                 text-align: left;

            //                 &:before {
            //                     position: absolute;
            //                     top: 0;
            //                     left: 0;
            //                 }

            //                 &:after {
            //                     position: absolute;
            //                     top: 0;
            //                     left: 0;

            //                     display: block;
            //                     width: 17px;
            //                     height: 17px;

            //                     background: #307aff url(../../img/checkmark-16.png);
            //                     background-repeat: no-repeat;
            //                     background-position: center;
            //                     background-size: 10px;
            //                     border-radius: 0;
            //                     transform: none;

            //                     content: "";
            //                 }
            //             }
            //         }
            //     }
            // }

            .header {
                position: absolute;
                top: 0;

                width: 100%;
                height: 60px;

                color: $White-clr;

                background: #121925;
                border-radius: 20px 0 0 0;

                p {
                    padding: 20px;

                    font-weight: 500;
                    text-align: center;
                }
            }

            .left-section {
                position: relative;

                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                width: 75%;
                height: 100%;
                padding: 60px 0;

                .loadingStep1 {
                    .appLoader {
                        margin: 0;
                    }
                }

                &.loadingStep2 {
                    flex-direction: column;

                    padding: 20px;

                    label {
                        font-weight: 500;
                    }

                    .appLoader {
                        margin: 15px 0 0;
                    }
                }

                &.loadingStep3 {
                    padding-bottom: 20px;

                    label {
                        margin-bottom: 20px;

                        padding: 10px 0;

                        color: $Grey2-clr;

                        font-size: 15px;


                    }

                    .loadingStep3Container {
                        .appLoader {
                            margin: 15px 0 0;
                        }
                    }
                }

                h2 {
                    font-weight: 600;
                    font-size: 25px;

                    text-align: left;
                }

                p {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: center;

                    color: $grayColor;
                }

                p.align-left {
                    line-height: 22px;
                    text-align: left;
                }

                .form-group {
                    padding: 0;

                    text-align: center;

                    label {
                        width: 100%;
                        margin-bottom: 20px;
                        padding: 10px 0;

                        // font-weight: 500;

                        font-size: 15px;

                        text-align: center;

                        color: $grayColor;

                        &:after {
                            left: 5px;
                        }
                    }
                }

                form {
                    padding: 10px;

                    text-align: center;
                }

                .bottomLoginSection {
                    width: 100%;

                    margin-top: 30px;

                    font-size: 15px;

                    ul {
                        li {
                            text-align: center;
                        }
                    }
                }

                ngx-intl-tel-input.ng-invalid.ng-touched {
                    input {
                        border: 1px solid #c0392b !important;
                    }
                }
            }
        }
    }

    .no-cover {
        .middle-section {
            .left-section {
                h2 {
                    text-align: center;
                }

                p {
                    text-align: center;
                }

                .form-group {
                    label {
                        width: initial;

                        text-align: center;
                    }
                }
            }

            .header {
                border-radius: 20px 20px 0 0;
            }

            padding-top: 120px;
            padding-bottom: 140px;

            border-radius: 20px 0 20px 20px;
        }
    }

    // .row.radio-btn {
    //     justify-content: center;
    //     padding-top: 30px;

    //     text-align: center;
    // }
    .radio-btn {
        justify-content: center;
        width: 189px;

        margin: 0 auto;

        padding-top: 20px;

        text-align: center;


    }

    .trust1 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


#verificationCode,#pinCode {
    input {
        width: 40px;
        height: 40px;

        margin: 0;

        border: 1px solid #dfdfdf;
        border-radius: 2px;
    }
}

#password {
    input {
        width: 40px;
        height: 40px;
        margin: 0;

        border: 1px solid #dfdfdf;
        border-radius: 0;
    }
}

#password_confirm {
    input {
        width: 40px;
        height: 40px;
        margin: 0;

        border: 1px solid #dfdfdf;
        border-radius: 0;
    }
}

#phoneVerificationCode {
    input {
        width: 40px;
        height: 40px;

        margin: 0;

        border: 1px solid #dfdfdf;
        border-radius: 2px;
    }
}

.forget-btn {
    text-align: center;

    &.back-to-login {
        position: absolute;
        right: 0;
        bottom: 30px;

        left: 0;
    }

    button {
        &:disabled {
            background: transparent;

            &:hover {
                opacity: 1;
            }
        }

        padding: 0;

        color: $primaryColor;
        font-size: 14px;

        background: transparent;
        border: 0;
        border-radius: 30px;
        box-shadow: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.submit-btn {
    width: 100%;
    margin-top: 25px;

    text-align: center;

    button {
        width: 100%;
        padding: 10px 30px;

        // color: $White-clr;
        // font-size: 14px;

        // background: $blue-clr !important;
        // border: 1px solid $blue-clr;
        // border-radius: 5px;

        // &:hover {
        //     opacity: 0.8;
        // }

        // &:disabled {
        //     background: #1d1d1b;

        //     &:hover {
        //         opacity: 1;
        //     }
        // }
    }
}


.forgetBtn {
    width: 100%;
    margin-top: 15px;

    text-align: center;

    button {
        width: 100%;
        padding: 10px 30px;
    }
}

.reset-btn {
    padding-bottom: 0;
    text-align: center;

    &.back-to-login {
        position: absolute;
        right: 0;
        bottom: 30px;

        left: 0;
    }
    button {
        &:hover {
            opacity: 0.8;
        }
        &:disabled {
            background: transparent;

            &:hover {
                opacity: 1;
            }
        }

        padding: 0;

        color: #0078c8;
        font-size: 14px;

        background: transparent;
        border: 0;
        border-radius: 30px;
        box-shadow: none;
    }
}


.main-login {
    display: flex;
    flex-wrap: wrap;
    min-height: 423px;
    padding: 0;

    border-radius: 20px;
    box-shadow: 3px 2px 11px -4px #888888;

    .middle-section {
        width: 100%;
    }

    .lef-img-section {
        width: 50%;
        overflow: hidden;

        border-radius: 0 10px 10px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.main-login.no-cover {
    display: block;
    flex: inherit;
    width: 50%;
    margin: 0 auto;

    border-radius: 20px;

    .middle-section {
        width: 100%;
        margin: 0 auto;
    }

    .lef-img-section {
        display: none;
    }
}

.ppLogoText {
    width: 75px;
    height: auto;
    margin-left: 5px;
}

.loginFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    background-color: #fff;

    span {
        font-size: 12px;
    }
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding-bottom: 15px;
    // margin-top: -20px;
    font-size: 12px;

    background-color: #fff;

    cursor: pointer;

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    :nth-child(1) {
        margin-right: 15px;
    }

    :nth-child(2) {
        margin-left: 15px;
    }
}

.back-login-btn {
    display: flex;

    padding-top: 7px;
    margin-left: 10px;

    align-items: flex-end;

    color: grey;
    font-size: 11px;
    // font-weight: 500;

    cursor: default;

    img {
        width: 14px;
    }

    span {
        margin-left: 5px;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.second-step-footer-margin {
    margin-top: -14px;
}


.form-control {
    padding: 0.498rem 0.75rem;
}

.login-main-section-div {
    height: 100%;
    background-color: #fff;
}

code-input#verificationCode {
    margin: 0 0 0;

    @media (max-width: 768px) {
        margin: 0 0 0px 19px;
    }
}

// .form-group-input[type=checkbox] {
//     border-radius: 0.25em;
// }

// .form-group .form-group-input {
//     float: left;
//     margin-left: -1.5em;
// }
// input[type=checkbox] {
//     width: auto;
//     height: auto;
// }
// .form-group-input {
//     width: 1em;
//     height: 1em;
//     margin-top: 0.25em;
//     vertical-align: top;
//     background-color: #fff;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     border: 1px solid rgba(0,0,0,.25);
//     -webkit-appearance: none;
//     appearance: none;
//     -webkit-print-color-adjust: exact;
//     print-color-adjust: exact;
// }

// .form-group label {
//     width: 100%;
//     margin-bottom: 20px;
//     padding: 10px 0;
//     color: #4C4C4C;
//     font-size: 15px;
//     text-align: center;
// }
.override-placement {
    position: relative;
    top: 2px;

    width: 82% !important;

    margin-bottom: 0px !important;
    margin-left: 0px !important;


    padding: 4px !important;
}

code-input {
    --item-spacing: 10px;
    --item-height: 3em;
    --item-border: none;
    --item-border-bottom: 2px solid #dddddd;
    --item-border-has-value: none;
    --item-border-bottom-has-value: 2px solid #888888;
    --item-border-focused: none;
    --item-border-bottom-focused: 2px solid #809070;
    --item-shadow-focused: none;
    --item-border-radius: 0px;
}

::ng-deep ngx-intl-tel-input .iti {
    width: 100% !important;
}

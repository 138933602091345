.customer-deatil-tabs {
    .tablist-section {
        background: #fff;
        border-radius: 5px 5px 0 0;
        .nav-tabs {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(5, 1fr);

            background: #f6f6f6;
            border: 0;
            .nav-link {
                position: relative;

                padding: 7px 10px;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                line-height: 26.4px;
                text-align: center;

                background: #f6f6f6;
                border: 2px solid #e2e2e2;
                border-bottom: 0;
                box-shadow: none;
                &:after {
                    position: absolute;
                    top: 17px;
                    right: 15px;

                    display: inline-block;
                    width: 10px;
                    height: 10px;

                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }
            .nav-link.active {
                position: relative;

                background: #fff;
                box-shadow: none;
                &:after {
                    top: 14px;

                    transform: rotate(180deg);
                }
                &:before {
                    position: absolute;
                    right: 0;
                    bottom: -5px;
                    left: 0;

                    display: block;
                    width: 100%;
                    height: 5px;

                    background: #fff;

                    content: "";
                }
            }
            li {
                &:first-child {
                    margin-left: 12px;
                }
            }
        }
        .tab-content {
            background: #0000;
            border: 2px solid #e2e2e2;
            box-shadow: none;
            .inner-detail-tab {
                ul {
                    padding: 30px 60px;

                    background: #fff;
                    li {
                        display: inline-block;
                        margin-right: 75px;
                        padding: 0 0 8px 0;

                        color: #000;
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: none;
                    }
                    li.current {
                        position: relative;

                        color: #dc1445;

                        background: transparent;
                        &:before {
                            position: absolute;
                            right: 0;
                            bottom: -1px;
                            left: 0;

                            display: inline-block;
                            width: 100%;
                            height: 2px;

                            background: #dc1445;

                            content: "";
                        }
                    }
                }
            }
            .detail-form-section {
                .display-uderline {
                    margin: 0 0 21px 0;
                    padding-bottom: 10px;

                    font-weight: bold;
                    font-size: 16px;

                    border-bottom: 1px solid #e9ecef;
                }
            }
        }
        .inner-tabel-tab {
            width: 95%;
            margin: 0 auto;
        }
    }
    .tab-table-section {
        .inner-tabel-tab {
            width: 98%;
        }
    }
    .detail-bank-section {
        margin-top: 30px;
        .form-group {
            /* stylelint-disable-next-line */
            label {
                margin-bottom: 15px;
                padding-left: 50px;
            }
        }
        /* stylelint-disable-next-line */
        form {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(2, 1fr);
            width: 70%;
            padding: 0 60px;
            .bottom-btn-section {
                display: flex;
                flex-wrap: wrap;
                grid-row: 5/5;
                grid-column: 1/3;

                justify-content: flex-end;
                button {
                    padding: 10px 45px;

                    color: #fff;
                    font-size: 12px;

                    border: 0;
                    border-radius: 30px;
                }
                .verify-btn {
                    button {
                        background: #3aaa35;
                    }
                }
                .update-btn {
                    button {
                        background: #706f6f;
                    }
                    margin-left: 15px;
                }
            }
            /* stylelint-disable-next-line */
            .update-btn {
                /* stylelint-disable-next-line */
                label {
                    margin-bottom: 15px;
                    padding-left: 50px;
                }
            }
        }
    }
    .inner-bank-tab {
        .top-header-section {
            padding: 30px 60px;

            background: #fff;
            h2 {
                position: relative;

                display: inline-block;

                color: #dc1445;
                font-weight: bold;
                font-size: 16px;
                &:after {
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;

                    display: inline-block;
                    width: 100%;
                    height: 2px;

                    background: #dc1445;

                    content: "";
                }
            }
        }
    }
}
.customer-deatil-tabs.profile-detail-page {
    height: 34vw;
    .tablist-section {
        .tab-content {
            .detail-form-section {
                width: 100%;
            }
            #tabs-1 {
                .detail-form-section {
                    form {
                        display: grid;
                        grid-gap: 20px 80px;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
            #tabs-3 {
                .detail-form-section {
                    form {
                        display: grid;
                        grid-gap: 20px 80px;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}
.customer-deatil-tabs.customer-deatil-tabs-form {
    .tablist-section {
        .tab-content {
            /* stylelint-disable-next-line */
            .detail-form-section {
                /* stylelint-disable-next-line */
                form {
                    .form-control {
                        height: 30px;
                        min-height: 30px;
                    }
                }
            }
            border: 0;
        }
    }
    .ng-select-container {
        height: 30px;
        min-height: 30px;
    }
}

div#tabs-1 {
    background: #fff;
}
div#tabs-2 {
    padding-bottom: 50px;

    background: #fff;
}
div#tabs-3 {
    background: #fff;
    @media (max-width: 991px) {
        padding: 0 20px 50px 20px !important;
    }
    .table-responsive {
        h2 {
            margin-bottom: 15px;

            color: #333333;
            font-size: 14px;
        }
    }
}
div#tabs-4 {
    padding-top: 50px;
    padding-bottom: 50px;

    background: #fff;
}
#tabs-2 {
    .multi-password {
        .form-control {
            width: 40px;
            height: 40px;

            text-align: center;

            border: 1px solid #ebebe9;
            border-radius: 0;
        }
        .common-pin-cls {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 10px;
            align-items: center;
            h2 {
                width: 100%;
                margin-bottom: 5px;

                color: #4f4f4f;
            }
        }
        .common-pin-cls.current-pin-sec {
            margin-bottom: 30px;
            padding-bottom: 30px;

            border-bottom: 1px solid #ebebe9;
        }
        .common-pin-cls.new-pin-sec {
            margin-bottom: 30px;
        }
    }
}
div#tab-4 {
    background: #fff;
}
.alert-warning {
    font-size: 12px;
}
/* stylelint-disable-next-line */
#tabs-3 {
    .third-tab-section {
        .third-inner-section {
            display: flex;
            grid-gap: 30px;
            width: 95%;
            margin-bottom: 300px;
            .language-select {
                width: 50%;
                select {
                    width: 100%;
                    height: 50px;
                    padding: 0 20px;

                    border: 1px solid #ebebe9;
                }
                h2 {
                    margin-bottom: 15px;

                    font-size: 14px;
                }
            }
            .currency-select {
                width: 50%;
                select {
                    width: 100%;
                    height: 50px;
                    padding: 0 20px;

                    border: 1px solid #ebebe9;
                }
                h2 {
                    margin-bottom: 15px;

                    font-size: 14px;
                }
            }
        }
    }
}
.header-table-section {
    .price-range-slider {
        position: relative;

        float: left;
        width: 100%;
        padding: 0 20px 6px 20px;
        .range-value {
            margin: 0;
            input {
                width: 100%;

                color: #000;
                font-weight: initial;
                font-size: 11px;

                background: none;
                border: none;
                box-shadow: none;
                .header-table-section {
                    .price-range-slider {
                        .range-bar {
                            .ui-slider-range {
                                background: #9d9d9c;
                            }
                        }
                    }
                }
            }
            input.amount-one-second-readonly {
                text-align: right;
            }
        }
        /* stylelint-disable-next-line */
        .range-bar {
            width: 96%;
            height: 3px;
            margin-left: 8px;

            background: #f6f6f6;
            border: none;
            .ui-slider-handle {
                top: -0.2em;

                width: 9px;
                height: 9px;

                background: #fff;
                border: 3px solid #9d9d9c;
                border-radius: 25px;
                cursor: pointer;
            }
            .ui-slider-handle + span {
                background: #fff;
            }
        }
    }
    /* stylelint-disable-next-line */
    ul {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1.7fr;
        align-items: flex-end;
        padding: 10px 20px;
        .filter-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            /* stylelint-disable-next-line */
            span {
                display: inline-block;
                padding-left: 15px;

                font-weight: bold;
            }
        }
        .select-one {
            text-align: center;
            /* stylelint-disable-next-line */
            select {
                width: 90%;
                margin: 0 auto;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
        }
        .select-two {
            text-align: center;
            /* stylelint-disable-next-line */
            select {
                width: 90%;
                margin: 0 auto;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
            /* stylelint-disable-next-line */
            input {
                width: 90%;
                margin: 0 auto;

                color: #d7d7d6;

                border: 1px solid #d7d7d6;
                border-radius: 3px;
            }
        }
        li.last-header-section {
            .button-group-header {
                display: flex;
                grid-gap: 20px;
                justify-content: flex-end;
                padding-right: 15px;
                /* stylelint-disable-next-line */
                button {
                    width: 140px;
                    padding: 8px 25px;

                    color: #fff;
                    font-size: 12px;

                    border: 0;
                    border-radius: 30px;
                }
                button.filter-sec {
                    width: initial;
                    padding: 8px 45px;

                    background: #191919;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                button.reset-sec {
                    &:hover {
                        opacity: 0.8;
                    }
                    width: initial;
                    padding: 8px 45px;

                    background: #706f6f;
                }
            }
        }
    }
}
.last-space-div-reduced-size {
    width: 3%;
}
.next-btn {
    cursor: pointer;
}
.end-btn {
    cursor: pointer;
}
.fa-icon {
    .fa-fw {
        margin-right: 7px;
    }
}
.ng-select {
    /* stylelint-disable-next-line */
    .ng-select-container {
        .ng-value-container {
            font-size: 12px;
        }
    }
}
.ng-select.size-sm {
    .ng-select-container {
        height: 31px !important;
        min-height: 31px;
    }
}

.searchArea{
    .form-group{
        input{
            width: 250px;
            height: 30px;
            padding: 5px 12px 6px;

            font-size: 12px;

            background-color: $White-clr;
            border: 2px solid $msgSearchBorder !important;
            
            border-radius: 0;

            &:focus-visible {
                border: 2px solid $msgSearchBorderHover !important;
                outline: 0;
            }
        }
    } 
} 
.platinumSection {
    .table.table-striped  tbody > tr:last-child {
        border-bottom: none;
    }

    .ng-select.ng-select-single .ng-select-container{
        height: 25px;
    }
    .cashbackMonths.ng-select{
        .ng-dropdown-panel .ng-option-label {
            font-weight: 600 !important;
        }
    }
}

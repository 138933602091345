.amexDetails{
    .tool{
        position: relative;
        top: -14px;

        display: inline-block;
        margin: 0 5px;

        .tooltipText{
            position: absolute;
            top: 0;
            right: 0;
            left: 22px;
            z-index: 1;

            width: 325px;
            padding: 13px 19px;

            color: $whiteColor;
            font-size: $textSizeNormal;
            line-height: 17px;
            text-align: center;

            background-color: $primaryColor;
            border: 1px solid $primaryColor;
            border-radius: 8px;
            transform: translateY(-20px);
            visibility: hidden;
            opacity: 0;

            transition: visibility 0s, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
            animation: animSlideIn 0.1s ease-in-out;
        }

        &:hover {
            .tooltipText {
                @include tooltipHover;
            }
        }

    }



    .amexQuestionAnswers {
        display: flex;
        justify-content: space-around;
        width: 12%;
    }
}

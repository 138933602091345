/*
    * Animation
    */

.animationFadeIn{
    animation-name: animFadeIn;
    animation-duration: 0s;
    animation-fill-mode: forwards;
}

.animationFadeOut{
    animation-name: animFadeOut;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes ball-pulse {

    0%,
    60%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);

        opacity: 1;
    }

    30% {
        -webkit-transform: scale(.01);
        transform: scale(.01);

        opacity: .1;
    }
}

@-moz-keyframes ball-pulse {

    0%,
    60%,
    100% {
        -moz-transform: scale(1);
        transform: scale(1);

        opacity: 1;
    }

    30% {
        -moz-transform: scale(.01);
        transform: scale(.01);
        opacity: .1;
    }
}

@-o-keyframes ball-pulse {

    0%,
    60%,
    100% {
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    30% {
        -o-transform: scale(.01);
        transform: scale(.01);
        opacity: .1;
    }
}

@keyframes ball-pulse {

    0%,
    60%,
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(.01);
        -moz-transform: scale(.01);
        -o-transform: scale(.01);
        transform: scale(.01);
        opacity: .1;
    }
}

@-webkit-keyframes animFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0
    }
}

@keyframes animFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes animFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1
    }
}

@keyframes animFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1
    }
}

@keyframes animZoomIn {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1)
    }
}

@keyframes animZoomOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0)
    }
}

@keyframes animSlideIn {
    0% {
        height: 0
    }

    100% {
        height: auto
    }
}

@keyframes animSlideOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0)
    }
}

@keyframes animSlideOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0)
    }
}

@keyframes animSlideInRight {
    0% {
        transform:translateX(($alertBoxSize+40)+px)
    }

    100% {
        transform:translateX(0)
    }
}

@keyframes animSlideOutRight {
    0% {
        transform:translateX(0)
    }

    100% {
        transform:translateX(($alertBoxSize+40)+px)
    }
}

.new-message {
    h1 {
        margin-bottom: 32px;
        padding: 42px 0 12px 0;

        font-size: 18px;

        font-family: "Roboto", sans-serif;

        border-bottom: 1px solid #dfdfdf;
    }
}

.pay-by-link {
    margin-top: 55px;
    margin-bottom: 40px;

    .label {
        margin-bottom: 10px;
    }

    textarea {
        height: 120px;
    }

    svg {
        margin-left: 25px;
    }
}

.paybylink-container {
    width: 90%;
    margin: 0 auto;
}

.customer-deatil-tabs {
    margin-top: 0;

    .tablist-section {
        background: #fff;
        border-radius: 5px 5px 0 0;

        .nav-tabs {
            display: grid;
            grid-gap: 7px;
            grid-template-columns: repeat(6, 1fr);

            background: #f6f6f6;
            border: 0;

            .nav-link {
                position: relative;

                padding: 7px 10px;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                line-height: 26.4px;
                text-align: center;

                background: #f6f6f6;
                border: $standardBorderWidth solid $borderColor;
                box-shadow: none;

                cursor: pointer;

                &:after {
                    position: absolute;
                    top: 22px;
                    right: 15px;

                    display: none;
                    width: 10px;
                    height: 7px;

                    background: url(../../img/arrow-top.png);
                    background-repeat: no-repeat;
                    background-size: contain;

                    content: "";
                }
            }

            .nav-link.active {
                position: relative;

                background: #fff;
                border-bottom: 0;
                box-shadow: -3px -4px 5px -4px #c1c1c1;
                box-shadow: none;

                &:after {
                    top: 22px;

                    transform: rotate(180deg);
                }
            }

            li {
                &:first-child {
                    margin-left: 0px;
                }
            }
        }

        .tab-content {
            background: #f6f6f6;
            background: transparent;
            border: 2px solid #e2e2e2;
            box-shadow: 1px 11px 10px -3px #c1c1c2;
            box-shadow: none;

            .inner-detail-tab {
                ul {
                    padding: 30px 60px;

                    background: #fff;

                    li {
                        display: inline-block;
                        margin-right: 75px;
                        padding: 0 0 8px 0;

                        color: #000;
                        font-weight: bold;
                        font-size: 16px;
                        text-decoration: none;
                    }

                    li.current {
                        position: relative;

                        color: #dc1445;

                        background: transparent;

                        &:before {
                            position: absolute;
                            right: 0;
                            bottom: -1px;
                            left: 0;

                            display: inline-block;
                            width: 100%;
                            height: 2px;

                            background: #dc1445;

                            content: "";
                        }
                    }
                }
            }
        }

        .widthmin {
            width: 100% !important;
        }
    }

    .detail-bank-section {
        margin-top: 30px;

        .form-group {

            /* stylelint-disable-next-line */
            label {
                margin-bottom: 0;
                padding-left: 20px;

                font-size: 14px;
            }
        }

        /* stylelint-disable-next-line */
        form {
            display: grid;
            grid-gap: 20px 50px;
            grid-template-columns: repeat(2, 1fr);
            width: 70%;
            padding: 0 60px;

            .bottom-btn-section {
                display: flex;
                flex-wrap: wrap;
                grid-row: 5/5;
                grid-column: 1/3;

                justify-content: flex-end;

                button {
                    padding: 10px 45px;

                    color: #fff;
                    font-size: 12px;

                    border: 0;
                    border-radius: 30px;
                }

                .verify-btn {
                    button {
                        background: #3aaa35;
                    }
                }

                .update-btn {
                    button {
                        background: #706f6f;
                    }

                    margin-left: 15px;
                }
            }

            /* stylelint-disable-next-line */
            .update-btn {

                /* stylelint-disable-next-line */
                label {
                    margin-bottom: 15px;
                    padding-left: 50px;
                }
            }
        }
    }

    .inner-bank-tab {
        .top-header-section {
            padding: 30px 60px;

            background: #fff;

            h2 {
                position: relative;

                display: inline-block;

                color: #dc1445;
                font-weight: bold;
                font-size: 16px;

                &:after {
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;

                    display: inline-block;
                    width: 100%;
                    height: 2px;

                    background: #dc1445;

                    content: "";
                }
            }
        }
    }

    .pay_section_table {
        border: 1px solid #e2e2e2;
    }

    .new-design-table-sec {
        .inner-tabel-tab {
            padding-top: 0;
        }
    }
}

.account-summary-section {
    .top-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .right-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            h2 {
                color: #4c4c4c;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }

            span {
                display: inline-block;
                padding-top: 10px;
                padding-left: 22px;

                color: #706f6f;
                font-size: 11px;
                line-height: 18.64px;
            }
        }

        .left-export {
            position: relative;

            /* stylelint-disable-next-line */
            button {
                padding: 2px 18px;

                color: #4c4c4c;
                font-weight: 400;
                font-size: 11px;
                line-height: 20.12px;

                background: linear-gradient(to bottom, #ffffff 0%, #dbdbdb 100%);
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                box-shadow: 1px 1px 5px -2px #888888;

                /* stylelint-disable-next-line */
                &:hover {
                    color: #0491df;
                }
            }

            span {
                display: inline-flex;
                margin-right: 20px;

                color: #8f8f8f;
                font-size: 14px;
            }
        }

        .toggle-btn-option {
            position: absolute;
            top: 50px;
            right: 0;

            width: 150px;

            background: #fff;
            box-shadow: 1px 1px 5px -2px #888888;

            /* stylelint-disable-next-line */
            ul {

                /* stylelint-disable-next-line */
                li {
                    padding: 10px 20px;

                    font-size: 13px;

                    border-bottom: 2px solid #e4e4e4;

                    a {
                        color: $primaryColor;
                        font-size: 15.4px;
                        line-height: 18.48px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.bottom-list-section {
    &.default {

        /* stylelint-disable-next-line */
        ul {

            /* stylelint-disable-next-line */
            li {
                width: 18% !important;
            }
        }
    }

    /* stylelint-disable-next-line */
    ul {
        display: flex;
        align-items: flex-end;

        padding-left: 75px;

        /* stylelint-disable-next-line */
        li {
            width: 22%;
            padding: 0 22px;

            border-right: 2px solid #e4e4e4;

            /* stylelint-disable-next-line */
            /* stylelint-disable-next-line */
            a {
                padding-left: 8px;

                color: $primaryColor;
                font-size: 12px;
                line-height: 21.12px;
                text-decoration: none;
            }

            /* stylelint-disable-next-line */
            &:last-child {
                border-right: 0;
            }

            /* stylelint-disable-next-line */
            &:first-child {
                .price-sec {
                    padding-left: 0;
                }
            }

            /* stylelint-disable-next-line */
            .price-sec {
                padding: 0 10px;

                .price {
                    display: inline-block;
                    margin-bottom: 5px;

                    color: $primaryColor;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                }

                .price-label {
                    color: #4c4c4c;
                    font-size: 12.5px;
                    line-height: 21.12px;
                }
            }

            /* stylelint-disable-next-line */
            &:nth-child(1) {
                padding-left: 0;
            }
        }
    }

    margin-bottom: 50px;
    padding-top: 20px;
}

.new-design-table-sec.customer-deatil-tabs {
    .tablist-section {
        .nav-tabs {
            .nav-link {
                padding: 7px 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: 12px;
                font-family: "Roboto", sans-serif;
                line-height: 26.4px;

                background: #f6f6f6;
                border: 2px solid #e2e2e2;
                border-bottom: 0;
                box-shadow: none;
            }

            .nav-link.active {
                position: relative;

                background: #fff;

                &:before {
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    left: 0;

                    display: block;
                    width: 100%;
                    height: 4px;

                    background: #fff;

                    content: "";
                }
            }

            /* stylelint-disable-next-line */
            li {
                &:first-child {
                    margin-left: 14px;
                }
            }
        }

        .tab-content {
            background: transparent;
            border: 2px solid #e2e2e2;
            box-shadow: none;
        }
    }

    .tab-table-section {
        .inner-tabel-tab {
            width: 100%;
            padding-top: 0;

            form {
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}

.commission-div {
    border: 1px solid #e2e2e2;
}

.transaction-section {
    .format {
        height: 42px;
        margin-bottom: 38px;

        background-color: #f3f3f3;

        .inline-block {
            display: inline-block;
            padding: 0 5px;
        }

        /* stylelint-disable-next-line */
        ul {
            min-height: 55px;
            max-height: 55px;
            padding-left: 10px;

            /* stylelint-disable-next-line */
            li:not(:last-child) {
                padding: 0 10px;

                border-right: 1px solid #a0a0a0;
            }

            span.inline-block {
                margin: 12px 10px 0px 10px;
            }
        }
    }
}

.tabel-section {
    .customer-product-container {
        max-width: 80% !important;
    }
}

.message-create-section {
    .container {
        max-width: 100%;
        padding: 0 40px;
    }

    .format {
        height: 42px;

        margin-bottom: 38px;

        background-color: #f3f3f3;

        /* stylelint-disable-next-line */
        .inline-block {
            display: inline-block;
            margin: 0 15px;

            cursor: pointer;
        }

        .underline {
            height: 1.7rem;
            padding-top: 0px;
        }

        /* stylelint-disable-next-line */
        ul {
            min-height: 55px;
            max-height: 55px;
            padding-left: 0px;

            /* stylelint-disable-next-line */
            li:not(:last-child) {
                padding: 0 10px;

                border-right: 1px solid #a0a0a0;
            }

            span.inline-block {
                margin: 15px 10px 0px 10px;

                cursor: pointer;
            }

            svg {
                margin-bottom: 5px;
            }

            input[type="text"] {
                width: 76px;

                margin-left: 10px;

                border: 1px solid #d7d7d6;
            }

            select.form-select {
                width: 170px;

                border: 1px solid #d7d7d6;
            }

            &::placeholder {
                color: #828282;
            }

            option:first-child {
                color: #828282;
            }
        }
    }

    .form-group.create-message {
        padding-bottom: 45px;
    }
}

/* stylelint-disable-next-line */
.bottom-list-section {
    margin-bottom: $tabPanelSpacing;
    padding-top: $standardSpacing;
}



.top-trans-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    .dateHeader {
        display: flex;
        align-items: flex-end;
    }

    .link-left-section {
        width: 22% !important;
    }

    .link-mid-section {
        width: 52%;
    }

    .rightSection {
        position: relative;

        width: 25%;

        text-align: right;

        .drop-down-sec {
            position: absolute;
            top: 35px;
            right: 0;
            z-index: 999;

            width: 180px;

            text-align: left;

            background: $White-clr;
            box-shadow: 1px 1px 5px -2px #888;

            /* stylelint-disable-next-line */
            ul {
                display: block;
                width: 0;
                padding-left: 0;

                /* stylelint-disable-next-line */
                li {
                    width: 180px;
                    padding: 5px 20px;

                    font-size: 13px;

                    border-right: none !important;
                    border-bottom: 2px solid #e4e4e4;

                    a {
                        padding-left: 8px;

                        color: $primaryColor;
                        font-size: 12px;
                        line-height: 21.12px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    /* stylelint-disable-next-line */
    .back-btn {
        margin-left: 2vw;
        padding: 2px 18px;

        color: #fff;
        font-weight: 400;
        font-size: 11px;
        line-height: 20.12px;
        text-transform: capitalize;

        background: #307aff;
        border: 1px solid #307aff;
        border-radius: 5px;
        box-shadow: none;

        /* stylelint-disable-next-line */
        &:hover {
            background: #2a6cdd;
        }
    }

    .adjust-btn {
        padding: 5px 22px;

        color: #fff;
        font-weight: 400;
        font-size: 13px;
        line-height: 20.12px;
        text-transform: capitalize;

        background: #307aff;
        border: 1px solid #307aff;
        border-radius: 5px;
        box-shadow: none;

        /* stylelint-disable-next-line */
        &:hover {
            background: #2a6cdd;
        }
    }

    .left-section {
        width: 100%;

        /* stylelint-disable-next-line */
        h2 {
            display: inline-block;

            color: #4c4c4c;
            font-weight: 500;
            font-size: 12.5px;
            line-height: 23.76px;
        }

        /* stylelint-disable-next-line */
        span {
            display: inline-block;
            margin-top: 8px;

            color: #4c4c4c;
            font-weight: 400;
            font-size: 12px;
            line-height: 23.76px;
        }
    }

    .mid-section {
        width: 40%;
    }

    .form-section-toggle {
        width: 100%;

        padding: $standardSpacing;

        border: $standardBorderWidth solid $borderColor;
    }

    .form-sec {
        position: relative;

        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 2fr 0.4fr;

        &:nth-child(2) {
            .first-input {
                display: flex;
                align-items: center;

                input {
                    position: relative;

                    width: 100%;
                    margin: 0;
                }

                /* stylelint-disable-next-line */
                label {
                    // margin: 0 10px 0 10px !important;

                    font-size: 12px;
                }
            }
        }

        .minmaxSection {
            display: flex;
            flex-direction: row;
            width: 100%;

            .minmaxInput {
                display: flex;
                flex-direction: column;
                width: 80px;
            }
        }

        /* stylelint-disable-next-line */
        input {
            width: 100%;
            height: 33px;
            padding: 5px 5px;

            color: $Grey2-clr;
            font-size: 11px;
            line-height: 21.12px;

            border: $standardBorderWidth solid $borderColor;

            /* stylelint-disable-next-line */
            &:focus-visible {
                border: $standardBorderWidth solid #ebebea;
                outline: 0;
            }

            &::placeholder {
                color: #9c9898;
            }

            &.checkboxDefault {
                width: $defaultCheckboxWidth;
                height: $defaultCheckboxHeight;
            }
        }

        label.filter-label {
            margin-bottom: 5px;

            color: $headerTextColor;
            font-weight: 500;
            font-size: 11px;
        }

        label.label-align {
            margin-left: 12px;
        }

        .ng-select-container {
            width: 100%;
            height: 33px;
            min-height: auto;

            padding: 0;

            color: #b5b5b5;
            font-size: 12px;

            border: $standardBorderWidth solid #dfdfdf;

            /* stylelint-disable-next-line */
            input {
                height: auto;

                &::placeholder {
                    font-size: 11px;
                    line-height: 21.12px;
                }
            }

            .ng-value-container {
                .ng-value {
                    color: $Grey2-clr;
                }
            }
        }

        .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
            font-size: 11px;
        }

        .ng-select .ng-clear-wrapper .ng-clear {
            font-size: 15px;
        }

        .clear-btn {
            position: absolute;
            top: 18px;
            right: 0;

            .clear_btns {
                width: 100px;
                height: 30px;

                line-height: 25px;
            }

            .cancel_btns {
                position: absolute;
                top: -15px;
                right: 0;

                margin: 0;
                padding: 0;

                background: transparent !important;
                border: 0 !important;
            }
        }
    }

    .bottom-form-sec {
        margin-top: $standardSpacing;

        background: #ffffff;
    }

    .btn-sec-all {
        display: flex;
        grid-gap: 10px;
    }
}

.settlement_top_sec {
    .settlement_title_sec {
        display: flex;
        grid-gap: 50px;
        margin-top: 20px;
        margin-bottom: 25px;

        /* stylelint-disable-next-line */
        h2 {
            margin-bottom: 10px;

            font-weight: 500;
            font-size: 14px;
            line-height: 23.76px;
        }

        p {
            font-size: 13px;
            line-height: 23.76px;
        }
    }

    .settlement_bottom_sec {
        .settle_table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 10px 0;

                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 10px 0;

                        font-size: 12px;
                        line-height: 14px;

                        span.time {
                            margin-left: 20px;
                        }
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 34.32px;
                    }

                    td.border_line {
                        width: 15%;
                        padding: 10px 0;

                        text-align: right;

                        border-top: 2px solid #dfdfdf;
                        border-bottom: 2px solid #dfdfdf;
                    }
                }
            }
        }
    }
}

.tabel-section.first-table-sec {

    /* stylelint-disable-next-line */
    .inner-tabel-tab {
        .table-striped {
            tbody {
                tr {
                    background: transparent !important;
                }
            }
        }

        .table {
            th {
                position: relative;

                padding-top: 20px;
                padding-bottom: 0;

                color: #4c4c4c;
                font-weight: 400;
                font-size: 12.5px;
                text-align: center;

                border: 0;

                &:before {
                    position: absolute;
                    top: 38%;
                    right: 0;

                    display: block;
                    width: 2px;
                    height: 40px;

                    background: #e5e5e4;

                    content: "";
                }

                &:nth-child(3) {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        padding-bottom: 0;
    }
}

.customer-products-div-sec {
    .first-table-sec {
        .table.table-striped {
            background: linear-gradient(to bottom, #fafafa 0%, #ebebeb 100%);
            border: 1px solid #ebebeb;
        }
    }

    .new-design-table-sec {
        .tabel-section {
            margin-top: 0 !important;
        }
    }

    /* stylelint-disable-next-line */
    .tabel-section {

        /* stylelint-disable-next-line */
        .inner-tabel-tab {
            border-radius: 0;

            .form-group {
                margin-right: 23px;

                text-align: right;

                .positive-btn {
                    float: right;
                    margin-top: 2px;
                    margin-bottom: 20px;

                    padding: 4px 30px;

                    color: #000;
                    font-weight: 400;
                    font-size: inherit;
                    line-height: 20.12px;
                    text-transform: capitalize;
                    /* stylelint-disable-next-line */
                    color: #fff !important;

                    background: #307aff !important;

                    border: 1px solid #307aff !important;

                    border-radius: 5px;

                    box-shadow: none !important;
                }
            }
        }
    }

    .tabel-section.second-table-div-sec {
        .inner-tabel-tab {
            border-right: 1px solid #dfdfdf;
            border-bottom: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
        }
    }

    .tabel-section.first-table-sec {
        .inner-tabel-tab {
            border-top: 1px solid #dfdfdf;
            border-right: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
        }
    }
}

.calendarIconArea {
    width: 10%;
}

.clickable {
    cursor: pointer;
}

.filterFlexColumn {
    display: flex;
    flex-direction: column;
}

.daterangeAdjustment {
    margin-top: -4px;
}

.copyIconRef {
    width: 190px;
}

.table-responsive {
    overflow: hidden;

    &.defaultMinHeight {
        min-height: 300px;
    }
}

.customMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 251px;
    padding: 10px;

    background-color: #fff;

    border-radius: 5px;

}

.menuButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px;

    background: none;
    border: none;
    border-bottom: 1px solid #ccc;

    cursor: pointer;
}

.menuButton:last-child {
    border-bottom: none;
}

.menuLabel {
    padding-right: 10px;

    color: #4c4c4c;
    font-size: 12px;

}

.menu-checkbox {
    margin-left: auto;
}

.btnBorder {
    border-bottom: 1px solid #ccc;
}

.custom-menu .menu-button:hover {
    color: #000;

    background-color: #f3f3f3;

}

.btnCloseMenu {
    margin-top: -8px;
    margin-left: -12px;

    color: #0d6efd;

    background-color: transparent;
    border: none;
}


.dateOrdinal {
    margin-right: 5px;

    color: #307aff;

    font-weight: 500;
    font-size: 14px;

    cursor: pointer;

}

.inputFieldvisibility {
    visibility: hidden;
    opacity: 0;
    height: 0 !important;
    padding: 0 !important;

}



.popupContainer {
    position: absolute;
    top: 0;

    z-index: 1000;

    padding: 9px;


    background-color: white;


    border-radius: 10px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popupContainerVterminal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    padding: 9px;

    background-color: $whiteColor;

    border-radius: 10px;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translate(-98%, 9%);
}




.textDecoration{
    text-decoration: none;
}

// These color codes are for transactions and commissions sections to change status colors

.commission-1{
    color: $primaryColor !important;
}
.commission-2{
    color: $amberColor !important;
}
.commission-3{
    color: $successColor !important;
}
.commissionAvailableOn{
    color: $grayColor !important;
}
.transaction-3{
    color: $grayColor !important;
}
.transaction-4{
    color: $successColor !important;
}
.transaction-5{
    color: $errorColor !important;
}

.transactionMargin{
    margin-top: -2px;
}

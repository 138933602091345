@for $i from 10 through 50 {
    .textSize-#{$i}{
        font-size: #{$i}+px;
    }
}

.colorPrimary {
    color: $primaryColor;
}

.fieldColorPrimary {
    color: $blackColor;
}

.textNormal {
    font-size: $textSizeNormal;
}
.onBoardText{
    width: 500px;

    font-size: 14px;

    line-height: 20px;
}
.textBig {
    font-size: $bigFontSize;
}

.textTitle {
    font-size: $titleFontSize;
}

.textRegular {
    font-size: $regularFontSize !important;
}

.textExtraSmall {
    font-size: 10px;
    line-height: 15px;
}

.textPrimary {
    color: $primaryColor;
}

.textError {
    color: $invalidFeedback;
}

.textLink {
    color: $primaryColor;

    &:hover {
        text-decoration: underline;
    }
}

.textPrimaryI {
    color: $primaryColor !important;
}

.textCharcoal {
    color: $headerTextColor;
}

.title1 {
    font-weight: 600;
    font-size: $titleText1;
}

.title2 {
    font-weight: 600;
    font-size: $titleText2;
}

.title3 {
    font-weight: 600;
    font-size: $titleText3;
}

.textCapitalize {
    text-transform: capitilize
}

.textBody1 {
    font-weight: normal;
    font-size: $textSizeNormal;
}

.textBody1WidthSpacing {
    font-weight: normal;
    font-size: $textSizeNormal;
    line-height: $textLineHeightNormal;
}

.textBody2 {
    font-weight: normal;
    font-size: $textSizeMedium;
}

.textStrongNormal{
    font-weight: normal !important;
}
.textBody3 {
    font-weight: normal;
    font-size: $textSizeLarge;
}

.textStrong {
    font-weight: 700;
}

.text500 {
    font-weight: 500;
}

.text500I {
    font-weight: 500 !important;
}

.textStrongI {
    font-weight: 700 !important;
}
.text400{
    font-weight:400;
}

.textUppercase {
    text-transform: uppercase;

    &::-webkit-input-placeholder {
        text-transform: initial;
    }

    &:-moz-placeholder {
        text-transform: initial;
    }

    &::-moz-placeholder {
        text-transform: initial;
    }

    &:-ms-input-placeholder {
        text-transform: initial;
    }
}

.application_btn-font-setting {
    a {
        font-size: 11px;
    }
}

.header {
    &.headerPrimary {
        color: $whiteColor;

        background: $primaryColor;

        a {
            color: $whiteColor;
            font-weight: 400;
        }

        &.light {
            font-weight: 400;
        }

        &.normal {
            font-weight: 600;
        }
    }
}

// Align text
.textRight {
    text-align: right;
}

.textRightI {
    text-align: right !important;
}

.textCenter {
    text-align: center;
}

.textCenterI {
    text-align: center !important;
}

.textLeft {
    text-align: left;
}

.textLeftI {
    text-align: left !important;
}

.textTransformInherit {
    text-transform: inherit;
}

.textTransformInheritI {
    text-transform: inherit !important;
}

.textNoWrap {
    white-space: nowrap;
}

.txtResize {
    resize: none;
}
.textFloatRight{
    float: right;
}


.commissionDetailPage {
    .headerSection {

        .transection_price {
            display: inline-block;
            margin-bottom: 5px;

            color: $blue-clr;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
        }

        .settlement_title_sec {
            display: flex;
            justify-content: space-between;
            margin-top: $mainContentSpacing;
            margin-bottom: $standardSpacing;
            margin-left: 8px;

            .settle {
                display: flex;
                justify-content: space-between;
                width: 32%;
            }

            .rightSection {
                position: relative;
                /* stylelint-disable-next-line */
                button {
                    padding: 2px 18px;

                    color: $White-clr;
                    font-size: 11px;
                    line-height: 20.12px;
                    text-transform: capitalize;

                    background: $blue-clr;
                    border: 1px solid $blue-clr;
                    border-radius: 5px;
                    box-shadow: none;
                    /* stylelint-disable-next-line */
                    &:hover {
                        background: #2a6cdd;
                    }
                }

                .drop-down-sec {
                    position: absolute;
                    top: 35px;
                    right: 0;
                    z-index: 999;

                    width: 208px;

                    background: $White-clr;
                    box-shadow: 1px 1px 5px -2px #888;
                    /* stylelint-disable-next-line */
                    ul {
                        display: block;
                        width: 0;
                        padding-left: 0;
                        /* stylelint-disable-next-line */
                        li {
                            width: 208px;
                            padding: 5px 20px;

                            font-size: 13px;

                            border-right: none !important;
                            border-bottom: 2px solid #e4e4e4;

                            span {
                                padding-left: 8px;

                                font-size: 11px;
                                line-height: 21.12px;
                                text-decoration: none;
                            }

                            a {
                                padding-left: 8px;

                                color: #0078c8;
                                font-size: 11px;
                                line-height: 21.12px;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            /* stylelint-disable-next-line */
            h2 {
                margin-bottom: 10px;

                font-weight: 500;
                font-size: 14px;
                line-height: 23.76px;
            }

            p {
                font-size: 13px;
                line-height: 23.76px;
            }
        }

        .settlement_middle_sec {
            padding-bottom: $standardSpacing;

            border-bottom: 1px solid #dfdfdf;

            .settle_table {
                width: 100%;

                thead {
                    tr {
                        th {
                            padding: 10px 0;

                            color: $colorGray;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;

                            &:nth-child(1) {
                                width: 20%;
                            }

                            &:nth-child(2) {
                                width: 18%;
                            }

                            &:nth-child(3) {
                                width: 16%;
                            }

                            &:nth-child(4) {
                                width: 17%;
                            }

                            &:nth-child(5) {
                                width: 12%;
                            }

                            &:nth-child(6) {
                                width: 12%;

                                font-weight: 600;
                            }

                            &:nth-child(7) {
                                width: 8%;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 10px 0;

                            font-size: 12px;
                            line-height: 14px;

                            span.time {
                                margin-left: 20px;
                            }

                            .settle_price {
                                margin-left: 22px;
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 34.32px;
                        }

                        td.border_line {
                            width: 15%;
                            padding: 10px 0;

                            text-align: right;

                            border-top: 2px solid #dfdfdf;
                            border-bottom: 2px solid #dfdfdf;
                        }
                    }
                }
            }
        }
    }

    .footerSection {
        margin-top: $standardSpacing;
        margin-bottom: $standardSpacing;
        padding-bottom: $standardSpacing;
    }

    .minResidualHeight {
        min-height: 800px;
    }

    .displayUnderline {
        margin: 0;
        padding-bottom: 0;

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        border-bottom: 0;
    }
}

.datePickerSearch {
    right: 5px;

    margin-top: -4px;

}

.download-btn {
    background: transparent !important;
    border: 1px solid transparent !important;
    box-shadow: none !important;
}


.nav-link-tab {
    padding: .8rem 3rem !important;
}
.tab-color{
    background: #307aff !important;
    color: #fff !important;
}

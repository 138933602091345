textarea:focus-visible,
input:focus-visible {
    outline: 1px solid #307aff;
}

select:focus-visible {
    outline: 1px solid #307aff;
}

.offerField {
    input:focus-visible {
        outline: 1px solid #307aff !important;
    }
}

div#tabs-2 {
    .mcc-container {
        .row {
            .left-section {
                margin: 0;
                padding: 30px 44px;

                color: #7c7c7c;
                font-size: 15px;

                line-height: 29px;

                border: 1px solid $primaryColor;

                @media (max-width: 991px) {
                    margin: 20px;
                }

                h1 {
                    font-weight: 600;
                    font-size: $titleFontSize;

                    line-height: 29px;
                }

                p {
                    margin: 15px 0;
                }
            }

            .rightSection {
                margin: 0;

                .trunover_main {
                    margin-left: 20px;
                }

                .ng-select {
                    .ng-arrow-wrapper {
                        top: -5px;

                        .ng-arrow {
                            display: inline-block;
                            padding: 4px;

                            border: solid black;
                            border-width: 0 2px 2px 0;

                            transform: rotate(45deg);
                        }
                    }

                    &.is-invalid {
                        .ng-select-container {
                            border-color: $errorColor !important;
                        }

                        .ng-arrow-wrapper {
                            .ng-arrow {
                                border-color: $errorColor;
                            }
                        }
                    }

                    &.mccSearch {
                        .ng-arrow-wrapper {
                            right: 10px;
                        }
                    }
                }

                .turnover-main-container {
                    margin-right: 0;

                    p {
                        color: $grayColor;
                        font-size: 12px;
                    }

                    label.estimated-turnover-css {
                        margin-top: 25px;
                    }

                    label.estimated-atv {
                        margin-top: 60px;
                    }

                    label.mt-25 {
                        margin-top: 25px;
                    }

                    .mccDescription {
                        position: relative;

                        .descriptionOfGoodIndicator {
                            position: absolute;
                            right: 0;
                            bottom: -16px;

                            color: #4C4C4C;
                            font-size: 12px;

                        }
                    }

                    .estimatedAtvSection {
                        display: flex;
                        justify-content: space-between;

                        .estimatedAtvItem {
                            width: 48%;
                        }
                    }

                    .estimate {
                        position: relative;

                        height: 0;

                        /* stylelint-disable-next-line */
                        input {
                            height: 0;
                            padding: 15px 100px 15px 15px;

                            color: $blackColor;

                            border: none;

                            &.is-invalid {
                                border-color: $errorColor;

                                + .ng-select {
                                    .ng-arrow {
                                        border-color: $errorColor;
                                    }
                                }
                            }

                            &[type="number"],
                            &.currency-mask {
                                border: 1px solid #d7d7d6;
                            }
                        }

                        /* stylelint-disable-next-line */
                        .ng-select {
                            position: absolute;
                            top: 2px;
                            right: 5px;
                            bottom: -40px;

                            border-left: 1px solid #ccc;

                            /* stylelint-disable-next-line */
                            .ng-select-container {
                                position: relative;
                                top: 0px;

                                width: 100px;
                                height: 34px;
                                min-height: 33px;
                                overflow: visible;

                                background: #fff;
                                border: none;
                                border-radius: 0;

                                &:hover {
                                    box-shadow: none;
                                }

                                .ng-arrow-wrapper {
                                    top: -2px !important;
                                    right: 3px;
                                }
                            }

                            /* stylelint-disable-next-line */
                            span[title="Clear all"] {
                                position: absolute;
                                top: 8px;
                                right: -28px;
                            }

                            input {
                                // height: 34px;
                                margin: 0;
                            }
                        }

                        /* stylelint-disable-next-line */
                        select {
                            width: 30%;
                            height: 32px;
                            margin: 7px 13px;
                            padding: 0 42px;

                            background: #ededed;

                            border: none;

                            @media (max-width: 1500px) {
                                padding: 0;
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line */
                ng-select#select-mcc .ng-select-container {
                    min-height: 41px !important;
                    max-height: 41px !important;

                    border-top: none;
                    border-right: none;
                    border-bottom: 1px solid #ccc;
                    border-left: none;
                }
            }


            /* stylelint-disable-next-line */
            .rightSectionMerchantOffers {
                width: 100%;

                color: $headerTextColor;

                /* stylelint-disable-next-line */
                p {
                    font-size: $textSizeNormal;
                }

                label {
                    font-size: $textSizeSmall;
                }

                .offerField {
                    position: relative;

                    min-width: 95%;
                    max-width: 400px;

                    .dropdownPanel{
                        padding-right: 48px;
                    }

                    .closeIcon {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    /* stylelint-disable-next-line */
                    input {
                        box-sizing: content-box;

                        width: 90%;

                        padding: 0;

                        font-size: $textSizeNormal;

                        background: none transparent;

                        border: 0;
                        border: 1px solid #cccccc;

                        outline: none;

                        box-shadow: none;

                        cursor: default;
                    }

                    .ng-select .ng-option-label {
                        font-size: 12px !important;
                    }

                    /* stylelint-disable-next-line */
                    ng-select {
                        position: absolute;
                        top: 0;
                        right: 0;

                        min-width: 60px;
                        max-width: 100px;

                        padding: 0;

                        outline: 0;

                        /* stylelint-disable-next-line */
                        .ng-select-container {
                            border-top: 1px solid #cccc;
                            border-right: 0 none;
                            border-bottom: 0 none;

                        }
                    }

                }


                .offerError {
                    margin-top: 10px;

                    color: #dc3545;

                    font-size: $textSizeNormal;
                }
            }
        }

        .bottom-btn {
            margin-right: -38px;
        }
    }

    .nav-tabs {
        padding: 35px 80px 25px 80px;

        background: #fff;

        li {
            margin-right: 30px;

            a {
                display: inline-block;
                padding-right: 0;
                padding-bottom: 10px;
                padding-left: 0;

                color: $primaryColor;
                font-weight: 500;
                font-size: $textSizeNormal;
                line-height: 26.3px;
            }

            a.active {
                border-bottom: 2px solid $primaryColor;
            }
        }
    }

    padding: 0;
}

.turnover-height {
    height: 40px !important;
}

.offerField input[type="text"] {
    padding-left: 10px !important;
}

.dropdown-panel .ng-dropdown-panel {
    width: 72px !important;
}


.bottom-section-footer {
    margin-top: 450px;
}

.business-hours-container {

    .day-row {
        display: flex;
        align-items: center;
    }

    .day-row.sunday {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .day-label {
        width: 80px;

        font-size: 12px;
    }


    .radio-container {
        position: relative;
        display: inline-block;
        margin-right: 12px;
        padding-left: 20px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        input[type="radio"] {
            position: absolute;
            cursor: pointer;
            opacity: 0;
        }

        .radio-custom {
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            height: 8px;
            border: 0 !important;
            border-radius: 50% !important;
            outline: 0.1em solid #7c7c7c;
            outline-offset: 2px;
            -webkit-appearance: none;

            font-size: $textSizeNormal;

            // This should come before the checked state rule to avoid specificity issues
            &::after {
                position: absolute;
                display: none;
                content: "";
                top: 3px;
                left: 3px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }

        input[type="radio"]:checked + .radio-custom {
            background-color: #307AFF;
            border-color: #307AFF;

            // Move this after the .radio-custom::after to fix specificity issue
            &::after {
                display: block;
            }
        }
    }

    .repeat-inline {
        display: inline-flex;
        align-items: center;
        width: 117px;
        .repeat-next-checkbox {
            width: 17px;
            height: 15px;
            padding: 0px;
        }
    }

    .time-inputs {
        display: flex;
        align-items: center;

        font-size: $textSizeNormal;

        input[type="time"] {
            width: 85px;
            margin: 0 10px;

            color: $charcoalColor;

        }
    }

    .closed-label {
        color: #999;
    }
}

.toggle-container {
    display: flex;
    align-items: center;
    margin-top: 13px;
}

.status-label {
    margin-left: 10px;
}


.modalContent {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;

    color: var(--bs-modal-color);

    background-clip: padding-box;
    border-radius: var(--bs-modal-border-radius);

    .modalHeader {
        display: flex;
        align-items: center;
        padding: var(--bs-modal-header-padding);

        color: $whiteColor;

        background-color: $primaryColor;
        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);

        .modalTitle {
            flex: 1;

            text-align: center;
        }
    }

    .modalBody {
        position: relative;

        flex: 1 1 auto;
        padding: var(--bs-modal-padding);
    }

    .modalFooter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);

        border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    }
}

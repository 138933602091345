.left-section {
    .headerGroupContent {
        display: flex;
        align-items: center;

        .appLoading {
            margin-left: 20px;
        }
    }
}

.drop-down-pop-up {
    @include posFix;
    z-index: 999999;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .overlay-pop-up {
        @include posFix;
        z-index: 999;

        width: 100%;
        height: 100%;

        background: rgb(0, 0, 0, 0.5);
    }
    .login-history-pop {
        position: relative;
        z-index: 99999;

        width: 660px;
        padding: 20px;

        background: $White-clr;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;

            cursor: pointer;
        }
        h3 {
            margin-bottom: 20px;

            color: $Black2-clr;
            font-size: 15px;
        }
        .table-pop-up {
            ul {
                border: 1px solid #ddd;
                li {
                    @include gridPort(repeat(2, 1fr));
                    padding: 10px;

                    color: $Black2-clr;

                    border-bottom: 1px solid #ccced2;
                    .left-section {
                        display: block;
                        width: 100%;
                    }
                    .rightSection {
                        display: block;
                        width: 100%;

                        text-align: right;
                    }
                    span {
                        margin: 0 !important;

                        color: $Black2-clr !important;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.breadcrumb-section {
    padding:0 $layoutBreakPointSpacing;

    background: #fff;
    border-bottom: 1px solid $Grey-clr;


    @include mediumDesktop {
        padding:0;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;

        /* stylelint-disable-next-line */
        .left-section {
            h3 {
                margin-bottom: 10px;

                font-weight: bold;
                font-size: 20px;
            }
            p {
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
            }
            h2 {
                padding-left: 0;

                color: $Grey2-clr;
                font-weight: 600;
                font-size: 18px;
                line-height: 31.68px;
                letter-spacing: 0.5px;
            }
            .breadcrumb {
                // margin: 8px 0px;
                padding: 0;

                font-size: 11px;

                background: transparent;
            }
            .breadcrumb-item {
                & + .breadcrumb-item {
                    &:before {
                        content: ">";
                    }
                }
                a {
                    color: $primaryColor;
                }
            }
            .breadcrumb-item.active {
                color: $Grey2-clr;
                font-weight: 400;
            }
        }
        /* stylelint-disable-next-line */
        .rightSection {
            .version-dash {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;

                // margin-top: -9px;

                color: #c4b7b6;
                font-size: 12px;
                select {
                    width: 180px;
                    padding: 5px 35px 5px 16px;

                    color: $Grey2-clr;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 22.3px;

                    background: transparent;
                    background: $Grey-clr;
                    border: 0;
                    border-radius: 4px;

                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    &:focus {
                        outline: 0;
                    }
                }
                .arrow-div {
                    position: relative;
                    &:after {
                        position: absolute;
                        top: 0;
                        top: 40%;
                        right: 15px;

                        display: inline-block;
                        width: 13px;
                        height: 13px;

                        background: $Grey-clr url(../../img/select-arrow.svg);
                        background-repeat: no-repeat;
                        background-size: 100%;

                        content: "";
                    }
                }
                span {
                    display: inline-block;
                    margin-left: 15px;

                    color: #9d9d9c;
                    font-size: 11px;
                    line-height: 22.3px;

                    // cursor: pointer;
                    svg {
                        path {
                            fill: #9d9d9c;
                        }
                    }
                    &:hover {
                        svg {
                            path {
                                fill: #e11455;
                            }
                        }
                    }
                }
                .top-right-head {
                    display: block;
                    width: 100%;
                    margin-bottom: 3px;

                    color: #4c4c4c;
                    font-size: 11px;
                    line-height: 14px;
                    text-align: right;
                    .about-pop-up {
                        margin: 0 !important;

                        color: #317AFF !important;

                        // cursor: pointer;
                        // &:hover {
                        //     text-decoration: underline;
                        // }
                    }
                }
            }
        }
    }
}

nav.breadcrumb-section.bg-clr-div {
    background: #f6f6f6;
    border: 0;
    .container {
        padding-top: 30px;
    }
}

.breadcrumb-section.transaction-align {
    /* stylelint-disable-next-line */
    .container {
        /* stylelint-disable-next-line */
        .left-section {
            h2 {
                padding-left: 0;
            }
        }
    }
}

nav.breadcrumb-section.group-user-div {
    /* stylelint-disable-next-line */
    .breadcrumb {
        display: flex !important;
        margin-top: 10px;
    }
}
/* stylelint-disable-next-line */
nav.breadcrumb-section.bg-clr-div {
    background: #f6f6f6;
    border: 0;
    /* stylelint-disable-next-line */
    .container {
        padding-top: 30px;
    }
}

.bottom-form-contain {
    label {
        margin-bottom: 10px;

        font-size: 12px;
    }
    padding: 50px 0;
    .bottom-line {
        width: 100%;
        height: 1px;
        margin-top: 10px;
        margin-right: 15px;
        margin-bottom: 20px;
        margin-left: 15px;

        background: #e3e3e3;
    }
}
.text-muted {
    font-size: 10px;
}
.btn-primary.disabled {
    color: #fff;
    font-size: 11px;

    background: #307AFF;
    border: 1px solid #307AFF;
    box-shadow: none;
    &:hover {
        background: #2A6CDD;
    }
}
.btn-primary {
    &:disabled {
        color: #fff;
        font-size: 11px;

        background: #307AFF;
        border: 1px solid #307AFF;
        box-shadow: none;
        &:hover {
            background: #2A6CDD;
        }
    }
}
.btn-danger {
    font-size: $textSizeNormal;
}

.crBusinessContactContainer{
    .limitedCompanySection{
        .officerItem{
            position:relative;
        }

        .changeLeadDirector{
            position:absolute;
            top: 5px;
            right: -150px;
        }
    }
    .limitedCompanyShareHolderContent{
        padding:20px;

        &.widthHalf{
            width:75%;
        }

        .iti{
            width:100%;
        }

        .answerSelection{
            display:flex;
        }
    }

    .nameSection{
        display: grid;
        grid-template-columns: 20% 80%;
        .titleDropdown{
            &.ng-select.ng-select-single .ng-select-container{
                height: 38px;
            }
        }
    }
}

.httpRequestObserveContainer {
    position: relative;

    display: flex;
    width: 100%;

    .httpRequestWrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 100;

        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;

        background: #f6f6f6;
        opacity: 0;

        &.show {
            animation-name: animFadeIn;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        &.hide {
            animation-name: animFadeOut;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }

        .httpRequestBody {
            width: 500px;
            height: 500px;

            text-align: center;

            .tortoise {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 250px;
                }
            }
        }
    }
}

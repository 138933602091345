button {
    &:focus {
        outline: 0;
    }

    &:focus-visible {
        outline: 0;
    }

    &:disabled {
        background: #ccc;
        border: 1px solid #ccc;
    }
}

.btn {
    &.btnPrimary {
        padding: 5px 20px;

        color: $whiteColor;
        font-size: 11px;
        line-height: 20px;

        background: $primaryColor;
        border: none;

        &.small {
            font-size: 11px;
            line-height: 11px;
        }

        &.medium {
            font-size: 11px;
            line-height: 15px;
        }

        &.large {
            font-size: 12px;
            line-height: 22px;
        }

        &.extraLarge {
            font-size: 13px;
            line-height: 35px;
        }

        &:hover {
            color: $whiteColor;

            background: $primaryColor;

            opacity: 0.8;

        }

        &:disabled {
            opacity: 0.65
        }
    }

    &.btnBorderPrimary {
        color: $colorGray;
        font-size: 14px;

        background: none;
        border: none;
        border-bottom: 0 none;
        border-radius: 0;

        &.nav-link {
            &.active {
                color: $primaryColor;
                font-size: 14px;

                border-bottom: 2px solid $primaryColor;
                border-radius: 0;
            }
        }

    }

    &.btnDefault {
        padding: 4.2px 20px;

        font-size: 11px;
        line-height: 20px;
        text-transform: capitalize;

        background: #f6f6f6;

        border: $standardBorderWidth solid $borderColor;
        border-radius: 5px;

        &.clear {
            color: #307AFF;

            background: #fff;
            border: $standardBorderWidth solid $primaryColor;
            border-radius: 5px;

            &:hover {
                color: $whiteColor;

                background: $primaryColor !important;

            }

        }

        &.medium {
            font-size: 11px;
            line-height: 15px;

            &.login {
                font-size: 12px;
            }
        }

        &:hover {
            color: $Black-clr;

            background-color: $backHover;

            opacity: 0.8;


        }

        &.noHover {
            &:hover {
                color: $colorGray;

                background: #f6f6f6;
                border: $standardBorderWidth solid $borderColor;
            }
        }
    }

    &.success {
        padding: 5px 20px;

        color: $whiteColor;
        font-size: 11px;
        line-height: 20px;

        background: $successColor;

        border: none;

        &.small {
            font-size: 11px;
            line-height: 11px;
        }

        &.large {
            padding: 5px 20px;

            font-size: 14px;

            line-height: 18px;
        }

        &:hover {
            color: $whiteColor;

            background: $successColor;
        }


    }

    &.btnSpacingH {
        padding: 5px 40px;
    }

    &.btnMt {
        margin-top: $buttonSpacing;
    }

    &.btnMr {
        margin-right: $buttonSpacing;
    }

    &.btnMb {
        margin-bottom: $buttonSpacing;
    }

    &.btnMl {
        margin-left: $buttonSpacing;
    }
}

.custom-ratio-btn {
    form {
        padding: 0 !important;
    }

    .form-group {
        display: block;
        margin: 0;
        margin-bottom: 15px;
        padding: 0 !important;

        /* stylelint-disable-next-line */
        input {
            display: none;
            width: initial;
            height: initial;
            margin-bottom: 0;
            padding: 0;

            cursor: pointer;

            &:checked {
                + {
                    label {
                        &:after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 83px;

                            display: block;
                            width: 17px;
                            height: 100%;

                            background: #307aff url(../../img/checkmark-16.png);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 10px;
                            border-radius: 0;
                            transform: none;

                            content: "";
                        }
                    }
                }
            }
        }

        /* stylelint-disable-next-line */
        label {
            position: relative;

            padding: 0 !important;

            font-weight: normal !important;

            cursor: pointer;

            /* stylelint-disable-next-line */
            &:before {
                position: relative;

                display: inline-block;
                margin-right: 5px;
                padding: 7px;

                vertical-align: middle;

                background: #e9e8e6;
                background-color: transparent;
                border: 1px solid #000000;
                box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
                cursor: pointer;

                -webkit-appearance: none;
                content: "";
            }
        }
    }
}

.cancel-btn.extra-padding {
    padding: 40px 30px 30px 30px;

    text-align: right;
}

.cancel-btn {
    button {
        padding: 2px 18px;

        color: #4c4c4c !important;
        font-size: 11px;
        line-height: 20.12px;
        text-transform: capitalize;

        background: #f6f6f6;
        border: 1px solid #d8d8d8;
        border-radius: 5px;

        &:hover {
            text-decoration: none;

            background: #efefef;
        }
    }
}

button:disabled.positive-btn {
    color: #666666 !important;

    background: #cccccc !important;
    border: 1px solid #999999 !important;
}

.forget-btn {

    /* stylelint-disable-next-line */
    button {
        &:disabled {
            background: #404040;

            &:hover {
                opacity: 1;
            }
        }

        padding: 0;

        color: $primaryColor;
        font-size: 14px;

        background: transparent !important;
        border: 0;
        border-radius: 30px;

        /* stylelint-disable-next-line */
        &:hover {
            opacity: 0.8;
        }
    }

    text-align: center;
}

.negative-btn {
    color: #4c4c4c !important;

    background: #f6f6f6 !important;
    border: 1px solid #d8d8d8 !important;
    box-shadow: none !important;

    &:hover {
        background: #efefef !important;
    }
}

.positive-btn {
    color: #fff !important;

    background: #307aff !important;
    border: 1px solid #307aff !important;
    box-shadow: none !important;

    &:hover {
        background: #2a6cdd !important;
    }
}


.search-div-sec {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    justify-content: center;

    /* stylelint-disable-next-line */
    button {
        border-radius: 5px;
    }
}
.validationColor{
    color: #dc3545;
}

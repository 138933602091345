.alertSection {
    max-width: 1200px;
    min-height: 400px;
    margin: 50px auto 0;
    padding-top: 32px;

    color: $grayColor;

    background-color: $whiteColor;
    border: 1px solid $borderColor;
    .alertSubSection {
        width: 100%;
        padding: 0 32px 0 32px;

        font-size: $textSizeNormal;
        .firstAlertSection{
            border-bottom: 2px solid $borderColor;
        }
        .alertMainArea {
            display: flex;

            .alertContent {
                box-sizing: border-box;
                width: 65%;
                padding-right: 10px;
            }

            .dropdownContainer {
                display: flex;
                justify-content: flex-end;
                width: 50%;
                .dropdown{
                    width: 200px;
                    height: 37px;
                }
                .mailSection {
                    position: relative;
                    .emailInput {
                        margin-right: 25px;
                        padding: 0 40px 0 12px;

                        font-size: 12px;
                        line-height: 35px;

                        border: 1px solid $borderColor;
                        &.emailFormControl:focus{
                            border-color: $lightBlue;
                            outline: 0;
                            box-shadow: 0 0 0 0.25rem $darkBlue;
                        }
                        &.errorMessage:focus{
                            border-color: $errorColor;
                        }
                    }
                    .errorText {
                        margin: 6px 0 0 0;

                        color: $errorColor;
                    }
                    .icon{
                        position: absolute;
                        top: 12px;
                        right: 0;
                    }
                    .removeIcon{
                        position: absolute;
                        right: 0;

                        padding: 12px 0 0 0;
                    }
                }
            }
        }
    }
}


.login-history-main-section {
    .breadcrumb-section {
        background: none;
        border: none;
    }
}
.login-summary-section {
    max-width: 1200px;
    margin: 50px auto 0;

    background-color: #fff;
    border: 2px solid #e2e2e2;
    .container {
        background: #fff;
        .table-responsive {
            padding: 0 30px;
            thead {
                color: #0491df;
                font-weight: 400;

                background-color: #f6f6f6;
                th {
                    position: relative;

                    height: 34px;

                    // padding: 5px;
                    color: $primaryColor;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 21.12px;
                }
            }
            tbody {
                tr {
                    td {
                        padding: 7px 10px;

                        color: #4c4c4c;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 18.48px;
                    }
                }
            }
        }
        .inner-tabel-tab {
            .table {
                th {
                    &:nth-child(1) {
                        width: 12%;
                    }
                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 12%;
                    }
                    &:nth-child(4) {
                        width: 9%;
                    }
                    &:nth-child(5) {
                        width: 9%;
                    }
                    &:nth-child(6) {
                        width: 11%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                    &:nth-child(8) {
                        width: 11%;
                    }
                }
            }
        }
    }
}

.lockIcon{
    position: relative;
    top: -2px;
    display: inline-block;
    margin: 0 17px;
}

.lockicon{
    position: relative;
    top: -2px;
    display: inline-block;
    margin: 0 3px;
}

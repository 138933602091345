.loginContainer{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    background-color: #fff;

    .loginContent{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 60%;
        height: 60%;

        .loginBody{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                margin-bottom: 35px;

                font-size: $textSizeLarge;
                line-height: 20px;

                @media screen and (min-width: 1440px) {
                    margin-bottom: 45px;

                    font-size: $textSizeExtraLarge;
                }
            }
        }

        .pp_logo_text{
            width: 58px;
        }
    }

    .ppDownload{
        width: 150px;

        @media screen and (min-width: 1440px) {
            width: 170px;
        }
    }
    .ppLogo{
        width: 100px;

        @media screen and (min-width: 1440px) {
            width: 120px;
        }
    }
}

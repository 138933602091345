.container{
    padding: 0 $layoutBreakPointSpacing;
    @include largeDesktop{
        padding:0;
    }
}

.routerOutlet {
    flex-grow: 1;

    &.animatePage {
        opacity: 0;

        &.initialize {
            animation-name: animFadeIn;
            animation-duration: 3s;
            animation-fill-mode: forwards;
        }
    }
}

.headerSpacing{
    margin: $headerSpacing 0;
}

hr.divider{
    &.default{
        margin: pxToRem(16) 0;
    }

    &.bgGray{
        border: 1px solid gray;
    }
}

.text-left{
    text-align:left !important;
}

// Hiding default arrow for input[type=number]
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    margin: 0;

    -webkit-appearance: none !important;
}
input[type=number] {
    -moz-appearance:textfield !important;
}

.select{
    &.is-invalid{
        .ng-select-container{
            border-color: $errorColor !important;
        }
    }
}

.CSTableWidth{
    width:550px
}

.svg{
    &.hint{
        width: 15px;
        height: 15px;

        color: #fff;

        background: #fff;

        border: 1px solid #ddd;
        border-radius: 50%;

        cursor:pointer;
    }
}

.bs-tooltip-top, .bs-tooltip-end{
    position:absolute;
}
.noBorder{
    border:none !important;
}
.link{
    &.linkUnderline{
        text-decoration: underline !important;
    }
}

input#login, input#phoneNumber {
    font-size: 15px;
}

.button-height-25px{
    height: 25px;
}

.hover{
    &:hover{
        cursor: pointer;
    }
}

.spacingWithBorder{
    display: flex;
    align-items: center;
    justify-items: center;
    height: 43px;

    font-size: $textSizeNormal;

    border-bottom: $standardBorderWidth solid $borderColor;
}
tr:last-child>td{
    border-bottom:0
}

.routerBackground {
    background-color: $whiteColor;
}

.error-text{
    color: $invalidFeedback !important;
}

.whiteBackgroundOverride {
    background-color: $whiteColor !important;
}

.cursorPointer{
    cursor: pointer;
}

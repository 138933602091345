.dBlock {
    display: block !important;
}
.dFlex{
    display: flex;
    &.dCenter{
        align-items: center;
        justify-content: center;
    }
    &.dAlignItemsCenter{
        align-items: center;
    }
}
.dAlignEnd{
    align-items: flex-end;
}
.dJustifyEnd{
    justify-content: flex-end;
}
.dJustifyBetween{
    justify-content: space-between;
}

@import "./abstracts/variables";
@import "./abstracts/animation";
@import "./abstracts/functions";
@import "./abstracts/mixins";
@import "abstracts/mixins/mediaQuery";




/**
**********************************************
  Add all mixins
**********************************************
*/
@import "./abstracts/mixins/buttons";
@import "./abstracts/mixins/table";

/**
**********************************************
  Add any plugins style
**********************************************
*/

/**
**********************************************
  Add base file to overwrite any browser rule
**********************************************
*/

@import "base/reset";
@import "base/typography";
@import "base/display";
@import "base/position";

/**
**********************************************
  Add layout files
**********************************************
*/
@import "layout/variables";
@import "layout/extra";
@import "layout/navigation";
@import "layout/header";
@import "layout/footer";
@import "layout/main";
@import "layout/globle";
@import "layout/login";
@import "layout/responsive";
@import "layout/table";
@import "layout/breadcrumb";
@import "layout/grid";
@import "layout/tabPanel";
@import "layout/tablePrimary";
@import "layout/position";
@import "layout/display";

/**
**********************************************
  Global style
**********************************************
*/

@import "global";
@import "global/background";
@import "global/marginPadding";
@import "global/table";
@import "global/form";
@import "global/search";
@import "global/formInput";
@import "global/gridLayout";

/**
**********************************************
  Add Component files
**********************************************
*/
@import "components/buttons";
@import "components/alert";
@import "components/dashboardWidgets";
@import "components/headerDropDown";
@import "components/summaryHeader";
@import "components/topSectionHeader";
@import "components/appLoader/appLoading";
@import "components/httpRequestObserve";
@import "components/advanceSearch";
@import "components/datepickerComponent";
@import "components/tooltip";
@import "components/notificationComponent";
@import "components/poweredBy";
@import "components/model";
@import "components/datePickers";
@import "components/copyClipboard";
@import "components/customerRegistrationPage/businessContact";
@import "components/customerRegistrationPage/amexDetails";
@import "components/alerts";
@import "components/confirmationDialog";
@import "components/customerRegistrationPage/partnerMode";
@import "components/customerRegistrationPage/loyaltyComponent";
@import "components/customerRegistrationPage/platinumSection";
@import "components/commissionPercentage";
@import "components/switchSeller";

/**
**********************************************
  Add pages file
**********************************************
*/
@import "pages/customer-register";
@import "pages/customer-detail-old";
@import "pages/users";
@import "pages/user-add";
@import "pages/user-activation";
@import "pages/profile";
@import "pages/customer";
@import "pages/commercials";
@import "pages/customer-detail";
@import "pages/login-history";
@import "pages/login-device";
@import "pages/messages";
@import "pages/transaction";
@import "pages/account";
@import "pages/commissionsDetail";
@import "pages/user-edit";
@import "pages/loyalCustomer";
@import "pages/verofy-alerts";
@import "pages/lockIcon";
@import "pages/spacer";

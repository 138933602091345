@use "sass:math";
body{
    .pageHeader{
        &.primary{
            margin-top: $mainContentSpacing;
            margin-bottom: $mainContentSpacing;

            .title{
                color: $headerTextColor;
                font-weight: 600;
                font-size: 18px;
                line-height: 34.32px;
            }
        }

        &.bottomBorder{
            margin-bottom: 0px;
            padding-bottom: math.div($mainContentSpacing, 2);

            border-bottom: $standardBorderWidth solid $borderColor;
        }

        &.withBorder{
            margin-bottom: math.div($mainContentSpacing, 2);
            padding-bottom: math.div($mainContentSpacing, 2);

            border-bottom: $standardBorderWidth solid $borderColor;
        }
    }
    .headerSpacing{
        margin-top: $mainContentSpacing;
    }

    .spacingTopStandard{
        margin-top: $standardSpacing;
    }

    .footerSpacingMb{
        margin-bottom: $mainContentSpacing;
    }

    .footerSpacingPb{
        padding-bottom: $mainContentSpacing;
    }

    .borderBottom{
        border-bottom: $standardBorderWidth solid $borderColor;
    }

    .borderTop{
        border-top: $standardBorderWidth solid $borderColor;
    }

    .elementSpacingMt{
        margin-top: $standardSpacing;
    }

    .elementSpacingMb{
        margin-bottom: $standardSpacing;
    }

    .elementSpacingPt{
        padding-top: $standardSpacing;
    }

    .elementSpacingPb{
        padding-bottom: $standardSpacing;
    }

    .nav-tabs {
        &.noBorder{
            border-bottom: none;
        }
    }

    .noMargin{
        margin:0 !important;
    }

    .noBorder{
        border:none;
    }
}

.custom-checkbox {
    .custom-control-input {
        ~ {
            .custom-control-label {
                font-size: 0;
            }
        }
        &:checked {
            ~ {
                .custom-control-label {
                    &::before {
                        background-color: $primaryColor !important;
                        border: 0;
                    }

                    &:after {
                        color: $primaryColor;
                        font-size: 0;

                        background-position: 2px 2px;
                    }
                }
            }
        }
        &:active {
            ~ {
                .custom-control-label {
                    &::before {
                        background-color: $primaryColor;
                    }
                }
            }
        }
    }
    > [type="checkbox"] {
        &:checked + label {
            &:before {
                width: 15px;
                height: 15px;

                border: 1px solid #c0c0c0;
            }
        }
        &:not(:checked) + label {
            &:before {
                width: 15px;
                height: 15px;

                border: 1px solid #c0c0c0;
            }
        }
    }
    [type="checkbox"] {
        &:disabled {
            &:checked + label {
                &:hover {
                    &:before {
                        border: 1px solid $primaryColor !important;
                    }
                }
            }
            /* stylelint-disable-next-line */
            &:not(:checked) + label {
                &:hover {
                    &:before {
                        border: 1px solid $primaryColor !important;
                    }
                }
            }
        }
    }
    /* stylelint-disable-next-line */
    label {
        /* stylelint-disable-next-line */
        &:hover {
            /* stylelint-disable-next-line */
            &:before {
                border: 1px solid $primaryColor !important;
            }
        }
    }
}
.appWrapper {
    position: relative;
    top: 10px;

    .appAlert {
        position: relative;
        top: 0;
        z-index: 10;

        margin: 0;
        padding: 0;

        border: none;

        @for $i from 0 through 10 {
            &.alert-#{$i} {
                .notification {
                    @if $i == 0 {
                        top: 150px;
                    } @else {
                        top: 50 * $i + px;
                    }
                }
            }
        }
    }
}
.alert {
    position: relative;
    .danger-alert {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;

        color: #f91408;
        font-size: 16px;

        background: #fff;
        border-radius: 5px;

        .close {
            margin: 0;
            padding: 0;

            color: #fff;

            background: transparent;
            box-shadow: none;
            opacity: 1;
        }
    }
}
.alert-danger {
    background: none;
    border: none;
}

.notification {
    position: absolute;
    top: 5px;
    left: 50%;

    display: flex;
    align-items: center;

    padding: 10px 20px;

    transform: translateX(-50%);

    &.green-alert {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;

        color: #fff;
        font-size: 15px;

        background: rgb(66 196 18 / 60%);
        border-radius: 5px;

        .close {
            margin: 0;

            padding: 0;

            color: #fff;

            background: transparent;
            box-shadow: none;
            opacity: 1;
        }
    }

    &.info-alert {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;

        color: #fff;

        font-size: 15px;

        background: #007bff;

        border-radius: 5px;

        .close {
            margin: 0;

            padding: 0;

            color: #fff;

            background: transparent;

            box-shadow: none;
            opacity: 1;
        }
    }

    &.warning-alert {
        display: flex;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;

        color: #fff;

        font-size: 15px;

        background: #ffc755;

        border-radius: 5px;

        .close {
            margin: 0;

            padding: 0;

            color: #fff;

            background: transparent;

            box-shadow: none;
            opacity: 1;
        }
    }
}

/*
********************************
Need to add on form files once merge with code
********************************
 */

.inputElement{
    &.checkbox{
        display: grid;
        place-content: center;


        width: 13px !important;
        height: 13px;
        margin: 0 5px 0 0;

        color: currentColor;
        font: inherit;

        background-color: #fff;

        border: 1px solid currentColor;
        border-radius: 0;

        transform: translateY(-0.075em);

        -webkit-appearance: none;
        appearance: none;

        &::before{
            width: 9px;
            height: 9px;

            margin-left:-3px;

            background-color: $primaryColor;

            box-shadow: inset 1em 1em var(--form-control-color);
            transform: scale(0);

            transform-origin: bottom left;

            transition: 120ms transform ease-in-out;

            content: "";

            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }

        &:checked::before{
            transform: scale(1);
        }
    }
}

.daterangeSummary{
    display: flex;
    align-items: center;
}

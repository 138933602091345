.datePickerGroup{
    position: relative;

    input{
        position:absolute;

        width:1px;
    }
}

.datePickerContainer{
    &.labelNoMargin{
        .inline-check{
            label{
                margin:0;

                color: $primaryColor;
                font-size:$regularFontSize;
            }
        }
    }
}
.theme-green {
    border-radius: 10px;
    
    .bs-datepicker-head{
        background-color: $primaryColor;
    }
    .bs-datepicker-body {
        table {
            td {
                span.selected {
                    background-color: $primaryColor;
                }
                span[class*="select-"] {
                    &:after {
                        background-color: $primaryColor;
                    }
                }
            }
            td.selected {
                span {
                    background-color: $primaryColor;
                }
            }
            td[class*="select-"] {
                span {
                    &:after {
                        background-color: $primaryColor;
                    }
                }
            }
            td.week {
                span {
                    color: $primaryColor;
                }
            }
        }
    }
}

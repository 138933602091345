// TO DO need to import all other styles from user-edit.component.scss

.modalHeader {
    display: flow;
    padding: var(--bs-modal-header-padding);

    color: $whiteColor;

    background: $primaryColor;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}
